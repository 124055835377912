import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { history } from '../index.js'

import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import { Button, Divider } from '@material-ui/core';

import UserDocumentUploadForm from './UserDocumentUploadForm';
import {
    getAllDSPRServicingZipCode,
    updateLoggedInUserInfo, uploadUserDocument, uploadUserSignature, USER_ID_DOCUMENT, USER_MEDICAL_RECOMMENDATION
} from '../actions/userActions';
import { logException } from '../actions/apiUIHelperActions';
import { getIsRecreationalMarket } from "../selectors/menuSelector";
import { getLoggedInUser } from "../selectors/userSelectors";
import SignaturePad from 'react-signature-pad';

class UserIdAndRecommendationUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userIdUploading: false,
            userIdFinished: false,
            medRecUploading: false,
            medRecFinished: false,
        };
    }

    componentDidMount() {
        this.props.getAllDSPRServicingZipCode(this.props.user.signupZipCode);
        /*this.props.updateLoggedInUserInfo();*/
    }

    handleFinish = () => {
        history.push('/');
    };

    handleUploadUserIdDocument = (formValues) => {
        if (!formValues.imageFile) {
            alert("Image was not selected properly. Please contact support@grasspit.com for help.");
            logException("Image was not selected properly", { formValues, state: this.props.state });
        }
        else {
            const imageFile = formValues.imageFile[0];
            const { user } = this.props;

            this.setState({ userIdUploading: true })
            this.props.uploadUserDocument(user.id, USER_ID_DOCUMENT, imageFile).then(() => {
                this.setState({ userIdFinished: true }, () => {
                    ReactGA.event({
                        category: 'User',
                        action: 'Uploaded ID Doc'
                    });

                    // Bring the user to the home page after both steps are completed
                    if (this.state.medRecFinished) {
                        this.handleFinish();
                    }
                });
                
            });
        }
    };

    handleUploadDoctorRecommendation = (formValues) => {
        const imageFile = formValues.imageFile[0];
        const { user } = this.props;

        this.setState({ medRecUploading: true });
        this.props.uploadUserDocument(user.id, USER_MEDICAL_RECOMMENDATION, imageFile).then(() => {
            this.setState({ medRecFinished: true }, () => {
                ReactGA.event({
                    category: 'User',
                    action: 'Uploaded Medical Recommendation'
                });

                // Bring the user to the home page after both steps are completed
                if (this.state.userIdFinished) {
                    this.handleFinish();
                }
            });
        });
    };

    handleUploadSignature = () => {
        this.props.uploadUserSignature(this.refs.mySignature.toDataURL())
            .then(() => this.props.updateLoggedInUserInfo())
            .then(() => history.push("/"));
    };

    render() {
        const { user } = this.props;
        const { isRecreationalMarket } = this.props;
        //console.log("isRecreationalMarket = " + isRecreationalMarket);

        return (
            <Card className="card-with-form" style={{ textAlign: 'left' }}>
                <CardHeader
                    title={`Welcome ${user.firstName}!`}
                    subheader="Next we need some additional documentation."
                />
                <div className="signup-upload-form">
                    <div className="signup-upload-driver-license">
                        <p>1. Driver's License / State ID</p>
                        {!this.state.userIdUploading ?
                            <UserDocumentUploadForm onSubmit={this.handleUploadUserIdDocument}
                                formId="SignupIdUploadForm"
                                buttonLabel="Upload a photo"
                                showSubmitButton={false}
                                submitLabel="Upload"
                                showBackButton={false} /> :
                                !this.state.userIdFinished ? 
                                    <p className="upload-status">Uploading...</p> : <p className="upload-status">Upload Successful!</p>}
                    </div>
                    {isRecreationalMarket ? (
                        <Fragment>
                            <div className='upload-form'>
                                <p>2. Please provide your signature. This is needed to comply with the laws in your state for recreational marijuana.<br />
                                    It will only be used and attached to your delivery receipt with your approval.</p>
                                <div><SignaturePad clearButton="true" ref="mySignature" /></div>
                                <div style={{width:"100%", display:"flex", justifyContent:"center"}}><Button color="primary" variant="contained" onClick={this.handleUploadSignature}>Submit</Button></div>
                            </div>
                           
                        </Fragment>
                    ) : <div className="signup-upload-medical-recommendation">
                            <Divider className="signup-divider" />
                            <p>2. Medical Recommendation / Card</p>
                            {!this.state.medRecUploading ? 
                                <UserDocumentUploadForm onSubmit={this.handleUploadDoctorRecommendation}
                                    formId="SignupMedRecUploadForm"
                                    buttonLabel="Upload a photo"
                                    showSubmitButton={false}
                                    submitLabel="Upload"
                                    showBackButton={false} /> : 
                                        !this.state.medRecFinished ? 
                                            <p className="upload-status">Uploading...</p> : <p className="upload-status">Upload successful!</p>}
                        </div>}
                </div>
                <CardContent><Button variant="contained" onClick={() => history.push('/')}>Skip</Button></CardContent>
            </Card>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loggedInUser: getLoggedInUser(state),
        isRecreationalMarket: getIsRecreationalMarket(state),
        state
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        uploadUserDocument,
        uploadUserSignature,
        updateLoggedInUserInfo,
        getAllDSPRServicingZipCode,
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserIdAndRecommendationUpload));
