import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { submit, reset } from 'redux-form'
import ReactGA from 'react-ga';
import {window} from "browser-monads"
import { history } from '../index.js'

import {Card, Checkbox, Snackbar} from "@material-ui/core";

import CouponCodeForm from "./CouponCodeForm";
import SpecialInstructionsForm from "./SpecialInstructionsForm";
import AddressForm from "./AddressForm";

import OrderWithDetailsAndPrices from "../components/OrderWithDetailsAndPrices";
import MenuProductDialog from './MenuProductDialog';

import {getCartOrderDetails, getOrderForCost} from "../selectors/cartSelector";
import {getAddressFromMenu, getDriverMenu, getIsRecreationalMarket} from "../selectors/menuSelector";
import {getLoggedInUser} from "../selectors/userSelectors";
import {getError} from "../selectors/errorSelector";

import {updateLoggedInUserInfo} from "../actions/userActions";
import {NO_SERVICE_ERROR_STRING, DSPR_NOT_OPERATING_IN_ZIP_ERROR_STRING} from '../actions/menuActions';
import {clearCart, removeProductFromCart} from "../actions/cartActions";
import {getOrderCost, submitOrder} from "../actions/orderActions";
import {clearErrorMessage} from "../actions/apiUIHelperActions";
import LoadingSpinner from '../components/LoadingSpinner'

const styles = {
  checkoutPage: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    flex: 1,
    overflowY: 'auto',
    width: '100%',
    marginBottom:'50px'
  },
  centeredText: {
    textAlign: 'center',
    marginTop: "30px",
    marginBottom: "5px"  
  },
  addressForm: {
    textAlign: 'center',
    marginBottom: "30px"  
  },
  specialInstructions: {
      marginTop: "30px",
      width: "250px"
  }
};

class Cart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            couponApplied: false,
            couponCode: undefined,
            snackBarAutoHideDuration: 5000,
            snackBarOpen: false,
            snackBarMessage: '',
            selectedProduct: null,
            showSelectedProduct: false,
            showPlaceOrder: true,
            allowSubmit: true,
            specialInstructions: undefined,
            specialInstructionsValid: undefined,
            couponCodes: new Set()
        };
        this.removeProductFromCart = this.removeProductFromCart.bind(this);
        this.showProductQuantitySelector = this.showProductQuantitySelector.bind(this);
        // this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.updateLoggedInUserInfo();

        this.props.clearErrorMessage();
        if(this.props.orderDetails !== undefined) {
            if(this.props.orderDetails.length === 0) history.push("/");
            else this.getOrderCost();
        }

        

        // window.addEventListener('resize', this.resize);
        // this.resize();
    }

    componentDidUpdate() {
        // this.resize();
    }

    // resize() {
    //     const cartNode = ReactDOM.findDOMNode(this.cartCont);
    //     if (cartNode !== null) {
    //         const diff = cartNode.offsetHeight - cartNode.clientHeight;
    //         cartNode.style.height = (window.innerHeight - cartNode.offsetTop + diff) + 'px';
    //     }
    // }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.resize);
    }

    handleSnackBarClose = () => {
        this.setState({
            snackBarOpen: false,
            snackBarMessage: ''
        });
    };

    setSpecialInstructionsText = (text) => {
        this.setState({specialInstructions: text})
    }

    setSpecialInstructionsFormError = (validity) => {
        this.setState({specialInstructionsValid: validity})
    }

    getOrderCost = () => {
        return this.props.getOrderCost({
            address: this.props.address,
            dsprDriver: this.props.dsprDriver,
            orderDetails: this.props.orderDetails,
            couponCodes: this.state.couponCodes.length === 0 ? null : this.state.couponCodes
        })
    };

    applyCouponCode = (codeValue) => {
        const {code}= codeValue;
        if(this.state.couponCodes.has(code)) {
            this.setState({snackBarOpen: true, snackBarMessage: "That coupon is already applied to this order"})
        } else {
            this.setState({couponCodes: this.state.couponCodes.add(code)}, () => {
                this.getOrderCost()
                .then(response => {
                    if (!response.error) {
                        var returnedCoupons = new Set(response.response.entities.orders[0].coupons.map(coupon => coupon.code))
                        var snackBarMessage = 'Coupon applied successfully!'
                        var couponAppliedToOrder = true
                        if(returnedCoupons.size === (this.state.couponCodes.size - 1)) {
                            couponAppliedToOrder = false;
                            var coupons = new Set(this.state.couponCodes)
                            coupons.delete(code);
                            returnedCoupons.forEach(coupon => {
                                if(!coupons.has(coupon)) {
                                    couponAppliedToOrder = true;
                                    
                                }
                            });
                            if(!couponAppliedToOrder) {
                               snackBarMessage = 'Coupon not applied, it wouldn\'t lower order total.';
                            }
                        }
                        if(couponAppliedToOrder) {
                            this.props.reset('couponCodeForm')
                        }
                        this.setState({
                            snackBarOpen: true,
                            snackBarMessage: snackBarMessage,
                            couponCodes: returnedCoupons,
                        });
                    } else {
                        var resetCoupons = new Set(this.state.couponCodes);
                        resetCoupons.delete(code);
                        this.setState({
                            snackBarOpen: true,
                            snackBarMessage: response.error,
                            couponCodes: resetCoupons
                        });
                    }
                });
            });       
        }
    };

    removeCouponCode = (coupon) => {
        const {code} = coupon;

        if(this.state.couponCodes.has(code)) {
            const newCoupons = new Set(this.state.couponCodes)
            newCoupons.delete(code)
            this.setState({couponCodes: newCoupons}, () => {
                this.getOrderCost()
                .then(response => {
                    if (!response.error) {
                        this.setState({
                            snackBarOpen: true,
                            snackBarMessage: 'Coupon removed successfully!'
                        });
                    } else {
                        this.setState({
                            snackBarOpen: true,
                            snackBarMessage: response.error
                        });
                    }
                });
            })
        }
    }

    processError = () => {
        if(this.props.error && (this.props.error.includes(NO_SERVICE_ERROR_STRING) || this.props.error.includes(DSPR_NOT_OPERATING_IN_ZIP_ERROR_STRING))){
            const re = /.*zip code.*'(\w+)'/;
            return this.props.error.replace(re,'Grassp is not servicing zip code: $1. Please try a different delivery address.');
        } else if(this.props.error.includes("minimum")){
            const re =/.*minimum.*'(\w+)\.(\w+)'\./;
            return this.props.error.replace(re, 'Minimum order size is $1.$2');
        }
        return this.props.error;
    };

    submitOrderDetails = (address) => {
        this.setState({showPlaceOrder: false});
        if(this.state.specialInstructionsValid) {
            this.props.submitOrder({
                address,
                dsprDriver: this.props.dsprDriver,
                orderDetails: this.props.orderDetails.map(productInfo => ({...productInfo, product: {id: productInfo.product.id}})),
                couponCodes: this.state.couponCodes,
                specialInstructions: this.state.specialInstructions,
            })
                .then((response) => {
                    if(this.props.error) {
                        this.setState({
                            snackBarOpen: true,
                            snackBarMessage: this.processError()
                        });
                        this.props.clearErrorMessage();
                        this.setState({showPlaceOrder: true});
                    }
                    else {
                        window.ca("send", "order_placed", "buypage", 'click', `${this.props.order.cashTotal}`)
                        ReactGA.event({
                            category: 'Order',
                            action: 'Order Placed By User'
                        });
                        this.props.clearCart();
                        this.props.updateLoggedInUserInfo()
                            .then(() => history.push("/"));
                    }
                });
        } else {
            this.setState({showPlaceOrder: true});
        }
    };

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.orderDetails !== this.props.orderDetails){
            if(nextProps.orderDetails.length === 0) history.push("/");
            else {
                this.props.getOrderCost({
                    address: this.props.address,
                    dsprDriver: this.props.dsprDriver,
                    orderDetails: nextProps.orderDetails,
                    couponCode: this.state.couponCode
                });
            }
        }
    };

    removeProductFromCart = (product) => {
        this.props.removeProductFromCart(product);
        ReactGA.event({
            category: 'Product',
            action: 'Product Removed From Cart',
            value: product.id,
            label: product.name
        });
    };

    showProductQuantitySelector = (product) => {
        this.setState({showSelectedProduct: true, selectedProduct: product});
    };

    onSignatureCheck = (event, isInputChecked) => {
        this.setState({allowSubmit: isInputChecked});
    };

    continueShopping = () => {
        history.push('/');
    }
    render() {

        const address = (this.props.loggedInUser && this.props.loggedInUser.defaultAddress) ?
            this.props.loggedInUser.defaultAddress :
            this.props.address;
        const hasConvertedBonus = this.props.loggedInUser && this.props.loggedInUser.hasConvertedBonus;

        return this.props.order ? <div>
                <Card className={'cart'}
                      ref={ (cartCont) => { this.cartCont = cartCont; } }
                      style={styles.checkoutPage}
                      elevation={0}
                >
                    <OrderWithDetailsAndPrices order={this.props.order} removeProductFromCart={this.removeProductFromCart} showProductQuantitySelector={this.showProductQuantitySelector} removeCouponCode={this.removeCouponCode}/>
                    {this.props.isRecreationalMarket &&
                        <div>
                            <div className="msg-style-center">
                                The cannabis excise taxes are included in the total amount of this invoice.
                            </div>
                            <div className="msg-style-center">
                                <Checkbox defaultChecked={true} onChange={this.onSignatureCheck}/>
                                I agree to add my signature to the receipt for this order by checking this box.
                            </div>
                        </div>
                    }
                    {hasConvertedBonus ? (
                        <div className="msg-style-center">You have a <b>WELCOMEBONUS</b> coupon code available — Thanks for signing up!</div>
                    ) : (
                        <div/>
                    )}
                    {/* {!this.state.couponApplied && <CouponCodeForm onSubmit={this.applyCouponCode}/>} */}
                    <CouponCodeForm onSubmit={this.applyCouponCode} couponCodes={this.state.couponCodes}/>

                    <SpecialInstructionsForm setSpecialInstructionText={this.setSpecialInstructionsText} setFormError={this.setSpecialInstructionsFormError} className={styles.specialInstructions}/>

                    <div style={styles.addressForm}>
                    <h4 style={styles.centeredText}>Delivery Address</h4>
                    <AddressForm address={address} order={this.props.order} checkout={true} onSubmit={this.submitOrderDetails} showButton={false} continueShopping={this.continueShopping}/>
                    </div>
                    
                    

                    {/* <div className="cart-cta-container">
                        <div>
                            <p>Estimated Tax</p>
                            <p>${this.props.order.orderTaxDetails.length !==0 ? parseFloat(this.props.order.orderTaxDetails.slice(0).reverse().map(item => item.amount).reduce((prev, curr) => prev + curr)).toFixed(2): 0}</p>
                        </div>
                        <div>
                            <p>Total</p>
                            <p>${parseFloat(this.props.order.cashTotal).toFixed(2)}</p>
                        </div>
                        <Button color="primary" variant="contained"
                            type="submit"
                            form="address-form"
                            fullWidth={true}
                            disabled={!this.state.showPlaceOrder}
                            style={{ marginLeft: '0px', marginRight: '0px' }}
                        >
                            Place Order
                        </Button>
                        <Button color="secondary" variant="contained"
                            onClick={this.continueShopping}
                            fullWidth={true}
                            style={{ marginLeft: '0px', marginRight: '0px' }}
                        >
                            Continue Shopping
                        </Button>
                    </div> */}

                </Card>
                <Snackbar
                        open={this.state.snackBarOpen}
                        message={this.state.snackBarMessage}
                        autoHideDuration={this.state.snackBarAutoHideDuration}
                        onClose={this.handleSnackBarClose}
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    />
                {this.state.showSelectedProduct && <MenuProductDialog
                    product={this.state.selectedProduct.id}
                    docsVerified={true}
                    onClose={() => this.setState({ showSelectedProduct: false, selectedProduct: null })}/>}
            </div>
            :
            <LoadingSpinner />
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        loggedInUser: getLoggedInUser(state),
        orderDetails: getCartOrderDetails(state),
        address: getAddressFromMenu(state),
        dsprDriver: getDriverMenu(state),
        order: getOrderForCost(state),
        error: getError(state),
        isRecreationalMarket: getIsRecreationalMarket(state),
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getOrderCost,
        submitOrder,
        clearErrorMessage,
        clearCart,
        updateLoggedInUserInfo,
        removeProductFromCart,
        submit,
        reset,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
