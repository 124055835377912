
import React, { Fragment, useState, useEffect } from 'react'
import ReactGA from 'react-ga';
import {
    Dialog,
    DialogContent,
    CardContent,
    GridList,
    Card,
    IconButton,
    GridListTile,
    GridListTileBar,
    FormControlLabel, Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { history } from '../index.js';
import { API_HOST } from '../middleware/api';
import { parseDateEndOfDay } from '../util/util';

import './MenuComponentStyles.css';

import { MENU_LOCATION_TYPES } from "./SetLocationMenu";
import { useParams } from "react-router-dom";
import { MenuParams } from "../containers/MenuContainer";

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        margin: '0px',
        width: "100%",
        padding: '0px'
    },
    productsContainer: {
        display: "flex",
        flex: 1,
    },
    gridList: {
        height: '100%',
        //overflowY: 'auto',
        overflowY: "visible" as const,
        width: '100%',
        padding: '0px',
        paddingBottom: '56px' // temporary workaround to prevent fixed position footer (with height of 56px) from obscuring bottom of menu
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    fullMenuContainer: {
        width: '100%',
    },
    searchResultsContainer: {
        width: "100%",
    },
    searchResults: {
        display: "flex",
        marginLeft: 12,
        alignItems: "center" as const,
        flexDirection: "row" as const,
    }
};

const StyledLocationMenuLabel = withStyles({
    root: {
        width: '100%',
    },
    label: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }
})(FormControlLabel);


interface MenuProps {
    dsprId: number;
    Notifications: JSX.Element;
    Errors: JSX.Element;
    LocationMenu: JSX.Element;
    FiltersMenu: JSX.Element;

    currentZipCode: string;
    loggedInUser;
    medicalRecommendation;
    idDocument;
    isRecreationalMarket;
    userSignature;

    productCategories: any[];
    products: any;
    initialCategoryId: any;

    mostRecentDspr?: boolean;
    dsprFullName: string; //Not currently being used
    dsprName: string;
    dsprImageLocation: string;
    setHasSeenDsprModal: (value: boolean) => void;
    isFullMenuAvailable: boolean;
    isFullMenuShown: boolean;
    handleChangeForFullMenuCheckbox: () => void;
    currentMenuType: string;
    currentMenu: any;

    menuSorting: { sortParameter: string, sortMethod: 'ascending' | 'descending' };
    menuFilters: any;
    menuSearch: any;
    clearMenuSearch: () => void;

}

const Menu: React.FC<MenuProps> = props => {
    const {
        dsprId, Notifications, Errors, LocationMenu, currentZipCode,
        loggedInUser, medicalRecommendation, idDocument, isRecreationalMarket, userSignature,
        productCategories, products, mostRecentDspr, dsprName, dsprImageLocation, setHasSeenDsprModal,
        currentMenuType, FiltersMenu, menuSorting, menuFilters, menuSearch, clearMenuSearch
    } = props;

    const { section, value } = useParams<MenuParams>();
    const [showMostRecentDsprModal, setShowMostRecentDsprModal] = useState(false);

    //***Keep for if we have an expandable options header
    //const [expanded, setExpanded] = useState<boolean | string>(false);

    const getProductPromotionListTileForCurrentCategory = () => {
        if (!(section && section === 'categories' && !!value)) return;
        const categoryId = value
        const category = productCategories.find(category => category.id === parseInt(categoryId))
        const categoryPromotion = category ? category.currentPromotion : undefined
        return categoryPromotion && <GridListTile
            className={'product-grid'}
            key={"category-promotion-" + categoryPromotion.id}>
            <img className={'product-image'}
                alt={`${categoryPromotion.promotionalText}`}
                src={`${API_HOST}${categoryPromotion.imageLocation}`} />
            <GridListTileBar
                className={'promotional-text-tilebar'}
                title={
                    <div className='promotional-text'>
                        <p>{categoryPromotion.promotionalText}</p>
                    </div>
                }
            />
        </GridListTile>
    }

    const createProductGridTile = (product) => {
        const price = product.isFlower ? product.currentPrice.eighthPrice : product.currentPrice.price;
        const displayPrice = Number.isInteger(price) ?
            price :
            parseFloat(price).toFixed(2);

        return (
            <GridListTile
                onClick={handleProductSelection(product)}
                className={'product-grid'}
                key={product.id}>
                <img className={'product-image'}
                    alt={`${product.name} :${product.description}`}
                    src={`${API_HOST}${product.imageLocation}`} />
                <GridListTileBar
                    title={
                        <div className='prod-name'>
                            <strong>{product.name}</strong>
                            <div className={getFlowerType(product).toLowerCase()}>
                                {getFlowerType(product)}
                            </div>
                        </div>
                    }
                    actionIcon={<div className='pricing'>${displayPrice}</div>}
                />
            </GridListTile>
        )
    }

    const getProductGridListTilesForCurrentCategory = () => {
        return filterProductsBy(products)
            .sort(sortProduct)
            .map(product => {
                const price = product.isFlower ? product.currentPrice.eighthPrice : product.currentPrice.price;
                const displayPrice = Number.isInteger(price) ?
                    price :
                    parseFloat(price).toFixed(2);
                return <GridListTile
                    onClick={handleProductSelection(product)}
                    className={'product-grid'}
                    key={product.id}>
                    <img className={'product-image'}
                        alt={`${product.name} :${product.description}`}
                        src={`${API_HOST}${product.imageLocation}`} />
                    <GridListTileBar
                        title={
                            <div className='prod-name'>
                                <strong>{product.name}</strong>
                                <div className={getFlowerType(product).toLowerCase()}>
                                    {getFlowerType(product)}
                                </div>
                            </div>
                        }
                        actionIcon={<div className='pricing'>${displayPrice}</div>}
                    />
                </GridListTile>
            });
    };

    const renderProductGridtListTilesForSection = () => {
        switch (section) {
            case 'products':
                const productsToRender = products
                    .filter(filterProducts)
                    .sort(sortProduct);
                return productsToRender.map(product => createProductGridTile(product));
            case 'categories':
                if (value) {
                    const productsForCategory = products
                        .filter(filterProducts)
                        .sort(sortProduct);
                    return productsForCategory.map(product => createProductGridTile(product));
                } else {
                    return getProductGridListTilesForCurrentCategory();
                }
            case 'brands':
                if (value) {
                    const productsForBrand = products.filter(filterProducts).sort(sortProduct);
                    return productsForBrand.map(product => createProductGridTile(product));
                } else {
                    return getProductGridListTilesForCurrentCategory()
                }
            default:
                getProductGridListTilesForCurrentCategory();
            //return [];
        }
    }


    const handleProductSelection = (product) => () => {
        //TODO: Do we still want to push users to profile if not all documents are valid?
        if (loggedInUser && (!allDocumentsExist() || (allDocumentsVerified() && !allDocumentsNonExpired()))) {
            history.push("/profile");
        } else {
            ReactGA.event({
                category: 'Product',
                action: 'Product Selected for Details And Quantity',
                value: product.id,
                label: product.name
            });
            history.push(`/product/${product.id}`)
        }
    };

    const filterProducts = (product) => {
        if (menuFilters.brands) {
            if (!product.brand) return false;
            if (!menuFilters.brands.includes(product.brand.name)) return false;
        }
        if (menuFilters.categories) {
            if (product.productCategories.filter(category => menuFilters.categories.includes(category.name)).length === 0) return false
        }
        if (menuSearch && menuSearch !== "" && !product.name.toLowerCase().includes(menuSearch.toLowerCase())) return false
        if (section === "categories") {
            if (!product.productCategories.some(category => category.id === parseInt(value))) return false;
        }
        if (section === "brands") {
            if (!product.brand) return false;
            if (!(product.brand.id === parseInt(value))) return false
        }

        return true
    }

    const filterProductsBy = (products) => {
        //return array of all products that have a quantity, and match the chose category
        return products ? products.filter((product: any) => !!product.currentQuantityAvailable).sort(sortProduct)
            : [];
    };

    const getFlowerType = (product) => {
        if (!product) return null;
        const { flowerType } = product;
        if (flowerType === 'cbd') return "CBD";
        return (flowerType.charAt(0).toUpperCase() + flowerType.slice(1)).replace('_', ' ');
    };

    // eslint-disable-next-line
    const flowerTypeOrder = { sativa: 1, sativa_hybrid: 2, hybrid: 3, indica_hybrid: 4, indica: 5, cbd: 6, other: 7 };

    const sortProduct = (a, b) => {
        if (!menuSorting.sortParameter || menuSorting.sortParameter === '') return 0;

        if (menuSorting.sortParameter === 'byFlowerType') {
            if (a.flowerType !== b.flowerType) {
                return flowerTypeOrder[a.flowerType] < flowerTypeOrder[b.flowerType] ? -1 : 1;
            }
        }

        if (menuSorting.sortParameter === 'currentPrice') {
            const aPrice = a.isFlower ? a.currentPrice.eighthPrice : a.currentPrice.price;
            const bPrice = b.isFlower ? b.currentPrice.eighthPrice : b.currentPrice.price;
            if (aPrice === bPrice) return 0;
            if (menuSorting.sortMethod === 'descending') return aPrice < bPrice ? 1 : -1;
            else return aPrice < bPrice ? -1 : 1;
        } else {
            if (a[menuSorting.sortParameter] === b[menuSorting.sortParameter]) return 0;
            if (menuSorting.sortMethod === 'descending') return a[menuSorting.sortParameter] < b[menuSorting.sortParameter] ? 1 : -1
            else return a[menuSorting.sortParameter] < b[menuSorting.sortParameter] ? -1 : 1
        }

    };

    const allDocumentsNonExpired = () => {
        if (loggedInUser) {
            if (isRecreationalMarket) {
                return !!(idDocument
                    && idDocument.expirationDate
                    && parseDateEndOfDay(idDocument.expirationDate) > new Date())
            } else {
                return !!(medicalRecommendation
                    && medicalRecommendation.expirationDate
                    && parseDateEndOfDay(medicalRecommendation.expirationDate) > new Date()
                    && idDocument
                    && idDocument.expirationDate
                    && parseDateEndOfDay(idDocument.expirationDate) > new Date())
            }
        }
        return false;
    };

    const allDocumentsExist = () => {
        if (loggedInUser) {
            if (isRecreationalMarket) {
                return !!(userSignature && idDocument)
            } else {
                return !!(medicalRecommendation && idDocument)
            }
        }
        return false;
    };

    const allDocumentsVerified = () => {
        if (loggedInUser) {
            if (isRecreationalMarket) {
                return !!(idDocument && idDocument.verified)
            } else {
                return !!(medicalRecommendation
                    && medicalRecommendation.verified
                    && idDocument
                    && idDocument.verified)
            }
        }
        return false;
    };

    useEffect(() => {
        if (mostRecentDspr && dsprId && productCategories && productCategories.length > 0) {
            const dsprImage = new Image();
            dsprImage.src = dsprImageLocation;
            dsprImage.onload = () => {
                setShowMostRecentDsprModal(true);
                setTimeout(() => {
                    setShowMostRecentDsprModal(false);
                    setHasSeenDsprModal(true);
                }, 2000); // Auto close the modal and signify that the modal has been shown for the session after 2 sec
            }
        }
    }, [mostRecentDspr, dsprId, productCategories, dsprImageLocation, setHasSeenDsprModal]);


    const errorReturn = () => {
        if (!products || products.length < 1) {
            return Errors || <div className="msg-style">
                Uh oh! No products were returned when trying to load the menu. Please reach out to support@grasspit.com for help!
            </div>
        } else {
            return null;
        }
    }

    //***Keep for if we have an expandable options header
    //const handleOptionsHeaderExpansion = (panel) => (event, isExpanded) => {
    //    setExpanded(isExpanded ? panel : false);
    //}

    const determineLabelForLocationsMenu = () => {
        switch (currentMenuType) {
            case MENU_LOCATION_TYPES.CURRENT_LOCATION:
                return 'Current Location';

            case MENU_LOCATION_TYPES.DELIVERY_ADDRESS:
                if (!loggedInUser) return `${currentZipCode}`
                const deliveryAddress = loggedInUser.defaultAddress;
                return `${deliveryAddress.street} ${deliveryAddress.zipCode}`;

            case MENU_LOCATION_TYPES.DEFAULT_MENU:
                return loggedInUser && loggedInUser.zipCode ? `${loggedInUser.zipCode}` : `${currentZipCode}`;

            default:
                return 'Set Location'
        }
    }

    const labelForLocationsMenu = determineLabelForLocationsMenu();

    /**Options header. Shows the location menu and an expandable options card
     *  -> Only shown on render if dspr allows full menu ordering
     * */
    const optionsHeader = () => {
        return (
            <div className={'options-header-container' + (Errors || productCategories.length === 0 ? " error" : "")}>
                <div className={'accordion-summary-container'}>
                    <div
                        onClick={evt => {
                            evt.stopPropagation()
                        }}
                        onFocus={evt => {
                            evt.stopPropagation()
                        }}
                        className={'location-menu-button-container'}
                    >
                        <StyledLocationMenuLabel
                            control={LocationMenu}
                            label={labelForLocationsMenu}
                        />
                    </div>
                    <div onClick={evt => {
                        evt.stopPropagation()
                    }}
                        onFocus={evt => {
                            evt.stopPropagation()
                        }}
                        className={'options-menu-button-container'}>
                        <StyledLocationMenuLabel
                            control={FiltersMenu}
                            label={"Filters"}
                            labelPlacement="start"
                        />
                    </div>
                </div>
                <div style={styles.searchResultsContainer}>
                    {menuSearch && <div style={styles.searchResults}>
                        <Typography variant='body1' color='primary'>Results for: '{menuSearch}'</Typography>
                        <IconButton color='primary' onClick={() => clearMenuSearch()}><Close></Close></IconButton>
                    </div>}
                </div>
            </div>
        )
    }

    return <div className='menu-component'>
        <Fragment>
            {Notifications}
            <Dialog
                open={showMostRecentDsprModal}
                onBackdropClick={() => setShowMostRecentDsprModal(false)}
            >
                <DialogContent className="most-recent-dspr-modal">
                    <p>Now Showing</p>
                    <img src={dsprImageLocation} alt={dsprName} />
                    <p className="dspr-full-name">{dsprName}</p>
                </DialogContent>
            </Dialog>
            {dsprId && optionsHeader()}
            {dsprId && errorReturn()}
            {/* Change the top style property for the class product-container in styles.css if you adjust the height of the carousel*/}
            {products && products.length > 0 && <Card className='product-container' style={styles.productsContainer}>
                <CardContent style={styles.root as React.CSSProperties}>
                    <GridList cellHeight={'auto'} style={styles.gridList} cols={1}>

                        {getProductPromotionListTileForCurrentCategory()}
                        {renderProductGridtListTilesForSection()}
                    </GridList>
                </CardContent>
            </Card>}
        </Fragment>
    </div>
}

export default Menu;