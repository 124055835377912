import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Button, TextField, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = {
    form: {
        display: "flex", 
        flexDirection: "column" as const, 
        justifyContent: "center", 
        alignItems: "center", 
        textAlign: "left" as const, 
        paddingTop: 0
    },
    button: {
        width: "90%", 
        marginTop: "16px"
    }
}

const validate = values => {
    const errors:any = {};
    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email.trim())) {
        errors.email = 'Invalid email address';
    }
    if (!values.password) {
        errors.password = 'Required';
    }
    return errors
};

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <TextField label={label} placeholder={label} type={type} {...input}
            error={touched && error ? true : false}
            helperText={touched && error ? error : ''}
            margin="normal"
            style={{ width: 250, marginTop: 8 }}
        />
    </div>
);

interface LoginFormProps extends Partial<InjectedFormProps> {
    handleSubmit?: any;
    handleCancel?: any;
    onSubmit?: any;
    enableButton: boolean;
    errorMessage: string;
    classes
}
const LoginForm = (props: LoginFormProps) => {
    const { handleSubmit, enableButton, handleCancel, errorMessage, classes } = props;

    const keyPressCallback = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSubmit();
        }
    }

    return (
        <form onSubmit={handleSubmit} onKeyPress={e => keyPressCallback(e)} className={classes.form}>
            <Field name="email" type="text" component={renderField} label="Email" />
            <Field name="password" type="password" component={renderField} label="Password" />
            <Link to="/forgot-password" style={{width: 250}}>Forgot your password?</Link>
            {errorMessage && <div className='error' style={{marginTop: "16px"}}>{errorMessage}</div>}
            <Button color="primary" variant="contained" onClick={handleSubmit} disabled={!enableButton} className={classes.button}>
                Log In
            </Button>
            <Button color="primary" variant="outlined" onClick={handleCancel} className={classes.button}>Sign Up</Button>
        </form>
    )
};

export default withStyles(styles)(reduxForm<any, LoginFormProps>({
    form: 'loginForm', // a unique identifier for this form
    validate
})(LoginForm))
