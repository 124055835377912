import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const validate = (values) => {
    const errors = {};
    const requiredFields = ['street', 'zipCode'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });
    return errors
};

const renderField = ({ input, label, type, meta: { asyncValidating, touched, error } }) => (
    <div>
        <TextField label={label} placeholder={label} type={type}
            error={touched && error ? true : false}
            helperText={touched && error ? error : ''}
            margin="normal"
            style={{ width: 250 }}
            {...input}
        />
    </div>
);

let AddressForm = (props) => {
    const { handleSubmit,
        checkout,
        showButton,
        buttonEnabled,
        // submitting
    } = props;

    return (
        <form onSubmit={handleSubmit} id="address-form"> {/* Added ID to support external submit button */}
            <Field name="street" type="text" component={renderField} label="Street" />
            <Field name="aptNumber" type="text" component={renderField} label="Apartment Number" />
            <Field name="zipCode" type="number" component={renderField} label="Zip Code" />

            {/* Button is not needed on checkout page: external buttons handles form submission */}
            {(showButton) && <Button color="primary" variant="contained"
                                     size={checkout ? 'medium' : 'small'}
                onClick={handleSubmit}
                disabled={buttonEnabled !== undefined ? !buttonEnabled : undefined}
                fullWidth={checkout}
                style={{ marginLeft: '0px', marginRight: '0px', marginTop: 16, width: 200 }}
            >
                {checkout ? "Place Order" : "Update"}
            </Button>}
        </form>
    )
};

AddressForm = reduxForm({
    form: 'defaultAddressForm',
    validate
})(AddressForm);

export default connect(
    (state, props) => {
        const { address } = props;
        return { initialValues: address };
    }
)(AddressForm)
