import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Typography, withStyles, Card, CardContent, CardMedia } from "@material-ui/core";
import { withTheme } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router";
import { DocumentType } from "../components/UserDocument";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUserIdDocumentFromState, getUserMedicalRecommendationFromState } from "../selectors/userDocumentsSelector";
import {
    getUserDocumentUrl,
    getUserIdDocument,
    getUserMedicalRecommendation,
    LOGGED_IN_USER_INFO_SUCCESS,
    uploadUserDocument,
    USER_ID_DOCUMENT, USER_MEDICAL_RECOMMENDATION
} from "../actions/userActions";
import { getLoggedInUserId } from "../selectors/userSelectors";
import { parseDateEndOfDay } from "../util/util";
import UserDocumentUploadForm from "./RevisedUserDocumentUploadForm";

type DocumentStatusType = 'Verified' | 'Under Review' | 'Could Not Verify' | '';

const styles = {
    uploadDocumentSection: {
        marginTop: 16,
    },
    documentCard: {
        width: "calc(100% - 32px)",
        height: "calc(100% - 32px)",
        margin: 16
    },
    documentUploadFormCard: {
        minHeight: 120,
    }
}

interface UserDocumentStatusPageProps {
    classes: any;
    theme: any;
}

const UserDocumentStatusPage = ({classes, theme}: UserDocumentStatusPageProps) => {
    const history = useHistory();
    const { documentType } = useParams<{ documentType: DocumentType }>();
    const dispatch = useDispatch();

    const isDocUserId = documentType === 'id';

    const userId = useSelector(getLoggedInUserId, shallowEqual);
    const userIdDoc = useSelector(state => documentType === 'id' && getUserIdDocumentFromState(state), shallowEqual);
    const userMedRec = useSelector(state => documentType === 'med' && getUserMedicalRecommendationFromState(state), shallowEqual);

    const [expiryDate, setExpiryDate] = useState(undefined);
    const [expiryDays, setExpiryDays] = useState(undefined);
    const [documentStatus, setDocumentStatus] = useState<DocumentStatusType>('');
    const [documentStatusColor, setDocumentStatusColor] = useState<'primary' | 'secondary' | 'error'>('primary');

    useLayoutEffect(() => {
        if((userIdDoc && userIdDoc.expirationDate) || (userMedRec && userMedRec.expirationDate)) {
            const document = isDocUserId ? userIdDoc : userMedRec;

            const expiryDateOfDocument = document && document.expirationDate ? parseDateEndOfDay(document.expirationDate) : undefined
            setExpiryDate(expiryDateOfDocument)
            const idExpirDays = Math.floor((expiryDateOfDocument.valueOf() - new Date().valueOf()) / (1000 * 60 * 60 * 24));
            setExpiryDays(idExpirDays);

            setDocumentStatusColor(document.verified ? "primary" : document.visible ? "secondary" : "error");
            setDocumentStatus(document.verified? "Verified": document.visible ? "Under Review" : "Could Not Verify")
        }
    },[userIdDoc, userMedRec, isDocUserId])


    /**fetch userDocs if objects in store are incomplete
     * note: incomplete id document objects are obtained on every hard refresh of a page.
     * They are returned from the call to fetch the logged in user
     */
    useEffect(() => {

        if (!userIdDoc || (userIdDoc && (!userIdDoc.user || !userIdDoc.expirationDate))) {
            if (documentType === 'id') {
                dispatch<any>(getUserIdDocument())
                    .then(response => {
                    });
            }

            if (documentType === 'med') {
                dispatch<any>(getUserMedicalRecommendation())
                    .then(response => {
                    });
            }
        }
    }, [documentType, userIdDoc, dispatch]);

    //console.log('document type:', documentType);
    //console.log('userIdDoc:', userIdDoc);
    //console.log('userMedRec:', userMedRec);

/* to get user Id Document`
    <img src={getUserDocumentUrl(USER_ID_DOCUMENT, driver.currentInProcessOrder.user.id)} alt={USER_ID_DOCUMENT} /> : <p>No ID Document</p>
*/
    const handleDocumentUpload = async (evt) => {
        if(evt && evt.imageFile && evt.imageFile.length > 0) {
            let response;
            if(isDocUserId) {
               response = await dispatch(uploadUserDocument(userId, USER_ID_DOCUMENT, evt.imageFile[0]))
            } else {
               response = await dispatch(uploadUserDocument(userId, USER_MEDICAL_RECOMMENDATION, evt.imageFile[0]))
            }

            if(response.type === LOGGED_IN_USER_INFO_SUCCESS) {
                history.push("/profile");
            }
        }
    }

    return (

        <section className={'full-width-section'}>
            <div className={classes.uploadDocumentSection}>
                <div>
                    <Typography variant='body1'><strong>Upload New</strong></Typography>
                    <Card className={'full-width-card '} style={{minHeight: 120, height: 'fit-content', display: 'flex'}}>
                        {/*<CardActionArea style={{width: '100%'}} centerRipple={true} disabled={isFileSelectedFromUploadForm}>*/}
                        <div style={{width: '100%'}}>
                        <UserDocumentUploadForm
                            onSubmit={handleDocumentUpload}
                        />
                        </div>
                    </Card>
                </div>
            </div>

            {(userIdDoc || userMedRec) && <div className={classes.uploadDocumentSection}>
                <div>
                    <Typography variant='body1'><strong>Current</strong></Typography>
                    <Card className={'full-width-card'}>
                        <CardMedia
                            component="img"
                            className={classes.media}
                            //height={140}
                            src={getUserDocumentUrl(documentType === 'id' ? USER_ID_DOCUMENT : USER_MEDICAL_RECOMMENDATION, userId)}
                            title={documentType === 'id' ? 'Current ID Document' : 'Current Medical Recommendation'}
                            style={{height:"50%", maxHeight: "600px", objectFit: "contain"}}
                        />
                        <CardContent style={{paddingBottom: 16}}>
                            {expiryDays && expiryDate &&
                            <div style={{textAlign: 'left'}}>
                                <Typography color={'textSecondary'}>{`Time Left: ${expiryDays} days`}</Typography>
                                <Typography color={'textSecondary'}>{`Expires On: ${expiryDate.getMonth() + "/" + expiryDate.getDate() + "/" + expiryDate.getFullYear() % 100}`}</Typography>
                                <Typography color={'textSecondary'} style={{display: 'inline-block'}}>
                                    Status: {<span style={{display: 'inline-block', color: theme.palette[documentStatusColor]['main']}}>{documentStatus}</span>}
                                </Typography>

                                {documentStatus === 'Could Not Verify' && <div style={{marginTop: 16}}>
                                {/*{documentStatus === 'Verified' && <div style={{marginTop: 16}}>*/}
                                    <Typography gutterBottom={true}>There was a problem verifying your document.</Typography>
                                    <Typography>Please upload a new document to restart the verification process.</Typography>
                                </div>}
                            </div>}

                        </CardContent>
                    </Card>
                </div>
            </div>}
            
        </section>
    )
}

export default withTheme()(withStyles(styles)(UserDocumentStatusPage));