import {
	PRELOAD_ACCESS_TOKEN_FROM_LOCAL_STORAGE,
	GET_APP_ACCESS_TOKEN_SUCCESS,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGOUT,
} from '../actions/oauthActions';
import {
	LOGGED_IN_USER_INFO_SUCCESS,
	GET_USER_MEDICAL_RECOMMENDATION_SUCCESS,
	GET_USER_ID_DOCUMENT_SUCCESS,
	VALIDATE_EMAIL_FAILURE,
	RESET_PASSWORD_FAILURE,
	GET_ALL_DSPR_SERVICING_ZIP_CODE_SUCCESS,
	GET_USER_SIGNATURE_SUCCESS,
	CREATE_NEW_USER_FAILURE,
} from '../actions/userActions';
import {
	GET_MENU_BY_DEFAULT_ADDRESS_SUCCESS,
	GET_MENU_BY_DEFAULT_ADDRESS_FAILURE,
	GET_MENU_BY_ZIPCODE_FAILURE,
	GET_MENU_BY_ZIPCODE_SUCCESS,
	GET_MENU_BY_LATLONG_SUCCESS,
	GET_MENU_BY_LATLONG_FAILURE,
	GET_DSPR_SUCCESS,
	CLEAR_DSPRS,
	GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_SUCCESS,
	SET_IS_FULL_MENU_SHOWN_TO_USER,
	SET_CURRENT_MENU_TYPE,
	HIDE_FULL_MENU_DELIVERY_MODAL_FROM_USER,
} from '../actions/menuActions';
import {
	GET_ORDER_COST_SUCCESS,
	SUBMIT_ORDER_FAILURE,
	CANCEL_ORDER_SUCCESS,
	ORDER_HISTORY_SUCCESS,
	SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_SUCCESS,
	LOG_AEROPAY_TRANSACTION_SUCCESS,
} from '../actions/orderActions';
import { CLEAR_API_ERROR_MESSAGE } from '../actions/apiUIHelperActions';
import merge from 'lodash/merge';
import entitiesReducer, { initialState as entitiesInitialState } from './entitiesReducer';
import { stat } from 'fs';

const initialState = {
	accessToken: '',
	loggedInUserId: '',
	errorMessage: '',
	entities: entitiesInitialState,
	isRecreationalMarket: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_APP_ACCESS_TOKEN_SUCCESS:
		case LOGIN_SUCCESS:
		case PRELOAD_ACCESS_TOKEN_FROM_LOCAL_STORAGE:
			return { ...state, accessToken: action.accessToken };
		case LOGOUT:
			return {... initialState, entities: { ...state.entities, usersMedicalRecommendations: {}, usersSignatures: {}, usersIdDocuments: {}, addresses: {}, orders: {}, users: {}}, isRecreationalMarket: state.isRecreationalMarket};
		case LOGGED_IN_USER_INFO_SUCCESS: {
			let entities = action.response.entities;
			let usersFromResponse = entities.users;
			let loggedInUserId = Object.keys(usersFromResponse)[0];
			return merge(
				{},
				{ ...state, entities: entitiesInitialState },
				{ loggedInUserId, entities: entitiesReducer(state.entities, action) },
			);
		}
		case LOGIN_FAILURE:
			return { ...state, errorMessage: 'Incorrect email/password combination' };
		// we re-update the global state based on menu success... isRecreational
		// a reducer returns new state
		case CREATE_NEW_USER_FAILURE:
			return { ...state, errorMessage: action.error };
		case CLEAR_DSPRS:
			return {
				...state,
				entities: {
					...state.entities,
					DSPRs: {},
				},
			};
		case GET_DSPR_SUCCESS: {
			const newDSPRs = action.response.entities.DSPRs;
			const currentDSPR = state.entities.DSPRs;
			// TODO: destructure only imageLocation and nothing else for the new DSPRs
			return {
				...state,
				entities: {
					...state.entities,
					DSPRs: {
						...currentDSPR,
						...newDSPRs,
					},
				},
			};
		}
		case GET_ALL_DSPR_SERVICING_ZIP_CODE_SUCCESS: {
			let entities = action.response.entities;
			let dspr_count = entities.DSPRs ? Object.keys(entities.DSPRs).length : 0;
			let isRecreationalMarket =
				dspr_count === 1 ? entities.DSPRs[Object.keys(entities.DSPRs)[0]].isRecreationalMarket : false;
			const newState = { ...state, entities: entitiesInitialState };
			return merge({}, newState, { isRecreationalMarket, entities: entitiesReducer(state.entities, action) });
		}
		case GET_MENU_BY_LATLONG_SUCCESS:
		case GET_MENU_BY_DEFAULT_ADDRESS_SUCCESS:
		case GET_MENU_BY_ZIPCODE_SUCCESS: {
			let entities = action.response.entities;
			let isRecreationalMarket = entities.menu[0].isClosed
				? false
				: entities.DSPRs[entities.menu[0].dspr].isRecreationalMarket;
			const newState = { ...state, entities: entitiesInitialState };
			return merge({}, newState, { isRecreationalMarket, entities: entitiesReducer(state.entities, action) });
		}
		case GET_USER_ID_DOCUMENT_SUCCESS:
		case GET_USER_MEDICAL_RECOMMENDATION_SUCCESS:
		case GET_USER_SIGNATURE_SUCCESS:
		case GET_ORDER_COST_SUCCESS:
		case CANCEL_ORDER_SUCCESS:
		case GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_SUCCESS:
		case ORDER_HISTORY_SUCCESS:
		case SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_SUCCESS:
		case LOG_AEROPAY_TRANSACTION_SUCCESS:
		case SET_IS_FULL_MENU_SHOWN_TO_USER:
		case SET_CURRENT_MENU_TYPE:
		case HIDE_FULL_MENU_DELIVERY_MODAL_FROM_USER:
			// case SET_IS_FULL_MENU_SHOWN_TO_USER_TO_FALSE:
			const newState = { ...state, entities: entitiesInitialState };
			return merge({}, newState, { entities: entitiesReducer(state.entities, action) });
		case CLEAR_API_ERROR_MESSAGE:
			return { ...state, errorMessage: '' };
		case SUBMIT_ORDER_FAILURE:
		case VALIDATE_EMAIL_FAILURE:
		case RESET_PASSWORD_FAILURE:
			return { ...state, errorMessage: action.error };
		case GET_MENU_BY_LATLONG_FAILURE:
		case GET_MENU_BY_ZIPCODE_FAILURE:
		case GET_MENU_BY_DEFAULT_ADDRESS_FAILURE:
			return merge(
				{},
				{ ...state, entities: entitiesInitialState },
				{ entities: { ...state.entities, menu: {}, productCategories: {}, dspProducts: {} } },
			);
		default:
			return state;
	}
};
