import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const validate = values => {
    const errors = {};
    if (!values.code) {
        errors.code = 'Required';
    }
    return errors
};

const renderField = ({ input, label, type, meta: { asyncValidating, touched, error } }) => (
    <div>
        <TextField label={label} placeholder={label} type={type}
            margin="normal"
            style={{width:250}}
            {...input}
            />
    </div>
);

const CouponCodeForm = (props) => {
    const { handleSubmit,
        couponCodes,
        // submitting
    } = props;
    
    return (
        <form onSubmit={handleSubmit}>
            <Field name="code" type="text" component={renderField} label={(couponCodes && couponCodes.size > 0) ? "Add Another Coupon": "Coupon Code"}/>
            <Button color="secondary" variant="contained" onClick={handleSubmit}>Apply</Button>
        </form>
    )
};

export default reduxForm({
    form: 'couponCodeForm', // a unique identifier for this form
    validate,
})(CouponCodeForm)
