import React, {Component} from 'react';
import {Card, CardHeader, CardContent} from '@material-ui/core';

export default class ContactUs extends Component {
    render() {
        return <div>
            <Card style={{margin: 16}}>
                <CardHeader title="Contact Us"/>
                <CardContent>
                    <div><p>E-mail tech support: <a href="mailto:support@grasspit.com">support@grasspit.com</a></p></div>
                    <div><p>Call/Text Grassp support: <a href="tel:+16235623308">+1(623)562-3308</a></p></div>
                    <div><p>Or visit <a href="https://grassp.it">www.grassp.it</a> for more information.</p></div>
                </CardContent>
            </Card>
            <Card style={{margin: 16}}>
                <CardHeader title="Booking A Health Consultation"/>
                <CardContent>
                    <div>If you are interested in getting a health consultation, please give us a call, text, or email and we will be happy to assist you!</div>
                    <div><p>Please be aware that we can only give health consultations in locations where we are currently available for service. For a full list of our current locations, please visit the "Delivery Hours" section</p></div>
                </CardContent>
            </Card>
        </div>
    }
}