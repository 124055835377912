import React, { Fragment } from 'react';
import { IconButton } from '@material-ui/core';

export const MENU_LOCATION_TYPES = {
    CURRENT_LOCATION: 'CURRENT_LOCATION',
    DEFAULT_MENU: 'DEFAULT_MENU',
    DELIVERY_ADDRESS: 'DELIVERY_ADDRESS'
};

interface SetFiltersMenuProps {
    openFiltersMenu: () => void;
    Icon: JSX.Element;
}

const FiltersMenu: React.FC<SetFiltersMenuProps> = props => {
    const { Icon, openFiltersMenu } = props;

    return <Fragment>
        <IconButton
            aria-haspopup="true"
            onClick={e => openFiltersMenu()}
        >
            {Icon}
        </IconButton>
    </Fragment>
}

export default FiltersMenu;