import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button, Checkbox, withStyles } from '@material-ui/core';

const styles = {
    form: {
        display: "flex",
        flexDirection: "column" as const
    },
    checkboxStyles: {
        width: 250,
        marginTop: 6,
        display: "flex",
        alignItems: "flex-start",
        flexDirection: 'row' as const,
        textAlign: 'left' as const,
        margin: "auto"
    },
    errorMessage: {
        width: 250,
        margin: "auto",
        color: "red"
    },
    button: {
        marginTop: 16
    }
}

const renderField = ({ input, label, type, meta: { touched, error } }) => {

    console.log("input", input)
    return (
        <div>
            {type === 'checkbox' ?
                <Checkbox
                    className="checkbox"
                    checked={!!input.value}
                    onChange={input.onChange}
                    style={touched && error ? { color: "red" } : {}}
                />
                :
                <TextField label={label} hint={label} type={type} {...input}
                    error={touched && error ? true : false}
                    helperText={touched && error ? error : ''}
                    margin="normal"
                    style={{ width: 250 }}
                />
            }
        </div>)
}

    ;

interface ZipCodeFormProps extends Partial<InjectedFormProps> {
    handleSubmit?: any,
    handleCancel?: any
    enableButton: boolean,
    errorMessage: string
    classes: any

}

const ZipCodeForm = (props: ZipCodeFormProps) => {
    const { handleSubmit, enableButton, handleCancel, classes, errorMessage, error } = props;
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Field name="zipCode" type="text" component={renderField} label="Zip Code" />
            {errorMessage ? <p className={classes.errorMessage}>{errorMessage}</p> : null}
            <Button color="primary" variant="contained" onClick={handleSubmit} disabled={!enableButton} className={classes.button} id="sign-up-button">
                View Menu
            </Button>
        </form>
    );
};

const validate = values => {
    const errors: any = {};
    // TODO: [angel] validate zip codes.
    if (!values.zipCode) {
        errors.zipCode = 'Required';
    } else if (isNaN(Number(values.zipCode))) {
        errors.zipCode = 'Must be a number';
    } else if (values.zipCode.length !== 5) {
        errors.zipCode = 'Must be 5 digits';
    }

    return errors;
};
//
// const asyncValidate = (values, dispatch, props, field ) => {
//     return props.checkIfEmailTaken(values["email"]).then(emailTaken => {
//         if(emailTaken) throw { email: 'Email is already taken' }
//     });
// };

export default withStyles(styles)(reduxForm<{}, ZipCodeFormProps>({
    form: 'userZipCodeForm',
    validate
})(ZipCodeForm));
