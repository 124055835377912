import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';

const validate = values => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'Required';
    }
    if (!values.lastName) {
        errors.lastName= 'Required';
    }
    if (!values.email) {
        errors.email= 'Required';
    }
    if (!values.phoneNumber) {
        errors.phoneNumber= 'Required';
    }
    return errors
};

const renderField = ({ input, label, type, disabled, meta: { asyncValidating, touched, error } }) => (
    <div>
        <TextField label={label} hint={label} type={type} {...input}
                   error={touched && error ? true : false}
                   helperText={touched && error ? error : ''}
                   margin="normal"
                   style={{width:250}}
                   disabled={disabled}/>
    </div>
);

let BasicInformationForm = (props) => {
    const { handleSubmit,
        // submitting
    } = props;
    return (
        <form onSubmit={handleSubmit}>
            <Field name="firstName" type="text" component={renderField} label="First" disabled/>
            <Field name="lastName" type="text" component={renderField} label="Last" disabled/>
            <Field name="email" type="text" component={renderField} label="Email" disabled/>
            <Field name="phoneNumber" type="text" component={renderField} label="Phone Number" disabled/>
            {/*<Button color="secondary" variant="contained" label="Save Changes" onClick={handleSubmit}/>*/}
        </form>
    )
};

BasicInformationForm = reduxForm({
    form: 'basicInformationForm', // a unique identifier for this form
    validate
})(BasicInformationForm)

export default connect(
    (state, props) => {
        const {user} = props;
        return user ? {initialValues: user} : {};
    }
)(BasicInformationForm)
