import { createSelector } from 'reselect';
import { getLoggedInUser } from './userSelectors';
import { getIsRecreationalMarket } from './menuSelector';

export const getUserIdDocumentFromState = (state, props) => {
    return state.api.entities.users && state.api.entities.usersIdDocuments ?
        state.api.entities.users[state.api.loggedInUserId] ?
            state.api.entities.usersIdDocuments[state.api.entities.users[state.api.loggedInUserId].identificationDocument] :
            null :
        null;
};

export const getUserMedicalRecommendationFromState = (state, props) => {
    return state.api.entities.users && state.api.entities.usersMedicalRecommendations ?
        state.api.entities.users[state.api.loggedInUserId] ?
            state.api.entities.usersMedicalRecommendations[state.api.entities.users[state.api.loggedInUserId].medicalRecommendation] :
            null :
        null;
};

export const getUserSignatureFromState = (state, props) => {
    return state.api.entities.users && state.api.entities.usersSignatures ?
        state.api.entities.users[state.api.loggedInUserId] ?
            state.api.entities.usersSignatures[state.api.entities.users[state.api.loggedInUserId].userSignature] :
            null :
        null;
};

export const allDocumentsVerified = createSelector(
    [
        getLoggedInUser,
        getIsRecreationalMarket,
        getUserIdDocumentFromState,
        getUserMedicalRecommendationFromState
    ],
    (
        loggedInUser,
        isRecreationalMarket,
        idDocument,
        userMedicalRecommendation
    ) => {
        if (loggedInUser) {
            if (isRecreationalMarket) {
                return !!(idDocument && idDocument.verified);
            }
            return !!(userMedicalRecommendation && userMedicalRecommendation.verified && idDocument && idDocument.verified);
        }

        return false;
    }
);