import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Typography } from '@material-ui/core';

import { Card, CardHeader, CardContent, withStyles } from '@material-ui/core';

import { createNewUser, createNewUserWithReferral, getAllDSPRServicingZipCode } from '../actions/userActions';
import { attemptLogin } from '../actions/oauthActions';
import { getError } from "../selectors/errorSelector";
import { getLoggedInUser } from '../selectors/userSelectors';
import { window } from 'browser-monads'

import SignUpForm from './SignUpForm';
import UserIdAndRecommendationUpload from './UserIdAndRecommendationUpload';
import { getMenuZipCode } from '../selectors/menuSelector';


const styles = {
    signupPage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 880,
        scrollY: "hidden",
    },
    header: {
        marginTop: 8,
        marginBottom: 8,
        display: "flex",
        fontFamily: "reklame-script",
        userSelect: "none" as const,
        fontWeight: 600,
        justifyContent: "center",
    },
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    card: {
        maxWidth: 450,
        marginTop: "10%",
        height: "fit-content",
        overflowY: "auto" as const,
        width: "90%"
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 0,
    },
    cartButton: {
        marginLeft: 20,
        marginRight: -12,
    },
    list: {
        width: 200,
    },
    fullList: {
        width: 'auto',
    },
    badge: {
        top: 12,
        right: 7,
        background: "black",
        backgroundSize: "50%",
        color: "white",
        width: '50%',
        height: '50%'
    },
    health: {
        color: '#77C03E',
        fontSize: "100%",
        fontStyle: "italicize",
        alignSelf: "center",
        fontWeight: 600
    }
};
class SignUp extends Component<any, { errorMessage: string, enableButton: boolean, user?: any }> {
    state = {
        errorMessage: null,
        enableButton: true,
    }


    componentWillReceiveProps = newProps => {
        if (newProps.errorMessage) {
            this.setState({
                errorMessage: newProps.errorMessage,
                enableButton: true,
            });
        }
    }

    handleSubmit = userSignUpValues => {
        const responseFunc = response => {
            if (!response.error) {
                window.ca("send", "signup_button_clicked", "sign_up_page", "Signup", "click", "sign_up");
                this.props.attemptLogin(userSignUpValues.email.trim(), userSignUpValues.password, "/signup");
            } else {
                const emailAlreadyUsedExceptionRegularExpression = /Email '.*' is already used./;
                if (response.error.match(emailAlreadyUsedExceptionRegularExpression) !== null) {
                    this.props.attemptLogin(userSignUpValues.email.trim(), userSignUpValues.password, "/")
                        .then(() => { this.setState({ errorMessage: 'Email is already in use' }) });
                }
            }
        };
        this.setState({ enableButton: false }, () => {
            const referrerIdentityCode = this.props.location && this.props.match.params.code;
            if (referrerIdentityCode) {
                this.props.createNewUserWithReferral(userSignUpValues, referrerIdentityCode).then(responseFunc);
            } else {
                this.props.createNewUser(userSignUpValues).then(response => {
                    if (!response.error) {
                        this.props.getAllDSPRServicingZipCode(userSignUpValues.signupZipCode)
                            .then(() => responseFunc(response));
                    } else {
                        this.setState({ enableButton: true });
                        return;
                    }
                });
            }

            this.setState({ user: userSignUpValues });
            ReactGA.event({
                category: 'User',
                action: 'Created an Account',
            });
        })
    };

    handleLogIn = () => {
        this.props.history.replace("/login")
    }

    render() {
        const referrerIdentityCode = this.props.match.params && this.props.match.params.code;
        return (
            <section className={"page " + this.props.classes.signupPage}
                style={{ textAlign: 'center' }}
            >
                {this.props.loggedInUser ? (
                    <UserIdAndRecommendationUpload user={this.props.loggedInUser} />
                ) : (
                    // <Card className="card-with-form" style={{ boxShadow: 'none' }}>
                    //     <CardHeader
                    //         title="Let's create your new Grassp Account!"
                    //         subheader={
                    //             <div>
                    //                 <div>Get started by filling this out. Do you already have an account?</div>
                    //                 <Link to="/login">Log in.</Link>
                    //             </div>
                    //         }
                    //     />
                    //     <CardContent>
                    //         {this.state.errorMessage && <div className={'msg-style error'}>{this.state.errorMessage}</div>}
                    //         <SignUpForm onSubmit={this.handleSubmit} enableButton={this.state.enableButton}/>
                    //         {referrerIdentityCode && <div><br />Referral code: {referrerIdentityCode}</div>}
                    //     </CardContent>
                    // </Card>
                    <Card className={this.props.classes.card}>
                        <CardHeader
                            title={
                                <Typography variant="h4" color="inherit" className={this.props.classes.grow} onClick={() => <Link to="/" />} style={{ margin: 'auto', width: 'auto' }}>
                                    <p className={this.props.classes.header}>
                                        <img src='/images/GrasspLogo.png' alt="Grassp" style={{ height: '30px', top: '6px', position: 'relative', marginRight: '1px' }} />
                                        rassp<em className={this.props.classes.health}> Health</em>
                                    </p>
                                </Typography>
                            }
                            subheader={
                                <div>Get started by filling this out, or <Link to="/login" replace >Log in</Link> if you already have an account!</div>
                            }
                        />
                        <CardContent>
                            {this.state.errorMessage && <div className={'msg-style error'}>{this.state.errorMessage}</div>}
                            <SignUpForm onSubmit={this.handleSubmit} handleCancel={this.handleLogIn} enableButton={this.state.enableButton} errorMessage={this.state.errorMessage} initialValues={{
                                signupZipCode: this.props.initialZipCode
                            }} />

                            {referrerIdentityCode && <div><br />Referral code: {referrerIdentityCode}</div>}
                        </CardContent>
                    </Card>
                )}
            </section>
        );
    }
}

const mapStateToProps = state => ({
    loggedInUser: getLoggedInUser(state),
    errorMessage: getError(state),
    initialZipCode: getMenuZipCode(state),
})

const mapDispatchToProps = {
    getAllDSPRServicingZipCode,
    createNewUser,
    createNewUserWithReferral,
    attemptLogin,
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp)));
