import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer as routing } from 'react-router-redux'
import api from './apiReducer';
import cart from './cartReducer';
import menu from "./menuReducer";

const rootReducer = combineReducers({
    form: formReducer,
    routing,
    api,
    menu,
    cart,
});

export default rootReducer;