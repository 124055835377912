import React, { useState, useEffect} from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';

const validate = values => {
    const errors: any = {};
    if (values.specialInstructions && values.specialInstructions.length > 255) {
        errors.specialInstructions = `${values.specialInstructions.length - 255} characters over limit`;
    }
    return errors
};

const renderField = ({ input, label, type, meta: { asyncValidating, touched, error }, ...custom }) => (
    <TextField label={label} type={type} {...input} style={{width: 250}}
        error={!!((touched && error) || (!touched && ( error ? error.includes('over limit') : false)))}
        helperText={error ? (!touched && error === "Required") ? custom.helper :  error : custom.helper } {...custom} />

);

interface specialInstructionsFormProps {

}

const SpecialInstructionsForm = (props:any) => {
    const { handleSubmit,
        setFormError,
        valid,
        setSpecialInstructionText,
        className
        // submitting
    } = props;

    const [descriptionLength, setDescriptionLength] = useState<number>(0)
    const countCharacters = (event) => {
        setDescriptionLength(event.target.textLength)
        setSpecialInstructionText(event.target.value)
    }

    useEffect(()=> {
        setFormError(valid)
    }, [valid, setFormError])

    
    return (
        <form onSubmit={handleSubmit}>
            <Field name='specialInstructions' 
                    multiline
                    component={renderField} 
                    label="Special Instructions" 
                    variant="outlined"
                    style={className} 
                    onChange={countCharacters}
                    helper={`${255 - descriptionLength} characters remaining`}
                    />
            {/* <Field name="specialInstructions" type="text" component={renderField} label="Special Instructions"/> */}
        </form>
    )
};

export default reduxForm({
    form: 'specialInstructionsForm', // a unique identifier for this form
    validate
})(SpecialInstructionsForm)
