import { CALL_API, Schemas } from '../middleware/api';
import { updateLoggedInUserInfo } from './userActions';

export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';

const orderCanceler = (orderId) => {
	return {
		[CALL_API]: {
			httpAction: 'POST',
			types: [CANCEL_ORDER, CANCEL_ORDER_SUCCESS, CANCEL_ORDER_FAILURE],
			endPoint: 'order/canceled',
			body: { id: orderId },
			schema: Schemas.ORDER,
		},
	};
};

export const cancelOrder = (order) => (dispatch, getState) => {
	return dispatch(orderCanceler(order)).then(() => dispatch(updateLoggedInUserInfo()));
};

export const LOG_AEROPAY_TRANSACTION = 'LOG_AEROPAY_TRANSACTION';
export const LOG_AEROPAY_TRANSACTION_SUCCESS = 'LOG_AEROPAY_TRANSACTION_SUCCESS';
export const LOG_AEROPAY_TRANSACTION_FAILURE = 'LOG_AEROPAY_TRANSACTION_FAILURE';

const aeroPayTransactionLogger = (orderId, aeroPayUUID, aeroPayAmountPaid) => {
	return {
		[CALL_API]: {
			httpAction: 'POST',
			types: [LOG_AEROPAY_TRANSACTION, LOG_AEROPAY_TRANSACTION_SUCCESS, LOG_AEROPAY_TRANSACTION_FAILURE],
			endPoint: 'order/aeropay/confirm',
			body: { id: orderId, aeroPayUUID, aeroPayAmountPaid },
			schema: Schemas.ORDER,
		},
	};
};

export const logAeroPayTransaction = (orderId, uuid, total) => (dispatch, getState) => {
	return dispatch(aeroPayTransactionLogger(orderId, uuid, total));
};

export const SET_AEROPAY_REFERENCE_UUID_FOR_ORDER = 'SET_AEROPAY_REFERENCE_UUID_FOR_ORDER';
export const SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_SUCCESS = 'SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_SUCCESS';
export const SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_FAILURE = 'SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_FAILURE';

const aeroPayReferenceUUIDSetter = (aeroPayUUID, orderId) => {
	return {
		[CALL_API]: {
			httpAction: 'POST',
			types: [
				SET_AEROPAY_REFERENCE_UUID_FOR_ORDER,
				SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_SUCCESS,
				SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_FAILURE,
			],
			endPoint: 'order/aeropay/set-reference-uuid',
			body: { aeroPayUUID, id: orderId },
			schema: Schemas.ORDER,
		},
	};
};

export const setAeroPayReferenceUUIDForOrder = (aeroPayUUID, orderId) => (dispatch, getState) => {
	return dispatch(aeroPayReferenceUUIDSetter(aeroPayUUID, orderId));
};

export const GET_ORDER_COST = 'GET_ORDER_COST';
export const GET_ORDER_COST_SUCCESS = 'GET_ORDER_COST_SUCCESS';
export const GET_ORDER_COST_FAILURE = 'GET_ORDER_COST_FAILURE';

const orderCostGetter = (order) => ({
	[CALL_API]: {
		httpAction: 'POST',
		types: [GET_ORDER_COST, GET_ORDER_COST_SUCCESS, GET_ORDER_COST_FAILURE],
		endPoint: 'order/cost',
		body: order,
		schema: Schemas.ORDER,
	},
});

export const getOrderCost = (order) => (dispatch, getState) => {
	const filteredOrder = {
		...order,
		orderDetails: order.orderDetails.map((detail) => ({ ...detail, product: { id: detail.product.id } })),
	};
	return dispatch(orderCostGetter(filteredOrder));
};

export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE';

const orderSubmitter = (order) => ({
	[CALL_API]: {
		httpAction: 'POST',
		types: [SUBMIT_ORDER, SUBMIT_ORDER_SUCCESS, SUBMIT_ORDER_FAILURE],
		endPoint: 'order',
		body: order,
		schema: Schemas.ORDER,
	},
});

export const submitOrder = (order) => (dispatch) => {
	return dispatch(orderSubmitter(order));
};

export const ORDER_HISTORY = 'ORDER_HISTORY';
export const ORDER_HISTORY_SUCCESS = 'ORDER_HISTORY_SUCCESS';
export const ORDER_HISTORY_FAILURE = 'ORDER_HISTORY_FAILURE';

const historyGetter = () => ({
	[CALL_API]: {
		httpAction: 'GET',
		types: [ORDER_HISTORY, ORDER_HISTORY_SUCCESS, ORDER_HISTORY_FAILURE],
		endPoint: 'order/history',
		schema: Schemas.ORDER_ARRAY,
	},
});

export const getOrderHistory = () => (dispatch) => {
	return dispatch(historyGetter());
};
