import { Schema, arrayOf, normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import qs from 'query-string';
import ReactGA from 'react-ga';
import { logException } from '../actions/apiUIHelperActions';
import { history } from '../index.js'


import { LOCAL_STORAGE_ACCESS_TOKEN_KEY, logout } from '../actions/oauthActions';

// Processed at compilation through .env/.env.development files
export const API_HOST = process.env.REACT_APP_API_URL;

export const API_ROOT = API_HOST + 'v1/';

export const getUserDocumentUrl = (document, userId) =>
    API_ROOT + `user/${document}/document/${userId}?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`;

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (httpAction, endpoint, schema, accessToken,
    body = {}, queryParamsMap: any = {}, file) => {
        if( endpoint !== 'menu') queryParamsMap.access_token = accessToken;
    const queryParamsString = qs.stringify(queryParamsMap);

    const fullUrl = ((endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint)
        + `?${queryParamsString}`;

    let fetchInit = {};

    if (httpAction !== 'GET') {
        const jsonBody = JSON.stringify(body);
        let formData;
        let headers;
        if (file) {
            formData = new FormData();
            formData.append('file', file);
            formData.append('meta-data', jsonBody);
            headers = new Headers();
        } else {
            headers = new Headers();
            headers.append('Content-Type', 'application/json')
        }
        fetchInit = {
            mode: 'cors',
            method: httpAction,
            headers,
            body: formData ? formData : jsonBody
        };
    }

    return fetch(fullUrl, fetchInit)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json)
                }

                return Object.assign({},
                    normalize(camelizeKeys(json), schema)
                )
            })
        )
};

const userSchema = new Schema('users', {
    idAttribute: user => user.id
});

const dspSchema = new Schema('deliveryServiceProviders', {
    idAttribute: deliveryServiceProvider => deliveryServiceProvider.id
});

const dspManagerSchema = new Schema('dspManagers', {
    idAttribute: dspManager => dspManager.id
});

const dsprSchema = new Schema('DSPRs', {
    idAttribute: dspr => dspr.id
});

const dsprManagerSchema = new Schema('dsprManagers', {
    idAttribute: dsprManager => dsprManager.id
});

const dsprDriverSchema = new Schema('dsprDrivers', {
    idAttribute: dsprDriver => dsprDriver.id
});

const dspProductSchema = new Schema('dspProducts', {
    idAttribute: dspProduct => dspProduct.id
});

const dsprDriverLocationSchema = new Schema('dsprDriverLocations', {
    idAttribute: location => location.id
});

const dsprDriverInventoryPeriodSchema = new Schema('dsprDriverInventoryPeriods', {
    idAttribute: inventoryPeriod => inventoryPeriod.id
});

const dsprZipCodeSchema = new Schema('dsprZipCodes', {
    idAttribute: dsprZipCode => dsprZipCode.id
});

const dsprDriverInventoryItemSchema = new Schema('dsprDriverInventoryItems', {
    idAttribute: item => item.id
});

const dsprProductInventoryTransactionSchema = new Schema('dsprProductInventoryTransactions', {
    idAttribute: transaction => transaction.id
});

const dsprCurrentInventoryItemSchema = new Schema('dsprCurrentInventoryItems', {
    idAttribute: item => item.id
});

const userIdDocumentSchema = new Schema('usersIdDocuments', {
    idAttribute: document => document.id
});

const userMedicalRecommendationSchema = new Schema('usersMedicalRecommendations', {
    idAttribute: document => document.id
});

const menuSchema = new Schema('menu', {
    idAttribute: menu => menu.id
});

const productCategorySchema = new Schema('productCategories', {
    idAttribute: productCategory => productCategory.id
});

const dspBrandSchema = new Schema('dspBrands', {
    idAttribute: dspBrand => dspBrand.id
} )

const orderSchema = new Schema('orders', {
    idAttribute: order => order.id
});

const addressSchema = new Schema('addresses', {
    idAttribute: address => address.id
});

const userSignatureSchema = new Schema('usersSignatures', {
    idAttribute: document => document.id
});

const productCategoryPromotionSchema = new Schema('productCategoryPromotions', {
    idAttribute: productCategoryPromotion => productCategoryPromotion.id
});

orderSchema.define({
    address: addressSchema,
    dsprDriver: dsprDriverSchema,
    dspr: dsprSchema,
    user: userSchema
});

menuSchema.define({
    driver: dsprDriverSchema,
    dspr: dsprSchema,
    productCategories: arrayOf(productCategorySchema),
    products: arrayOf(dspProductSchema),
    address: addressSchema,
    brands: arrayOf(dspBrandSchema)
});

userIdDocumentSchema.define({
    user: userSchema
});

userMedicalRecommendationSchema.define({
    user: userSchema
});

dsprCurrentInventoryItemSchema.define({
    dspr: dsprSchema,
    product: dspProductSchema
});

dsprProductInventoryTransactionSchema.define({
    product: dspProductSchema,
    dspr: dsprSchema,
    dsprManager: dsprManagerSchema
});

dsprDriverInventoryItemSchema.define({
    dspr: dsprSchema,
    driver: dsprDriverSchema,
    inventoryPeriod: dsprDriverInventoryPeriodSchema,
    product: dspProductSchema
});

dsprDriverInventoryPeriodSchema.define({
    dspr: dsprSchema,
    driver: dsprDriverSchema
});

dsprDriverLocationSchema.define({
    dspr: dsprSchema,
    dsprDriver: dsprDriverSchema
});

dspProductSchema.define({
    dsp: dspSchema,
    brand: dspBrandSchema
});

dsprDriverSchema.define({
    dspr: dsprSchema,
    currentLocation: dsprDriverLocationSchema,
    currentInventoryPeriod: dsprDriverInventoryPeriodSchema
});

dsprManagerSchema.define({
    dspr: dsprSchema,
    user: userSchema
});

dsprZipCodeSchema.define({
    dspr: dsprSchema,
});

dsprSchema.define({
    deliveryServiceProvider: dspSchema,
    managers: arrayOf(dsprManagerSchema),
    drivers: arrayOf(dsprDriverSchema),
    zipCodes: arrayOf(dsprZipCodeSchema),
});

dspManagerSchema.define({
    managerUser: userSchema,
    deliveryServiceProvider: dspSchema
});

dspSchema.define({
    managers: arrayOf(dspManagerSchema),
    products: arrayOf(dspProductSchema)
});

userSignatureSchema.define({
    user: userSchema
});

userSchema.define({
    deliveryServiceProviderManagers: arrayOf(dspManagerSchema),
    dsprManagers: arrayOf(dsprManagerSchema),
    identificationDocument: userIdDocumentSchema,
    medicalRecommendation: userMedicalRecommendationSchema,
    userSignature: userSignatureSchema,
    currentOrder: orderSchema
});

productCategoryPromotionSchema.define({
    dspr: dsprSchema,
    productCategory: productCategorySchema
})

// Schemas for Grassp API responses.
export const Schemas = {
    USER: userSchema,
    USER_ARRAY: arrayOf(userSchema),
    DELIVERY_SERVICE_PROVIDER: dspSchema,
    DSP_ARRAY: arrayOf(dspSchema),
    DSP_MANAGER: dspManagerSchema,
    DSP_MANAGER_ARRAY: arrayOf(dspManagerSchema),
    DSPR: dsprSchema,
    DSPR_ARRAY: arrayOf(dsprSchema),
    DSPR_MANAGER: dsprManagerSchema,
    DSPR_MANAGER_ARRAY: arrayOf(dsprManagerSchema),
    DSPR_DRIVER: dsprDriverSchema,
    DSPR_DRIVER_ARRAY: arrayOf(dsprDriverSchema),
    DSP_PRODUCT: dspProductSchema,
    DSP_PRODUCT_ARRAY: arrayOf(dspProductSchema),
    DSPR_DRIVER_LOCATION: dsprDriverLocationSchema,
    DSPR_DRIVER_LOCATION_ARRAY: arrayOf(dsprDriverLocationSchema),
    DSPR_DRIVER_INVENTORY_PERIOD: dsprDriverInventoryPeriodSchema,
    DSPR_ZIPCODE: dsprZipCodeSchema,
    DSPR_ZIPCODE_ARRAY: arrayOf(dsprZipCodeSchema),
    DSPR_DRIVER_INVENTORY_PERIOD_ARRAY: arrayOf(dsprDriverInventoryPeriodSchema),
    DSPR_DRIVER_INVENTORY_ITEM: dsprDriverInventoryItemSchema,
    DSPR_DRIVER_INVENTORY_ITEM_ARRAY: arrayOf(dsprDriverInventoryItemSchema),
    DSPR_PRODUCT_INVENTORY_TRANSACTION: dsprProductInventoryTransactionSchema,
    DSPR_PRODUCT_INVENTORY_TRANSACTION_ARRAY: arrayOf(dsprProductInventoryTransactionSchema),
    DSPR_CURRENT_INVENTORY_ITEM: dsprCurrentInventoryItemSchema,
    DSPR_CURRENT_INVENTORY_ITEM_ARRAY: arrayOf(dsprCurrentInventoryItemSchema),
    USER_ID_DOCUMENT: userIdDocumentSchema,
    USER_ID_DOCUMENT_ARRAY: arrayOf(userIdDocumentSchema),
    USER_MEDICAL_RECOMMENDATION: userMedicalRecommendationSchema,
    USER_MEDICAL_RECOMMENDATION_ARRAY: arrayOf(userMedicalRecommendationSchema),
    PRODUCT_CATEGORY_PROMOTION: productCategoryPromotionSchema,
    PRODUCT_CATEGORY_PROMOTION_ARRAY: arrayOf(productCategoryPromotionSchema),
    MENU: menuSchema,
    DSP_BRAND: dspBrandSchema,
    DSP_BRAND_ARRAY: arrayOf(dspBrandSchema),
    ORDER: orderSchema,
    ORDER_ARRAY: arrayOf(orderSchema),
    USER_SIGNATURE: userSignatureSchema,
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
    const callAPI = action[CALL_API];

    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    let { endPoint } = callAPI;
    const { schema, types, httpAction, body, queryParamsMap, file } = callAPI;

    if (typeof endPoint === 'function') {
        endPoint = endPoint(store.getState());
    }

    if (typeof endPoint !== 'string') {
        throw new Error('Specify a string endpoint URL.')
    }

    if (!schema) {
        throw new Error('Specify one of the exported Schemas.')
    }

    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.')
    }

    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.')
    }

    const accessToken = store.getState().api.accessToken;

    if ((!accessToken || typeof accessToken !== 'string') && endPoint !== 'menu') {
        console.error('No access token found in store');
        store.dispatch(logout());
        history.push("/menu");
    }

    const actionWith = data => {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_API];
        return finalAction
    };

    const [requestType, successType, failureType] = types;
    try {
        next(actionWith({ type: requestType }));
    }
    catch (error) {
        logException("Next Action Failed (but non critical?)", {
            error,
            action: requestType,
            callApi: callAPI,
            state: store.getState()
        })
    }

    return callApi(httpAction, endPoint, schema, accessToken, body, queryParamsMap, file).then(
        response => next(actionWith({
            response,
            type: successType
        })),
        error => {
            const errorMessage = error.message ? error.message : error.error;

            // Expected for multiple DSPRs at a location - no need to execute a Google Analytics command
            // Caution: exception type may change in the future
            if (error.exception && error.exception !== 'com.grassp.restservice.exception.MoreThanOneDSPROperatingInZipCodeException') {
                ReactGA.exception({
                    description: errorMessage
                });
            }

            if (error.status === 500) logException(errorMessage, {
                action: requestType,
                callApi: callAPI,
                state: store.getState()
            });
            if (error.status === 401 || error.error === 'invalid_token' || error.message.includes('Unexpected token < in JSON at position 0')) {
                store.dispatch(logout());
                history.push("/login");
            }
            else {
                return next(actionWith({
                    type: failureType,
                    error: error.message || 'Something went terribly wrong :( '
                }))
            }
        }
    );
}
