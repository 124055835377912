import 'react-app-polyfill/ie9';
import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { createBrowserHistory } from "history";
// import { syncHistoryWithStore } from 'react-router-redux';
import 'typeface-roboto';

import Root from './containers/Root'
import configureStore from './store/configureStore'
import * as serviceWorker from './serviceWorker';

import {
    setAccessToken, PRELOAD_ACCESS_TOKEN_FROM_LOCAL_STORAGE, SET_INITALIZATION_COMPLETE,
    LOCAL_STORAGE_ACCESS_TOKEN_KEY, LOCAL_STORAGE_ACCESS_TOKEN_TYPE
} from './actions/oauthActions'
import { updateLoggedInUserInfo } from './actions/userActions';
import { logException } from './actions/apiUIHelperActions';
import { LOCAL_STORAGE_ZIP_CODE_KEY, setMenuZipCode } from './actions/menuActions';
// import {FLUSHED_LOCAL_STORAGE} from './actions/oauthActions'
// import {loadState, saveState} from './localStorage';


//TODO: store the cart in localstorage and load it from there
// const store = configureStore(loadState());
const store = configureStore();

export const history = createBrowserHistory()



// store.subscribe(() => {
//    saveState(store.getState());
// });


// const flushedLocalStorage = localStorage.getItem(FLUSHED_LOCAL_STORAGE);
// if(!flushedLocalStorage){
//     localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
//     localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_TYPE);
//     localStorage.setItem(FLUSHED_LOCAL_STORAGE, true);
// }



try {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    const accessTokenType = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_TYPE);
    const zipCode = localStorage.getItem(LOCAL_STORAGE_ZIP_CODE_KEY);
    store.dispatch(setMenuZipCode(zipCode, true));
    if (accessToken) {
        store.dispatch(setAccessToken(PRELOAD_ACCESS_TOKEN_FROM_LOCAL_STORAGE, accessToken, accessTokenType));
        //NOTE: this will happen in the right order because setAccesToken doesn't do anything
        // asynchronously
        store.dispatch(updateLoggedInUserInfo()).then(response => {
            store.dispatch({ type: SET_INITALIZATION_COMPLETE})
        });
    } else {
        store.dispatch({ type: SET_INITALIZATION_COMPLETE})
    }
}
catch (err) {
    logException("Couldn't load accessToken from local storage", { err });
}



render(
    <Root store={store} history={history}/>,
    document.getElementById('root')
);

serviceWorker.register();
