import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { history } from '../index.js';
import { useSelector } from "react-redux";
import { getOrderForCost } from "../selectors/cartSelector";

const CartFooter: React.FC<{}> = (props) => {

    // loggedInUser: getLoggedInUser(state),
    // orderDetails: getCartOrderDetails(state),
    // address: getAddressFromMenu(state),
    // dsprDriver: getDriverMenu(state),
    // error: getError(state),
    // isRecreationalMarket: getIsRecreationalMarket(state),

    const order = useSelector(getOrderForCost);

    // eslint-disable-next-line
    const [showPlaceOrder, setShowPlaceOrder] = useState(true);

    const continueShopping = () => {
        history.push('/');
    }


    return (order ? <div className="cart-cta-container">
        <div>
            <p>Estimated Tax</p>
            <p>${order.orderTaxDetails.length !== 0 ? parseFloat(order.orderTaxDetails.slice(0).reverse().map(item => item.amount).reduce((prev, curr) => prev + curr)).toFixed(2) : 0}</p>
        </div>
        <div>
            <p>Total</p>
            <p>${parseFloat(order.cashTotal).toFixed(2)}</p>
        </div>
        <Button color="primary" variant="contained"
            type="submit"
            form="address-form"
            fullWidth={true}
            disabled={!showPlaceOrder}
            style={{ marginLeft: '0px', marginRight: '0px' }}
        >
            Place Order
        </Button>
        <Button color="secondary" variant="contained"
            onClick={continueShopping}
            fullWidth={true}
            style={{ marginLeft: '0px', marginRight: '0px' }}
        >
            Continue Shopping
        </Button>
    </div> : null)
};

export default CartFooter;