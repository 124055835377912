import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton, Typography, List, ListItem, ListItemText, Divider, Toolbar, Badge, TextField, Icon } from '@material-ui/core';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import { withStyles } from '@material-ui/core/styles';
import { Search, Close, ArrowBackSharp } from "@material-ui/icons";
import { withTheme } from '@material-ui/core/styles';

import { logout, getAppAccessToken } from '../actions/oauthActions';
import { getCartOrderDetails } from '../selectors/cartSelector';
import { isClosed, getProductCategoriesForCurrentMenu, getBrandsForCurrentMenu, getShowSubheader, getProductCateogryByIdFromProps, getBrandByIdFromProps, getMenuSearch } from '../selectors/menuSelector'
import { getDSPRs } from '../selectors/dsprSelector.js';
import { setMenuSearch, setShowSubheader } from '../actions/menuActions';

export const HEADER_TITLES = {
    default: 'Grassp',
    menu: 'Menu',
    product: 'Product',
    categories: 'Categories',
    category: 'Category',
    brands: 'Brands',
    chosenBrand: 'Chosen Brand',
    order: 'Orders',
    currentOrder: 'Current Order',
    dsprSelection: 'DSPR Selection',
    idDocument: 'ID Document',
    login: 'Login',
    signup: 'Sign Up',
    cart: 'Cart',
    profile: 'Profile',
    contact: 'Contact'
}

const styles = {
    appBar: {
        backgroundColor: "white",
        display: "contents",
    },
    menuShadow: {
        boxShadow: " 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    sideMenuButton: {
        marginLeft: -12,
        marginRight: 0,
    },
    cartButton: {
        color: "#3084f2 !important",
        marginLeft: 0,
        marginRight: -12,
        decoration: "none"
    },
    list: {
        width: 200,
    },
    fullList: {
        width: 'auto',
    },
    badge: {
        top: 12,
        right: 7,
        background: "black",
        backgroundSize: "50%",
        color: "white",
        width: '50%',
        height: '50%'
    },
    toolbar: {
        display: 'flex',
        width: '100%',
        background: "#FFFFFF",
        minHeight: 64
    },
    search: {
        display: "block",
        width: "100%",
    },
    searchClosed: {
        display: "none",
    },
    searchInput: {
        padding: 0,
        height: 36,
        width: "100%",
        backgroundColor: "white",
        borderRadius: 4
    },
    titleClosed: {
        display: "none",
    },
    titleOpen: {
        display: "block"
    },
    menuSubdirectoryContainer: {
        height: 52,
    },
    menuSubdirectoryFlexContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'flex-end',
        justifyContent: 'space-around',
        height: '100%',
    },
    navLink: {
        padding: '4px 0px 3px 0px',
        display: "flex",
        letterSpacing: "1.2px",
        height: "100%",
        justifyContent: "center" as const,
        alignItems: "center" as const,
        textAlign: 'center' as const,
        textDecoration: 'none',
        color: '#FFFFFF',
        fontWeight: 500,
        fontSize: '.875rem',
        width: 150,
        flexGrow: 1,
    },
    activeNavLink: {
        backgroundColor: '#0058bf' //theme.palette.main.darker. Cannot currently utilize the isActive prop with the style property in the NavLink component
    }
};

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const Header: React.FC<{ loggedInUser: any, classes: any, onDSPRSelection: boolean, theme: any, setOnDSPRSelection, headerRef: any }> = props => {
    // const [menuOpen, setMenuOpen] = useState(false);

    const { classes, loggedInUser, onDSPRSelection, theme, headerRef } = props;
    const location = useLocation<any>();
    const searchRef = useRef<HTMLInputElement>();
    const history = useHistory();
    const dispatch = useDispatch();

    const { brandId, categoryId, determineShowSearch } = useMemo(() => {
        if (location) {
            const pathArray = location.pathname.split('/');
            if (pathArray.length >= 4) {
                if (pathArray[2] === "categories") {
                    return {
                        brandId: undefined,
                        categoryId: pathArray[3],
                        determineShowSearch: true
                    }
                } else if (pathArray[2] === "brands") {
                    return {
                        brandId: pathArray[3],
                        categoryId: undefined,
                        determineShowSearch: true,
                    }
                }
            } else {
                if (pathArray.length === 3 && pathArray[2] === "products") return { determineShowSearch: true }
                return {}
            }
            return {}
        }
        return {}
    }, [location])

    const currCategory = useSelector((state) => getProductCateogryByIdFromProps(state, { categoryId }));
    const currBrand = useSelector((state) => getBrandByIdFromProps(state, { brandId }));

    const orderDetails = useSelector(getCartOrderDetails, shallowEqual);
    const menuClosed = useSelector(isClosed, shallowEqual);
    const dsprs = useSelector(getDSPRs, shallowEqual);

    const menuCategories = useSelector(getProductCategoriesForCurrentMenu, shallowEqual);
    const menuBrands = useSelector(getBrandsForCurrentMenu, shallowEqual);
    const showSubheader = useSelector(getShowSubheader, shallowEqual);



    const showProvidersOnButton = location.pathname === '/' && !onDSPRSelection && Object.keys(dsprs).length > 1;

    // const headerTitle = (location && location.state && location.state.headerTitle) || HEADER_TITLES.default;
    const isMenuPage = location && location.pathname && location.pathname.includes('menu');

    const headerTitle = useMemo(() => {
        if (currCategory || currBrand) {
            return currCategory?.name || currBrand?.name
        } else {
            return (location && location.state && location.state.headerTitle) || HEADER_TITLES.default;
        }
    }, [location, currCategory, currBrand])
    //const [showSubheader, setShowSubheader] = useState<boolean>(false);
    const [searchOpen, setSearchOpen] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleSearchChange = (event) => {
        dispatch(setMenuSearch(event.target.value));
    }

    const handleSearchKeyEvent = (event) => {
        if (event.key === "Enter") {
            setMenuSearch(event.target.value)
            if (searchOpen) setSearchOpen(false);
        }

    }

    const clearSearch = () => {
        dispatch(setMenuSearch(''))
    }

    const searchValue = useSelector(getMenuSearch);

    let cartAmt: number;
    if (orderDetails) {
        cartAmt = 0;
        orderDetails.forEach(detail => cartAmt += detail.quantity);
    }

    useEffect(() => {
        if (!menuClosed && isMenuPage && (menuBrands.length > 0 || menuCategories.length > 0)) {
            dispatch(setShowSubheader(true))
        }
        else {
            dispatch(setShowSubheader(false));
        }
    }, [menuClosed, isMenuPage, menuBrands, menuCategories, dispatch])

    useEffect(() => {
        if (searchOpen) setSearchOpen(false);
        //eslint-disable-next-line
    }, [location])

    /*Nav Menu*/

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearch = () => {
        if (searchOpen) clearSearch();
        if (!searchOpen) {
            setTimeout(() => searchRef.current.focus(), 50);
        }
        setSearchOpen(!searchOpen)
    }

    const handleNavClick = (event) => {
        clearSearch();
    }

    const determineNavBackClick = () => {
        if (location) {
            const currentPathArray = location.pathname.split('/');

            //TODO: use entrypoint to check if the current location is the same as the static entrypoint, and if it is then use this to go back to the brands or categories page
            if (brandId) {
                return () => history.push('/menu/brands')
            }
            if (categoryId) {
                return () => history.push('/menu/categories')
            }

            if ((currentPathArray[1] === "profile" && currentPathArray.length >= 3 && currentPathArray[2] === "documentStatus") || currentPathArray[1] === "product" || currentPathArray[1] === "cart") {
                return () => history.goBack();
            }
        }
        return () => history.goBack();
    }

    const determineHeaderIconButton = useMemo(() => {
        if (location) {
            const currentPathArray = location.pathname.split('/');
            if ((currentPathArray[1] === "profile" && currentPathArray.length >= 3 && currentPathArray[2] === "documentStatus") || currentPathArray[1] === "product" || currentPathArray[1] === "cart" || (brandId || categoryId)) {
                return (<IconButton onClick={determineNavBackClick()} className={classes.sideMenuButton} color="primary" aria-label="Back">
                    <ArrowBackSharp />
                </IconButton>)
            }
        }

        return (<IconButton onClick={handleMenu}
            className={classes.sideMenuButton} color={"primary"} aria-label="Menu">
            <MenuIcon />
        </IconButton>)

    }, [location, brandId, categoryId, classes, history])



    const open = Boolean(anchorEl);

    const menu =
        loggedInUser ?
            <List className={classes.list}>
                <ListItem button disabled={true}>
                    <ListItemText primary={`${loggedInUser.firstName} ${loggedInUser.lastName}`} />
                </ListItem>
                <Divider />
                <ListItemLink disabled={orderDetails.length === 0 || loggedInUser.currentOrder !== undefined} component={Link} to="/cart">
                    <ListItemText primary="Cart" />
                </ListItemLink>
                <Divider />
                <ListItemLink component={Link} to={{
                    pathname: showProvidersOnButton ? '/showAll' : '/menu'
                }}>
                    <ListItemText primary={showProvidersOnButton ? "Show Providers" : "Menu"} />
                </ListItemLink>

                {/* just playing */}
                {/* <ListItemLink
                    to="/"
                    onClick={() => {if (someProp) props.setOnDSPRSelection()}}
                >
                    <ListItemText primary={"some_prop_passed_by_App" ? "Show Providers" : "Menu"} />
                </ListItemLink> */}

                <Divider />
                <ListItemLink component={Link} to='/orders'>
                    <ListItemText primary="Orders" />
                </ListItemLink>
                <Divider />
                <ListItemLink component={Link} to="/profile">
                    <ListItemText primary="Profile" />
                </ListItemLink>
                <Divider />
                <ListItemLink component={Link} to="/refer-a-friend">
                    <ListItemText primary="Refer a Friend" />
                </ListItemLink>
                <Divider />
                <ListItemLink component={Link} to="/hours">
                    <ListItemText primary="Delivery Hours" />
                </ListItemLink>
                <Divider />
                <ListItemLink component={Link} to="/contact-us">
                    <ListItemText primary="Contact Us" />
                </ListItemLink>
                <Divider />
                <ListItem button onClick={() => {
                    dispatch(logout());
                    dispatch(getAppAccessToken());
                    history.push('/menu');
                }}>
                    <ListItemText primary="Log Out" />
                </ListItem>
                <Divider />
                <ListItem button onClick={() => {
                    window.location.reload();
                }}>Refresh App</ListItem>
                <Divider />
            </List>
            :
            <List className={classes.list}>
                <ListItemLink component={Link} to="/menu">
                    <ListItemText primary="Menu" />
                </ListItemLink>
                <ListItemLink component={Link} to="/login">
                    <ListItemText primary="Log In" />
                </ListItemLink>
                <Divider />
                <ListItemLink component={Link} to="/signup">
                    <ListItemText primary="Sign Up" />
                </ListItemLink>
                <Divider />
                <ListItemLink component={Link} to="/contact-us">
                    <ListItemText primary="Contact Us" />
                </ListItemLink>
                <Divider />
                <ListItem button onClick={() => {
                    window.location.reload();
                    handleClose();
                }}>Refresh App</ListItem>
                <Divider />
            </List>;

    const renderMenuSubdirectory = () => {

        return (
            <nav className={classes.menuSubdirectoryContainer}>
                {/*div container can be used for responsive styling (e.g. set max width)*/}
                <div className={classes.menuSubdirectoryFlexContainer} style={{ backgroundColor: theme.palette.primary.main }}>
                    <NavLink
                        to={'/menu/products'}
                        onClick={handleNavClick}
                        className={classes.navLink}
                        activeClassName={classes.activeNavLink}
                    >
                        Products
                    </NavLink>
                    {menuCategories.length > 0 && <NavLink
                        to={'/menu/categories'}
                        onClick={handleNavClick}
                        className={classes.navLink}
                        activeClassName={classes.activeNavLink}
                    >
                        Categories
                    </NavLink>}
                    {menuBrands.length > 0 && <NavLink
                        to={'/menu/brands'}
                        onClick={handleNavClick}
                        className={classes.navLink}
                        activeClassName={classes.activeNavLink}
                    >
                        Brands
                    </NavLink>}
                </div>
            </nav>
        )
    }

    return <AppBar position="sticky" className={classes.appBar + " app-bar"} elevation={0}>
        {/*<AppBar position="fixed" className={classes.appBar} color="primary" elevation={0}>*/}
        <div className={(showSubheader ? "" : " " + classes.menuShadow)} style={{ position: "fixed", width: "100%", zIndex: 10 }} ref={headerRef}>
            <Toolbar className={classes.toolbar}>
                {determineHeaderIconButton}


                <div style={{ marginLeft: 'auto', marginRight: 'auto', background: "#FFFFFF" }} className={searchOpen ? classes.titleClosed : classes.titleOpen}>
                    {headerTitle === HEADER_TITLES.default && <Typography variant="h1" color="inherit" className={classes.grow} style={{ margin: 'auto', width: 'auto' }}>
                        <p className="h1 logo" style={{ padding: '0', margin: '0', position: 'absolute', whiteSpace: "nowrap", overflow: "hidden", left: "56px", right: "56px", fontFamily: "reklame-script", userSelect: "none", fontWeight: 600 }}>
                            <img src='/images/GrasspLogo.png' alt="Grassp" style={{ height: '30px', top: '6px', position: 'relative', marginRight: '1px' }} />
                            rassp<em className={"header-extension"} style={{ color: '#67B02E', fontSize: "100%", fontStyle: "italicize" }}>Health</em>
                        </p>
                    </Typography>}

                    {headerTitle !== HEADER_TITLES.default && <Typography className={classes.grow} style={{ margin: 'auto', width: 'auto', fontSize: '1.75rem', fontWeight: 600, fontFamily: "reklame-script" }} color={"secondary"}>
                        {headerTitle}
                    </Typography>}
                </div>
                <div className={searchOpen ? classes.search : classes.searchClosed}>
                    <TextField inputRef={searchRef} placeholder='search product' classes={{ root: classes.searchRoot }} className={searchOpen ? classes.search : classes.searchClosed} variant="outlined" InputProps={{
                        className: classes.searchInput
                    }} onChange={(event) => handleSearchChange(event)} onKeyPress={(keyEvent) => handleSearchKeyEvent(keyEvent)} value={searchValue}></TextField>

                </div>

                {!determineShowSearch && cartAmt === 0 && <Icon style={{ width: "32px", height: "32px", padding: "12px", marginRight: "-12px" }}></Icon>}
                {determineShowSearch && !searchOpen && <Typography variant='body1' align='right' color={'primary'}>{searchValue && `'${searchValue}'`}</Typography>}
                {determineShowSearch && <IconButton color="primary" aria-label="Menu" className={cartAmt > 0 ? '' : classes.cartButton} onClick={() => handleSearch()}>
                    {searchOpen ? <Close /> : <Search />}
                </IconButton>}

                {/*Cart*/}
                {cartAmt > 0 ?
                    (<IconButton
                        style={{ fontSize: '1rem' }}
                        className={classes.cartButton} color="primary" aria-label="Cart" onClick={() => history.push("/cart")}>
                        <Badge badgeContent={cartAmt} color="primary" classes={{ badge: classes.badge }}>
                            <ShoppingBasket />
                        </Badge>
                    </IconButton>) : ' '
                }
            </Toolbar>

            {showSubheader &&
                renderMenuSubdirectory()
            }
            <Drawer open={open} onClose={handleClose}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={handleClose}
                    onKeyDown={handleClose}
                >
                    {menu}
                </div>
            </Drawer>
        </div>

    </AppBar>


}

export default withTheme()(withStyles(styles)(Header))
