export const REMOVE_ORDER_DETAIL_FROM_CART = 'REMOVE_ORDER_DETAIL_FROM_CART';
export const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

export const FLOWER_UNITS = {
    oz: {name: 'oz', label: '1'},
    half: {name: 'half', label: '1/2'},
    quarter: {name: 'quarter', label: '1/4'},
    eighth: {name: 'eighth', label: '1/8'}
};

export const ADD_ORDER_DETAIL_TO_CART = 'ADD_ORDER_DETAIL_TO_CART';

const cartAdder = (product, unit, quantity) => {
    let orderDetail = {
        product,
        quantity
    };

    if (unit) {
        orderDetail.unit = unit;
    }

    return {
        type: ADD_ORDER_DETAIL_TO_CART,
        orderDetail
    };
};

export const addProductToCart = (product, unit=undefined, quantity = 1) => (dispatch, getState) => {
        return dispatch(cartAdder(product, unit, quantity));
};

const cartRemover = (product) => {
    return {
        type: REMOVE_ORDER_DETAIL_FROM_CART,
        productId: product.id
    }
};

export const removeProductFromCart = (product) => (dispatch, getState) => {
    return dispatch(cartRemover(product));
};

export const CLEAR_CART = 'CLEAR_CART';
const cartClearer = () => {
    return {
        type: CLEAR_CART
    }
};

export const clearCart = () => (dispatch, getState) => {
    return dispatch(cartClearer());
};