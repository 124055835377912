import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Card, CardHeader, CardContent} from '@material-ui/core';

import {validateEmail} from '../actions/userActions';
import {clearErrorMessage} from '../actions/apiUIHelperActions';

import {getError} from '../selectors/errorSelector';

class EmailValidator extends Component {
    constructor(props){
        super(props);
        this.state = {error: null}
    }

    componentDidMount = () => {
        const {token} = this.props.match.params;
        this.props.validateEmail(token)
            .then(() => {
            if(this.props.error) {
                    this.setState({error: this.props.error});
                    this.props.clearErrorMessage();
                }
            });
    };

    componentWillReceiveProps = (newProps) => {
        if(newProps.error) {
            this.setState({error: newProps.error});
            this.props.clearErrorMessage();
        }
    };

    render(){
        const title = this.state.error ? "Something Went Wrong" : "Email Confirmed";
        return <Card>
            <CardHeader title={title} />
            <CardContent>
                {this.state.error &&
                <div>
                    {this.state.error}
                </div>
                }
                {!this.state.error &&
                <div>
                    Thank you for confirming your email!
                </div>
                }
            </CardContent>
        </Card>
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        validateEmail,
        clearErrorMessage
    }, dispatch);
};

const mapStateToProps = (state, ownProps) => {
    return {
        error: getError(state)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailValidator);