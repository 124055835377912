import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, withStyles, Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { getFileUploadStatusFromDocumentForm } from '../selectors/documentUploadFormSelector';
import { ExpandLess } from '@material-ui/icons';

const FILE_FIELD_NAME = 'imageFile';

const styles = {
    documentUploadForm: {
        backgroundColor: '#EFEFEF',
        height: '100%',
        cursor: 'pointer',
    },
    documentUploadDropZone: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}

const renderDropzoneInput = (field, btnText, classes) => {
    const files = field.input.value;
    const preview = files ? files.length > 0 ? <img src={files[0].preview} alt="Preview" width="auto" height="100%" /> : <span>The maximum file size is 10MB. Please choose a smaller file.</span> : undefined;

    return (<Fragment>
            <Dropzone
                name={field.label}
                onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
                multiple={false}
                className={`drop-zone${files ? ' with-file' : ''}`}
                maxSize={10485760}
                maxFiles={1}
            >
                <Fragment>
                    {files ? preview :
                        <div className={classes.documentUploadDropZone} style={{flexDirection: 'column'}}>
                            <ExpandLess fontSize={'large'} />
                            <Typography style={{fontSize: '1rem'}}>
                                {btnText || 'Upload Photo'}
                            </Typography>
                        </div>
                    }
                </Fragment>
            </Dropzone>
            {field.meta.touched &&
                field.meta.error &&
                <span className="error">{field.meta.error}</span>}
        </Fragment>
    );
};

const validate = (values) => {
    const errors = {};
    const requiredFields = [FILE_FIELD_NAME];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'An image is required.'
        }
    });
    return errors
};

//FIXME: figure out how to properly incorporate UserDocumentUploadFormProps
//interface UserDocumentUploadFormProps extends Partial<InjectedFormProps> {
//    handleSubmit: (any) => any;
//    onCancel: (any) => any;
//    submitLabel: string;
//    showBackButton?: boolean;
//    showSubmitButton?: boolean;
//    hasFile: boolean;
//    classes: any;
//    buttonLabel: string;
//    formId?: any;
//}

//let UserDocumentUploadForm = (props: UserDocumentUploadFormProps & InjectedFormProps<{}, UserDocumentUploadFormProps>) => {

const UserDocumentUploadForm = (props) => {

    const {
        handleSubmit,
        onCancel,
        submitLabel = 'Submit',
        showBackButton,
        buttonLabel,
        hasFile,
        classes,
    } = props;
    return (
            <form onSubmit={handleSubmit} className={classes.documentUploadForm} style={{display: 'flex', flexDirection: 'column',}}>
                <Field name={FILE_FIELD_NAME} component={f => renderDropzoneInput(f, buttonLabel, classes)} type="dropzone" label="Upload Image" />
                {hasFile && <Button color="primary" variant="contained"
                                    type="submit"
                                    fullWidth={false}
                                    onTouchStart={(event) => event.stopPropagation()}
                                    onMouseDown={(event) => event.stopPropagation()}
                                    style={{marginLeft: 'auto', marginRight: 'auto', width: 150}}
                >
                    {submitLabel}
                </Button>}
                {showBackButton && <Button color="secondary" variant="contained" onClick={onCancel}>Back</Button>}
            </form>
    )
};

//FIXME: figure out how to properly incorporate UserDocumentUploadFormProps
//function mapStateToProps(state, props: UserDocumentUploadFormProps) {
//    return {
//        form: props.formId || 'UserDocumentUploadForm',
//        hasFile: getFileUploadStatusFromDocumentForm(state, props.formId),
//    }
//}

//export default connect(mapStateToProps)(withStyles(styles)(reduxForm<any, UserDocumentUploadFormProps>({
//    // form: 'UserDocumentUploadForm', // a unique identifier for this form
//    validate
//})(UserDocumentUploadForm)));

function mapStateToProps(state, props) {
    return {
        form: props.formId || 'UserDocumentUploadForm',
        hasFile: getFileUploadStatusFromDocumentForm(state,  props.formId || 'UserDocumentUploadForm'),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(reduxForm({
    // form: 'UserDocumentUploadForm', // a unique identifier for this form
    validate
})(UserDocumentUploadForm)));


