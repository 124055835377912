import React from 'react';
import { useParams } from "react-router";
import { shallowEqual, useSelector } from "react-redux";
import { getProductFromProps } from "../selectors/menuSelector";
import { API_HOST } from "../middleware/api";
import { Redirect } from "react-router-dom";
import { Paper, Typography, withStyles } from "@material-ui/core";
import ProductStatsChart from "./ProductStatsChart";


const styles = {
    pageContainer: {
        padding: 16,
        backgroundColor: '#FFF',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
}



const ProductPageMain = ({ classes }) => {
    const { productId } = useParams<{ productId: string }>();
    const product = useSelector(state => getProductFromProps(state, productId), shallowEqual);



    if (!product) {
        console.log("Product not found")
        return (
            <Redirect to={{ pathname: '/menu' }} />
        )
    }

    return (
        <>
            <Paper className={classes.pageContainer} style={{ flexDirection: 'column', }}>
                {/*<div style={{width: '100vw', height: 300, border: '1px solid black'}}>*/}
                <img
                    //className={'product-image'}
                    style={{ width: '100%', maxWidth: 400, height: 'auto', alignSelf: 'center' }}
                    alt={`${product.name} :${product.description}`}
                    src={`${API_HOST}${product.imageLocation}`}
                />
                {/*</div>*/}

                <Typography style={{ fontSize: '1.5rem', marginTop: 8 }}>{product.name}</Typography>

                <div style={{ width: '100%' }}>
                    <ProductStatsChart product={product} />
                </div>

                <Typography style={{ fontSize: '1.25rem', marginTop: 8 }} gutterBottom={true}>Description</Typography>
                <Typography>{product.description}</Typography>

                {/*Extra text used to test if footer hides content*/}
                {/*<Typography>---------- Test --------------</Typography>*/}
                {/*<Typography>{product.description}</Typography>*/}
                {/*<Typography>---------- Test --------------</Typography>*/}
                {/*<Typography>{product.description}</Typography>*/}
            </Paper>
        </>
    )
}

export default (withStyles(styles)(ProductPageMain));