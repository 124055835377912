import React from 'react';
import { Typography, withStyles } from "@material-ui/core";

const styles = {
    barGraph: {
        marginTop: 12,
    },
    rowContainer: {
        display: 'flex',
        width: '100%',
        marginTop: 8,
    },
    bar: {
        flexGrow: 1,
        backgroundColor: '#EAEAEA',
        height: 16,
        borderRadius: 6,
        '@media (max-width: 250px)' : {
            flexGrow: 0,
        }
    },
    barValue: {
        height: 16,
        zIndex: 4,
        borderRadius: 6,
    },
    titleContainer: {
        width: '10%',
        maxWidth: 30,
        marginRight: 10,
    },
    statsContainer: {
        minWidth: 80,
        marginLeft: 20,
        '@media (max-width: 250px)' : {
            marginLeft: 10,
        }
    }
}

const ProductStatsChart = ({product, classes}) => {

    const renderGraphRow = ({title, percentage, mg, backgroundColor}) => {
        return (
            <div className={classes.rowContainer} style={{alignItems: 'center'}} key={title}>
                <div className={classes.titleContainer}>
                    <Typography>{title}:</Typography>
                </div>
                <div className={classes.bar} style={{position: 'relative'}}>
                    <span
                        className={classes.barValue}
                        style={{position: 'absolute', top: 0, width: `${percentage}%`, backgroundColor}}
                    />
                </div>
                <div className={classes.statsContainer}>
                    <Typography>
                        {percentage}% {`${mg ? `/ ${mg} mg` : ''}`}
                    </Typography>
                </div>
            </div>
        )
    }

    const renderBarGraph = () => {
        const graph = [];

        if (product.thcPercentage) {
            const {thcPercentage: percentage, thcMg: mg,} = product;
            graph.push(renderGraphRow({
                title: 'THC', percentage, mg, backgroundColor: '#BBDFA0'
            }));
        }

        if (product.cbdPercentage) {
            const {cbdPercentage: percentage, cbdMg: mg,} = product;
            graph.push(renderGraphRow({
                title: 'CBD', percentage, mg, backgroundColor: '#CB9DE5'
            }));
        }

        if (product.cbnPercentage) {
            const {cbnPercentage: percentage, cbnMg: mg,} = product;
            graph.push(renderGraphRow({
                title: 'CBN', percentage, mg, backgroundColor: '#3EC6F4'
            }));
        }
        return graph;
    }

    return (
        <div className={classes.barGraph}>
            {renderBarGraph()}
        </div>
    )
}

export default (withStyles(styles)(ProductStatsChart));