import { createMuiTheme, CssBaseline, MuiThemeProvider } from "@material-ui/core"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router";
import ContactUs from "../components/ContactUs";
import CurrentOrder from "../components/CurrentOrder";
import DeliveryHours from "../components/DeliveryHours";
import FlexHeader, { HEADER_TITLES } from "../components/FlexHeader";
import LegacyUserRegistration from "../components/LegacyUserRegistration";
import Notification from "../components/Notification";
import ProductPageMain from "../components/ProductPageMain";
import { getIsRecreationalMarket, getShowSubheader } from "../selectors/menuSelector";
import { getDriverFromOrder, getOrderFromPropsWithAddress } from "../selectors/orderSelectors";
import { getLoggedInUser, getUserAccessTokenFromProps, getInitializationComplete } from "../selectors/userSelectors";
import Cart from "./Cart";
import ChangePasswordPage from "./ChangePasswordPage";
import EmailValidator from "./EmailValidator";
import FooterConductor from "./FooterConductor";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import MenuContainer from "./MenuContainer";
import Orders from "./Orders";
import ReferAFriend from "./ReferAFriend";
import ResetPassword from "./ResetPassword";
import SignUp from "./SignUp";
import UserDocumentStatusPage from "./UserDocumentStatusPage";
import UserProfile from "./UserProfile";
import { cancelOrder, LOG_AEROPAY_TRANSACTION_SUCCESS, logAeroPayTransaction, setAeroPayReferenceUUIDForOrder } from "../actions/orderActions"
import { getLastAppRefresh, setLastAppRefresh } from "../actions/menuActions";
import { EntryPathContext } from "../context/entryPath";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        margin: "5px",
        padding: "10px"
      }
    }
  },
  palette: {
    primary: {
      main: "#3084F2",
      dark: '#0058bf',
      light: '#75b3ff',
      contrastText: "#fff"
    },
    secondary: {
      main: "#77C03E",
      dark: "#448f00",
      light: '#aaf36f',
      contrastText: "#fff"
    },
    text: {
      secondary: "rgba(0, 0, 0, 0.6)",
    },
    error: {
      main: "#ff0000",
      light: "#ef5350",
      dark: '#c62828',
      contrastText: "#fff"
    }
  },
  typography: {
    useNextVariants: true
  }
})

type AppProps = {
  children?: any,
}

const App: React.FC<AppProps> = (props) => {

  const { children } = props;
  const location = useLocation<{ state: any, headerTitle: string }>();
  const headerRef = useRef(undefined);
  const pageRef = useRef(undefined);
  const dispatch = useDispatch();
  const orderCanceler = (order) => {
    dispatch(cancelOrder(order));
  }

  const { entryPath, setEntryPath } = useContext(EntryPathContext)

  const logAeroPay = (orderId, uuid, total) => {
    // dispatch(logAeroPayTransaction(orderId, uuid, total));
    dispatch({ type: LOG_AEROPAY_TRANSACTION_SUCCESS, response: { entities: { orders: { [orderId]: { aeroPayPaymentStatus: "completed" } } } } })
  }

  const setAeroPayReferenceUUID = (uuid, orderId) => {
    dispatch(setAeroPayReferenceUUIDForOrder(uuid, orderId));
  }

  const loggedInUser = useSelector(getLoggedInUser);
  const initializationComplete = useSelector(getInitializationComplete);
  const accessToken = useSelector(getUserAccessTokenFromProps);
  const currentOrder = useSelector((state) => loggedInUser ? getOrderFromPropsWithAddress(state, { orderId: loggedInUser.currentOrder }) : undefined);
  const driver = useSelector((state) => currentOrder ? getDriverFromOrder(state, { orderId: currentOrder.id }) : undefined)
  const isRecreationalMarket = useSelector(getIsRecreationalMarket);
  const showSubheader = useSelector(getShowSubheader, shallowEqual);

  const [hasSentTokenToWebview, setHasSentTokenToWebview] = useState(false);
  // eslint-disable-next-line
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const [onDSPRSelection, setOnDSPRSelection] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const headerHeight = useMemo(() => {
    if (headerRef && headerRef.current) {
      return headerRef?.current?.clientHeight;
    }

    return 64;
  }, [headerRef])


  useEffect(() => {
    if (loggedInUser && accessToken && !hasSentTokenToWebview) {
      // @ts-ignore - This is used because ReactNativeWebView isn't always there
      window?.ReactNativeWebView?.postMessage(accessToken);
      setHasSentTokenToWebview(true);
    }
  }, [loggedInUser, accessToken, hasSentTokenToWebview])


  const renderMenuOrderOrSignUp = useMemo(() => {
    if (children) {
      return children;
    }
    if (loggedInUser) {
      if (currentOrder === undefined || currentOrder.orderStatus === "canceled") {
        return <Redirect to={{ pathname: '/menu', state: { ...location.state, headerTitle: HEADER_TITLES.menu } }} />
      } else {
        location.state = { ...location.state, headerTitle: HEADER_TITLES.order }
        return (
          <Redirect to={{ pathname: '/currentOrder' }} />
        )
      }
    } else {
      //If initialization is complete, and the entrypath does not contain a signup path, password reset path, or email validation path, redirect to the menu
      if (initializationComplete) {
        if (!entryPath || (entryPath && !entryPath.includes("/signup") && !entryPath.includes("/reset-password") && !entryPath.includes("/validate-email"))) {
          return <Redirect to={{ pathname: '/menu', state: { ...location.state, headerTitle: HEADER_TITLES.menu } }} />
        } else {
          return <Redirect to={{ pathname: entryPath, state: { ...location.state, headerTitle: HEADER_TITLES.default } }} />
        }
      }
    }
  }, [location, initializationComplete, children, loggedInUser, currentOrder, entryPath])

  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (headerRef && headerRef.current) {
      console.log("Setting height: ", headerRef.current)
      if (showSubheader)
        setHeight(headerRef.current.clientHeight);
      else {
        setTimeout(() => {
          setHeight(headerRef.current.clientHeight)
        }, 10)
      }
    }
  }, [headerRef, headerHeight, location, showSubheader])

  return (
    <div>
      {
        isLoading === false &&
        <MuiThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />
            <FlexHeader loggedInUser={loggedInUser}
              headerRef={headerRef}
              onDSPRSelection={onDSPRSelection}
              setOnDSPRSelection={setOnDSPRSelection}
            />
            {/*<div style={{flex: '1 1 auto', position: 'relative'}}>*/}
            <div style={{ paddingBottom: 56, paddingTop: height, display: "flex", flexBasis: 0, flexDirection: 'column', overflow: "auto" }} ref={pageRef}>
              <Switch
                ref={(signupCont) => {
                  pageRef.current = signupCont;
                }}
              >
                <Route
                  exact
                  path="/"
                  render={({ location }) =>
                    renderMenuOrderOrSignUp
                  }
                />
                <Redirect
                  from="/showAll"
                  to={{
                    pathname: '/',
                    state: {
                      showAllDsprs: true,
                    }
                  }}
                />
                <Redirect
                  from="/showMenu"
                  to={{
                    pathname: '/',
                    state: {
                      showAllDsprs: false,
                      fromSidebarNavigation: true,
                    }
                  }}
                />
                <Route path={'/product/:productId'}
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.product }
                    return <ProductPageMain />
                  }}
                />
                <Route path="/cart"
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.cart }
                    return <Cart />
                  }}
                />
                <Route path="/orders"
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.order }
                    return <Orders />
                  }}
                />
                <Route path="/login"
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.login }
                    return <Login />
                  }}
                />
                <Route exact path="/signup/:code"
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.signup }
                    return <SignUp />
                  }}
                />
                <Route path="/signup"
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.signup }
                    return <SignUp />
                  }}
                />
                <Route path="/legacy" component={LegacyUserRegistration} />
                <Route exact path='/profile/documentStatus/:documentType'
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.profile }
                    return <UserDocumentStatusPage />
                  }}
                />
                <Route path="/profile"
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.profile }
                    return <UserProfile />
                  }}
                />
                <Route path="/currentOrder"
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.currentOrder }
                    return <CurrentOrder
                      isRecreationalMarket={isRecreationalMarket}
                      order={currentOrder}
                      orderCanceler={orderCanceler}
                      setAeroPayReferenceUUID={setAeroPayReferenceUUID}
                      logAeroPay={logAeroPay}
                      driver={driver}
                      loggedInUser={loggedInUser}
                    />
                  }}
                />
                <Route path="/change-password" component={ChangePasswordPage} />
                <Route path="/validate-email/:token" component={EmailValidator} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password/:token" component={ResetPassword} />
                <Route path="/contact-us"
                  render={() => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.contact }
                    return <ContactUs />
                  }}
                />
                <Route path="/refer-a-friend" component={ReferAFriend} />
                <Route path="/hours" component={DeliveryHours} />
                <Route path={['/menu', '/menu/:section', '/menu/:section/:value']}
                  exact={true}
                  render={({ location }: any) => {
                    location.state = { ...location.state, headerTitle: HEADER_TITLES.default }
                    return <MenuContainer setOnDSPRSelection={setOnDSPRSelection} getLastAppRefresh={getLastAppRefresh} setLastAppRefresh={setLastAppRefresh} initializationComplete={initializationComplete} />
                  }}
                />

                <Route
                  render={() =>
                    renderMenuOrderOrSignUp
                  }
                />
              </Switch>
            </div>

            {/*</div>*/}
            {showInstallMessage && <Notification message={<div>Get the
              app!<br />Tap <img src={"https://grassp.it/wp-content/uploads/2019/02/iconos30.png"}
                alt="share-icon"
                style={{ height: 20, position: "relative", top: 4 }} /> then "Add to Home Screen"
            </div>} variant="download" />}
            {/*<div style={{position: 'absolute', bottom: 0, left: 0, height: 'fit-content', flexShrink: 0}}>*/}
            <FooterConductor />
          </React.Fragment>
        </MuiThemeProvider>
      }
    </div>
  );
}

export default App;
