import React, { useContext, useState } from 'react';
import { Button, Typography, withStyles, MenuItem, Select } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { addProductToCart, FLOWER_UNITS, removeProductFromCart } from "../actions/cartActions";
import { getOrderDetailForProductInCart } from "../selectors/cartSelector";
import { getCurrentQuantityAvailableForProduct, getProductFromProps } from "../selectors/menuSelector";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import { matchPath, useHistory } from "react-router";
import { getLoggedInUserId } from "../selectors/userSelectors";
import { allDocumentsVerified } from "../selectors/userDocumentsSelector";
import { LOCAL_STORAGE_ENTRYPOINT_HISTORY_PUSH } from '../actions/menuActions';
import { EntryPathContext } from '../context/entryPath';



const styles = {
    footerContentContainer: {
        marginLeft: 10,
        marginRight: 10,
    },
    buttonContainerHeadingText: {
        marginTop: 3,
        color: 'dimgray',
    },
    flowerButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 3,
    },
    flowerButtonWrapper: {
        display: "flex",
        flexGrow: 1,
        padding: "0 8px"
    },
    flowerButton: {
        backgroundColor: '#3084f2'
    },
    selectedFlowerButton: {
        backgroundColor: 'rgba(48, 132, 242, 0.38)'
    },
    baseFlowerButtonStyles: {
        display: "flex",
        flexGrow: 1,
        margin: 0,
        marginBottom: 10,
        width: "25%",
        borderRadius: 8,
    },
    buttonSubText: {
        color: '#FFFFFF',
        marginTop: 4,
        marginBottom: 4,
        fontSize: '.75rem'
    },
    nonFlowerProductFooter: {
        display: 'flex',
        flexDirection: 'row' as const,
        alignItems: 'center' as const
    },
    select: {
        backgroundColor: 'white',
        display: 'flex',
        flexGrow: 1,
        borderRadius: 6,
        paddingLeft: 10,
        '&:focused': {
            backgroundColor: 'white',
            borderRadius: 6,
        },
    },

}

const ProductFooter = ({ classes }) => {
    const { pathname } = useLocation()
    //empty object avoids typeError if productId property is undefined
    const { params: { productId } = {} } = matchPath<any>(pathname, { path: "/product/:productId" })

    const dispatch = useDispatch();

    let history = useHistory();

    const { authRedirectEntryPoint } = useContext(EntryPathContext);

    const loggedInUserId = useSelector(getLoggedInUserId, shallowEqual);
    const areAllDocumentsVerified = useSelector(state => allDocumentsVerified(state, loggedInUserId), shallowEqual);
    const product = useSelector(state => getProductFromProps(state, productId), shallowEqual);
    const orderDetailInCartForProduct = useSelector(state => product && getOrderDetailForProductInCart(state, product.id), shallowEqual);
    const currentQuantityAvailableForProduct = useSelector(state => product && getCurrentQuantityAvailableForProduct(state, product.id), shallowEqual);

    const itemUnitInCart = orderDetailInCartForProduct ? orderDetailInCartForProduct.unit : '';

    const [selectedNonFlowerQuantity, setSelectedNonFlowerQuantity] = useState<number>(1);



    const handleLoginToAddToCart = () => {
        // set a local storage variable to redirect to this location hash after login
        localStorage.setItem(LOCAL_STORAGE_ENTRYPOINT_HISTORY_PUSH, authRedirectEntryPoint);
        history.push("/signup");
    };
    // const modifySnackBar = (quantity, unit) => {
    //     let message = `${quantity}${unit ? ' ' + unit : ''} now in your cart.`;
    //     message = quantity === currentQuantityAvailableForProduct ?
    //         message + ' You got the last one!' : message;

    //     setSnackbarOpen(true);
    //     setSnackbarMessage(message);
    // };

    const handleAddProductToCart = (product, unit, quantity = 1) => {
        dispatch(addProductToCart(product, unit, quantity));

        let gaLabel = product.name + 'x' + quantity;
        if (unit) gaLabel = gaLabel + ' ' + unit;
        ReactGA.event({
            category: 'Product',
            action: 'Product Added to Cart',
            value: product.id,
            label: gaLabel
        });

        // modifySnackBar(quantity, unit);
    };

    const handleRemoveProductFromCart = (product) => {
        dispatch(removeProductFromCart(product));
        ReactGA.event({
            category: 'Product',
            action: 'Product Removed From Cart',
            value: product.id,
            label: product.name
        });
    }


    /*FLOWER PRODUCT SPECIFIC CODE*/

    const handleClickFlowerButton = (product, unit) => {
        // flower unit is already in cart - user now wants to remove it
        if (unit && unit === itemUnitInCart) {
            handleRemoveProductFromCart(product);
            return
        }

        handleAddProductToCart(product, unit);
    }

    const createFlowerUnitButton = (unit) => {

        if (!product.currentPrice[`${unit.name}Price`]) return null;
        const price = product.currentPrice[`${unit.name}Price`];
        const displayPrice = Number.isInteger(price) ?
            price :
            parseFloat(price).toFixed(2);

        let amountLabel;
        switch (unit.label) {
            case '1/8':
            case '1/4':
                amountLabel = `${unit.label}th`;
                break;
            default:
                amountLabel = `${unit.label} oz.`
        }

        return (
            <div style={{ height: 'fit-content' }} key={unit.name} className={classes.flowerButtonWrapper}>
                <Button
                    //style={{minWidth: '25px', paddingLeft: '12px', paddingRight: '12px'}}
                    className={(unit.name === itemUnitInCart ? classes.selectedFlowerButton : classes.flowerButton) + " " + classes.baseFlowerButtonStyles}
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickFlowerButton(product, unit.name)}
                    size={'small'}
                    style={{ textTransform: 'lowercase' }}
                    disabled={!areAllDocumentsVerified}
                >
                    <div>
                        <Typography style={{ color: 'white' }}>{amountLabel}</Typography>
                        <Typography style={{ fontSize: '.75rem', color: "white" }}>{`$${displayPrice}`}</Typography>
                    </div>
                </Button>
                {/*<Typography className={classes.buttonSubText}>{`$${displayPrice}`}</Typography>*/}
            </div>
        )
    }

    const renderFlowerButtons = () => {
        const { currentQuantityAvailable } = product;
        const buttons = [];

        if (currentQuantityAvailable > 7) {
            buttons.push(createFlowerUnitButton(FLOWER_UNITS.oz));
        }
        if (currentQuantityAvailable > 3) {
            buttons.push(createFlowerUnitButton(FLOWER_UNITS.half));
        }
        if (currentQuantityAvailable > 1) {
            buttons.push(createFlowerUnitButton(FLOWER_UNITS.quarter));
            buttons.push(createFlowerUnitButton(FLOWER_UNITS.eighth));
        } else if (currentQuantityAvailable === 1) {
            buttons.push(createFlowerUnitButton(FLOWER_UNITS.eighth));
        }
        return buttons.reverse();
    }

    const renderFlowerFooterContent = () => {
        return (
            <div className={classes.footerContentContainer}>
                {loggedInUserId && <Typography className={classes.buttonContainerHeadingText}>Choose Amount:</Typography>}
                <div className={classes.flowerButtonsContainer}>
                    {loggedInUserId ? renderFlowerButtons() : <Button variant={'contained'} color={"primary"} fullWidth={true} style={{ marginBottom: "8px" }} onClick={() => handleLoginToAddToCart()}>Sign Up to Add to Cart</Button>}
                </div>
            </div>
        )
    }

    /*NON-FLOWER PRODUCT SPECIFIC CODE*/

    const renderNonFlowerSelectMenuItems = () => {
        const menuItems = [];

        for (let i = 1; i <= currentQuantityAvailableForProduct; i++) {
            menuItems.push(<MenuItem value={i} key={i}>{i}</MenuItem>)
        }

        return menuItems;
    }

    const handleNonFlowerSubmit = () => {
        if (orderDetailInCartForProduct && selectedNonFlowerQuantity === orderDetailInCartForProduct.quantity) {
            handleRemoveProductFromCart(product)
            return;
        }

        handleAddProductToCart(product, undefined, selectedNonFlowerQuantity);
    }

    const determineNonFlowerButtonText = () => {
        if (orderDetailInCartForProduct && selectedNonFlowerQuantity === orderDetailInCartForProduct.quantity) {
            return 'Remove from Cart'
        }

        if (orderDetailInCartForProduct && orderDetailInCartForProduct.quantity !== selectedNonFlowerQuantity) {
            return 'Update Cart'
        }

        return 'Add to Cart'
    }

    const renderNonFlowerFooterContent = () => {
        return (
            <div className={classes.footerContentContainer}>
                <div>
                    <Typography className={classes.buttonContainerHeadingText}>
                        Quantity Available: {currentQuantityAvailableForProduct}
                        <span style={{ marginLeft: 10 }}>
                            Currently in Cart: {orderDetailInCartForProduct ? orderDetailInCartForProduct.quantity : '0'}
                        </span>
                    </Typography>
                </div>
                <div className={classes.nonFlowerProductFooter}>
                    <Select
                        value={selectedNonFlowerQuantity}
                        onChange={(evt) => setSelectedNonFlowerQuantity(parseInt(evt.target.value))}
                        variant={'outlined'}
                        disableUnderline={false}
                        style={{ height: 30 }}
                        className={classes.select}
                        disabled={!areAllDocumentsVerified || !loggedInUserId}
                    >
                        {renderNonFlowerSelectMenuItems()}
                    </Select>
                    {!loggedInUserId ? <Button variant={'text'} style={{ marginLeft: 10 }} color={"primary"} onClick={() => handleLoginToAddToCart()} >
                        Sign Up To Add To Cart
                    </Button> :
                        <Button variant={'text'} style={{ marginLeft: 10 }} color={"primary"} onClick={handleNonFlowerSubmit} disabled={!areAllDocumentsVerified}>
                            {determineNonFlowerButtonText()}
                        </Button>}
                </div>



            </div>
        )
    }


    /*GENERAL CODE*/

    const renderFooterContent = () => {
        if (product) {
            return product.isFlower
                ? renderFlowerFooterContent()
                : renderNonFlowerFooterContent()
        }
    }

    return (
        <footer className={'footer-container'} style={{ height: 'fit-content' }}>
            {renderFooterContent()}
        </footer>

    )
}

export default (withStyles(styles)(ProductFooter));