import React, { useMemo } from 'react';
import { Button, Checkbox, Dialog, Radio, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Typography, withStyles, RadioGroup } from "@material-ui/core";
import './FullMenuModalStyles.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getMenuFilters, getBrandsForCurrentMenu, getMenuSorting, getProductCategoriesForCurrentMenu } from '../selectors/menuSelector';
import { setMenuFilter, setSortMethod, setSortParameter } from '../actions/menuActions';
import { useLocation } from 'react-router';


const styles = {
    header: {
        width: "100%",
        marginLeft: 12,
        marginRight: 12,
        flexGrow:1,
        fontWeight: "normal" as const
    },
    halfWidth: {
        width: "50%",
        textAlign: "center" as const
    },
    filterCheckboxGridContainer: {
        display: "grid",
        width: "100%",
        paddingLeft: "4%",
        paddingRight: "4%",
        gridAutoColumns: "minmax(225px, 300px)"
    }
}

const sortParameters = {
    "byFlowerType":"By Flower Type",
    "currentPrice": "Price",
    "thcPercentage": "THC Percentage",
    "cbdPercentage": "CBD Percentage",
    "cbnPercentage": "CBN Percentage",
    "thcMg": "THC Mg",
    "cbdMg": "CBD Mg",
}

const sortType = {
    "descending": "High To Low",
    "ascending": "Low To High"
   
}
interface FilterSelectionModalProps {
    isVisible: boolean;
    closeModal: () => void;
    clearFilters: () => void;
}

const FilterSelectionModal = ({ isVisible, closeModal, clearFilters }: FilterSelectionModalProps) => {

    const location = useLocation();
    const brands = useSelector(getBrandsForCurrentMenu, shallowEqual);
    const categories = useSelector(getProductCategoriesForCurrentMenu, shallowEqual);
    const filters = useSelector(getMenuFilters, shallowEqual);
    const getSortMethod = useSelector(getMenuSorting, shallowEqual)

    const { brandId, categoryId } = useMemo(()=> {
        if(location){
            const pathArray = location.pathname.split('/');
            if(pathArray.length >= 4) {
                if(pathArray[2] === "categories") {
                    return {
                        brandId: undefined,
                        categoryId: pathArray[3]                    }
                } else if(pathArray[2] === "brands"){
                    return {
                        brandId: pathArray[3],
                        categoryId: undefined
                    }
                }
            } else {
                return {}
            }
            return {}
        }
        return {}
    },[location])

    const dispatch = useDispatch();
    const handleSelection = (filterType, filterItem) => {
        dispatch(setMenuFilter(filterType, filterItem));
    }
    const handleSelectChange = (event) => {
       dispatch(setSortParameter(event.target.value));
    }
    const handleSortTypeChange = (event) => {
        dispatch(setSortMethod(event.target.value))
    }

    return(
        <Dialog open={isVisible} onClose={closeModal} className={'full-menu-modal-container'}>
            <DialogTitle className={'full-menu-modal-title'}>Filter Selection</DialogTitle>
            <DialogContent className={'full-menu-modal-content-container'}>
                {!brandId &&<span>
                    {brands && brands.length > 0 && <Typography variant="h6" style={styles.header}>Brands</Typography>}
                    {brands && brands.length > 0 && <div style={styles.filterCheckboxGridContainer}>
                        {brands.map((brand) => <FormControlLabel key={brand.name} label={brand.name} checked={(!!filters['brands'] && filters['brands'].indexOf(brand.name) !== -1)} onChange={() => handleSelection('brands', brand.name)} control={<Checkbox />}/>)}
                </div>}
                </span>}
                {!categoryId && <span>
                    {categories && categories.length > 0 && <Typography variant="h6" style={styles.header}>Categories</Typography>}
                    {categories && categories.length > 0 && <div style={styles.filterCheckboxGridContainer}>
                        {categories.map((category) => <FormControlLabel checked={(!!filters['categories'] && filters['categories'].indexOf(category.name) !== -1)} onChange={() => handleSelection('categories', category.name)} key={category.name} label={category.name} control={<Checkbox />}/>)}
                    </div>}
                </span>}
                
                <Typography variant="h6" style={styles.header}>Sorting</Typography>
                <div className={"filters-sorting-section"}>
                    {sortParameters &&  <FormControl className={"sort-parameter"}>
                        <InputLabel id="select-label">Sorting Method</InputLabel>
                        <Select onChange={(value)=> handleSelectChange(value)} value={getSortMethod.sortParameter || ''}>
                            <MenuItem key="menu-filter-default" value=""><i>No Sorting</i></MenuItem>
                            {Object.keys(sortParameters).map(value => (
                                <MenuItem key={value+"-select"} value={value}>{sortParameters[value]}</MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    }
                    {sortType && 
                            <RadioGroup value={getSortMethod.sortMethod} className={'sort-direction'} onChange={handleSortTypeChange}>
                                {Object.keys(sortType).map(value => 
                                    <FormControlLabel key={value+"-radio"} style={{margin: "auto"}} value={value} label={sortType[value]} control={<Radio />}/>
                                )}
                                
                            </RadioGroup>
                    }
                </div>

                
                {/* {categories && categories.length > 0 && categories.map((category) => <FormControlLabel checked={(!!filters['categories'] && filters['categories'].indexOf(category.name) != -1)} onChange={() => handleSelection('categories', category.name)} key={category.name} label={category.name} control={<Checkbox />}/>)} */}
            </DialogContent>
            <DialogActions style={{display: "flex", flexDirection: "column"}}>
                <Button onClick={clearFilters} variant={'contained'} color={'secondary'} style={{width: "100%", marginBottom: "8px"}}>Clear Filters</Button>
                <Button onClick={closeModal} variant={'contained'} color={'primary'} style={{width: "100%"}}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(FilterSelectionModal);