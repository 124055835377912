import React from 'react';

import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const validate = values => {
    const errors = {};
    if (!values.newPassword) {
        errors.newPassword = 'Required';
    }
    if (!values.newPasswordConfirm) {
        errors.newPasswordConfirm = 'Required';
    }
    if (values.newPassword !== values.newPasswordConfirm) {
        errors.newPasswordConfirm = 'Password doesn\'t match.';
    }

    return errors
};

const renderField = ({ input, label, type, meta: { asyncValidating, touched, error } }) => (
    <div>
        <TextField label={label} hint={label} type={type} {...input}
                   error={touched && error ? true : false}
                   helperText={touched && error ? error : ''}
                   margin="normal"
                   style={{width:250}}/>
    </div>
);

const ResetPasswordForm = (props) => {
    const { handleSubmit,
        // submitting
    } = props;
    return (
        <form onSubmit={handleSubmit}>
            <Field name="newPassword" type="password" component={renderField} label="New Password"/>
            <Field name="newPasswordConfirm" type="password" component={renderField} label="Confirm New Password"/>
            <Button color="primary" variant="contained" label="Change Password" onClick={handleSubmit}>Change Password</Button>
        </form>
    )
};

export default reduxForm({
    form: 'ResetPasswordForm', // a unique identifier for this form
    validate
})(ResetPasswordForm)
