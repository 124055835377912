import React, { useState, useEffect, Fragment, useRef, useContext } from 'react';
import { geolocated, GeolocatedProps } from 'react-geolocated';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { Dialog, Button } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, KeyboardArrowDown, MyLocation as LocationIcon } from '@material-ui/icons';
import MapIcon from '@material-ui/icons/Map';

import ReactGA from 'react-ga';
import { clearCart } from '../actions/cartActions';
import {
    updateLoggedInUserInfo, getUserMedicalRecommendation, getUserIdDocument, getAllDSPRServicingZipCode,
    GET_ALL_DSPR_SERVICING_ZIP_CODE_SUCCESS
} from '../actions/userActions';
import {
    getMenuByLatLong,
    getMenuByDefaultAddress,
    getMenuByZipCode,
    getMostRecentDSPR,
    setMostRecentDSPR,
    getLastAppRefresh,
    setLastAppRefresh,
    GET_MENU_BY_LATLONG_FAILURE,
    GET_MENU_BY_DEFAULT_ADDRESS_FAILURE,
    GET_MENU_BY_ZIPCODE_FAILURE,
    NO_SERVICE_ERROR_STRING,
    getProductCategoryPromotionsForDSPR,
    setIsFullMenuShownToUser, hideFullMenuDeliveryModalFromUser, setCurrentMenuType,
    clearFiltersForMenu,
    setMenuSearch,
    setMenuZipCode,
    GOOGLE_ZIPCODE_ERROR,
    getDefaultZipCode,
    LOCAL_STORAGE_ENTRYPOINT_HISTORY_PUSH
} from '../actions/menuActions';
import {
    getIsRecreationalMarket,
    getAllProductsOnMenu,
    getProductCategoriesWithPromotionsForDSPR,
    isFullMenuShownToUser, hasFullMenuModalBeenShownToUser, getMenu, getMenuFilters, getMenuSorting, getMenuSearch, getAllProductsOnMenuMap, getMenuCategories, getMenuProducts, getMenuBrands,
    getMenuZipCode
} from '../selectors/menuSelector';

import {
    getUserSignatureFromState, getUserMedicalRecommendationFromState, getUserIdDocumentFromState
} from '../selectors/userDocumentsSelector';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCartOrderDetails } from '../selectors/cartSelector';
import {
    getDsprImageLocationWithId,
    getDsprFullNameWithId,
    getDsprNameFromState,
    isFullMenuAvailableForDSPRFromProps
} from '../selectors/dsprSelector';

import { parseDate, parseDateEndOfDay } from '../util/util';
import { history } from '../index.js';
import { API_HOST } from '../middleware/api';

import MenuSwitcher from '../components/MenuSwitcherComponent';
import Menu from '../components/MenuComponent';
import LoadingSpinner from '../components/LoadingSpinner';
import Notification from '../components/Notification';
import SetLocationMenu from '../components/SetLocationMenu';
import { MENU_LOCATION_TYPES } from '../components/SetLocationMenu';
import { useBoolean } from "../util/hooks";
import DelayedDeliveryFullMenuModal from "../components/DelayedDeliveryFullMenuModal";
import FilterSelectionModal from "../components/FiltersSelectionModal";
import MenuDirectory from "../components/MenuDirectory";
import { getOrderFromPropsWithAddress } from '../selectors/orderSelectors';
import FiltersMenu from '../components/SetFiltersMenu';
import { EntryPathContext } from '../context/entryPath';
import ZipCodeForm from '../components/Forms/ZipCodeForm';
import { set } from 'react-ga';

const MENU_REFRESH_PERIOD = 300000;
const DEFAULT_AWAY_MESSAGE = 'Grassp is currently closed. Our operating hours are from 9am-9pm in AZ and 10am-9pm in CA. Please come back then!';
const STATE_INSPECTION_CLOSED_MESSAGE = "Grassp is currently closed for a state inspection. Please check back later to see if the inspection has been completed. We hope to see you again soon!"
const MULTIPLE_DSPR_FOR_ZIP_CODE_ERROR = "More than one DSPR is operating in zip code "; // trailing space is intentional
const NO_DSPR_FOR_ZIP_CODE_ERROR = "No DSPR is operating in zip code "; // trailing space is intentional

export interface MenuParams {
    section: 'products' | 'categories' | 'brands',
    value: string //(an id)
}

interface MenuContainerProps {
    setOnDSPRSelection: (value: boolean) => void;
    getLastAppRefresh: () => string;
    setLastAppRefresh: () => void;
    initializationComplete: boolean;
}

const MenuContainer: React.FC<GeolocatedProps & MenuContainerProps> = props => {
    const { coords, isGeolocationEnabled, setOnDSPRSelection, initializationComplete } = props;
    const [geoLocationPollCount, setGeoLocationPollCount] = useState(0);
    const [loadingLocation, setLoadingLocation] = useState(false);
    const [showCannotLocate, setShowCannotLocate] = useState(false);
    const [showPleaseEnableLocation, setShowPleaseEnableLocation] = useState(false);
    const [menuType, setMenuType] = useState(null);
    const [awayMessage, setAwayMessage] = useState<string>(null);
    const [hasReceivedMenu, setHasReceivedMenu] = useState(false);
    // eslint-disable-next-line
    const [showSomethingWentWrong, setShowSomethingWentWrong] = useState(false); // TODO: investigate the correct use of this
    // const [showRecreational, setShowRecreational] = useState(false);
    const [error, setError] = useState<string>(null);
    const [dsprs, setDsprs] = useState<any[]>(null);
    const [dsprId, setDsprId] = useState<number>(null);
    const [showingMostRecentDspr, setShowingMostRecentDspr] = useState(false);
    const [hasSeenDsprModal, setHasSeenDsprModal] = useState(false);
    //const [showWaitTimeModal, setShowWaitTimeMOdal] = useState(false);
    const [isFullMenuModalVisible, showFullMenuModal, hideFullMenuModal] = useBoolean(false);
    const [filtersModalVisible, showFiltersModal, hideFiltersModal] = useBoolean(false);
    const [showZipCodeModal, setShowZipCodeModal] = useState(false);

    const timeout = useRef<NodeJS.Timeout>(null);

    const location = useLocation<any>();
    const locationState = (location && location.state) || {};

    const { section, value } = useParams<MenuParams>();

    const loggedInUser = useSelector(getLoggedInUser, shallowEqual);
    const currentOrder = useSelector(state => getOrderFromPropsWithAddress(state, { orderId: loggedInUser?.currentOrder }))
    const medicalRecommendation = useSelector(getUserMedicalRecommendationFromState, shallowEqual);
    const idDocument = useSelector(getUserIdDocumentFromState, shallowEqual);
    const isRecreationalMarket = useSelector(getIsRecreationalMarket, shallowEqual);
    const userSignature = useSelector(getUserSignatureFromState, shallowEqual);
    const cart = useSelector(getCartOrderDetails, shallowEqual);
    const getDsprImageLocation = useSelector(getDsprImageLocationWithId, shallowEqual);
    const getDsprFullName = useSelector(getDsprFullNameWithId, shallowEqual);
    const getDsprName = useSelector(getDsprNameFromState, shallowEqual);
    const isFullMenuAvailable = useSelector(state => dsprId && isFullMenuAvailableForDSPRFromProps(state, { dsprId }), shallowEqual);
    const isFullMenuShown = useSelector(isFullMenuShownToUser, shallowEqual);
    const hasUserSeenFullMenuModal = useSelector(hasFullMenuModalBeenShownToUser, shallowEqual);
    const currentMenu = useSelector(getMenu, shallowEqual);
    const menuFilters = useSelector(getMenuFilters, shallowEqual);
    const menuSorting = useSelector(getMenuSorting, shallowEqual);
    const menuSearch = useSelector(getMenuSearch, shallowEqual);
    const currentZipCode = useSelector(getMenuZipCode, shallowEqual);

    // if (!loggedInUser) {
    //     //TODO figure out why we also need to redirect in the function startMenuPolling
    //     history.push('/');
    // }

    if (currentOrder && !(currentOrder.orderStatus === "canceled")) {
        history.push("/")
    }

    //Get local storage entry path
    const redirectToEntrypoint = localStorage.getItem(LOCAL_STORAGE_ENTRYPOINT_HISTORY_PUSH);
    if (redirectToEntrypoint && redirectToEntrypoint !== location.pathname && redirectToEntrypoint !== 'undefined') {
        localStorage.removeItem(LOCAL_STORAGE_ENTRYPOINT_HISTORY_PUSH);
        setTimeout(() => {
            history.push(redirectToEntrypoint);
        }, 150)

    }

    if (location.pathname === '/menu' || location.pathname === '/menu/') {
        history.replace('/menu/products', location.state);
    }

    const dispatch = useDispatch();

    const { entryPath, setEntryPath } = useContext(EntryPathContext)



    // For menu
    const productCategoriesWithPromotions = useSelector(state => getProductCategoriesWithPromotionsForDSPR(state, { dsprId: dsprId }))
    const products = useSelector(getAllProductsOnMenu, shallowEqual);
    // const productsMap = useSelector(getAllProductsOnMenuMap, shallowEqual);
    const menuCategories = useSelector(getMenuCategories, shallowEqual);
    const menuProducts = useSelector(getMenuProducts, shallowEqual);
    const menuBrands = useSelector(getMenuBrands, shallowEqual);
    const initialCategoryId = productCategoriesWithPromotions && productCategoriesWithPromotions.length > 0 && productCategoriesWithPromotions.sort((a, b) => a.order - b.order)[0].id;

    useEffect(() => {
        if (dsprId && loggedInUser && productCategoriesWithPromotions === undefined) {
            dispatch(getProductCategoryPromotionsForDSPR(dsprId))
        }
    }, [dsprId, loggedInUser, productCategoriesWithPromotions, dispatch])

    const refreshApp = () => {
        dispatch<any>(setLastAppRefresh())
        window.setTimeout(() => window.location.reload(), 150)
    }

    const clearMenuSearch = () => {
        dispatch(setMenuSearch(''));
    }

    const handleChangeForFullMenuCheckbox = () => {
        setHasReceivedMenu(false);
        dispatch(setIsFullMenuShownToUser(!isFullMenuShown));
    }

    const closeFullMenuModal = () => {
        dispatch(hideFullMenuDeliveryModalFromUser());
        hideFullMenuModal();
    }

    const clearMenuFilters = () => {
        dispatch(clearFiltersForMenu())
    }

    const handleSubmitZipCodeForm = (values) => {
        if (menuType !== MENU_LOCATION_TYPES.DELIVERY_ADDRESS) {
            setMenuType(MENU_LOCATION_TYPES.DEFAULT_MENU);
            dispatch(setCurrentMenuType(MENU_LOCATION_TYPES.DEFAULT_MENU));
        }
        dispatch(setMenuZipCode(values.zipCode));
        setShowZipCodeModal(false);
    }

    const getAwayMessageFromResponse = (response): string => {
        const showAwayMessage = response.response &&
            response.response.entities &&
            response.response.entities.menu &&
            response.response.entities.menu[0] &&
            response.response.entities.menu[0].isClosed;

        const returnZipCode = response?.response?.entities?.menu[0]?.address?.zipCode || loggedInUser?.defaultAddress?.zipCode || loggedInUser?.signupZipCode || currentZipCode;
        dispatch(setMenuZipCode(returnZipCode))

        const dsprAwayMessage = response.response.entities.menu[0].dsprAwayMessage;
        return showAwayMessage && ((dsprAwayMessage && dsprAwayMessage.message) || DEFAULT_AWAY_MESSAGE);
    }

    const checkForClosedMessageAndRenderMenu = (response, dsprId: number) => {
        console.log("checkForClosedMessageAndRenderMenu: ", response, dsprId)
        const lastClientRefresh = getLastAppRefresh();
        const awayMessage = getAwayMessageFromResponse(response);

        const latestRefreshTimeFromServer = response.response &&
            response.response.entities &&
            response.response.entities.menu &&
            response.response.entities.menu[0] &&
            response.response.entities.menu[0].refreshAppTime;

        if (response) {
            if (!response.error) {
                console.log("Away Message: ", awayMessage, currentZipCode)
                if (error) setError(null); // clear component state errors because the server returned none
                if (awayMessage) {
                    if (currentZipCode && currentZipCode.charAt(0) === "8") setAwayMessage(STATE_INSPECTION_CLOSED_MESSAGE);
                    else setAwayMessage(awayMessage);
                } else {
                    //TODO: confirm that this is no longer necessary
                    //if (lastClientRefresh === null) {
                    //console.log('@@@@@@@@@@@@ about to refresh the app. Response:', response);
                    //refreshApp();
                    //} else if (latestRefreshTimeFromServer) {
                    if (latestRefreshTimeFromServer) {
                        const latestRefreshTimeFromServerinDateFormat = parseDate(latestRefreshTimeFromServer)
                        if ((parseDate(lastClientRefresh) < latestRefreshTimeFromServerinDateFormat) && (new Date() >= latestRefreshTimeFromServerinDateFormat)) {
                            refreshApp();
                        }
                    }
                }
            } else {
                setError(response.error);
            }
        }

        setDsprId(dsprId);
        if (loggedInUser) dispatch(getProductCategoryPromotionsForDSPR(dsprId));
        dispatch(setMostRecentDSPR(dsprId));
        setOnDSPRSelection(false);
        if (isFullMenuShown) showFullMenuModal();

        setHasReceivedMenu(true);
    }

    const pollMenuWithDsprId = (dsprId: number) => {
        if (!menuType) return;

        if (getMostRecentDSPR() !== null && parseInt(getMostRecentDSPR()) !== dsprId && cart.length > 0) {
            const confirmation = window.confirm("Changing Providers will clear the current items in your cart. Do you wish to continue?");
            if (!confirmation) return;
            dispatch(clearCart());
        }

        setOnDSPRSelection(false)
        setHasReceivedMenu(false);

        switch (menuType) {
            case MENU_LOCATION_TYPES.CURRENT_LOCATION:
                dispatch<any>(getMenuByLatLong(coords.latitude, coords.longitude, dsprId, isFullMenuShown))
                    .then(response => checkForClosedMessageAndRenderMenu(response, dsprId));
                break;
            case MENU_LOCATION_TYPES.DELIVERY_ADDRESS:
                const defaultAddress = loggedInUser.defaultAddress;
                dispatch<any>(getMenuByDefaultAddress(defaultAddress.zipCode, defaultAddress.street, dsprId, isFullMenuShown))
                    .then(response => {
                        checkForClosedMessageAndRenderMenu(response, dsprId)
                    });
                break;
            case MENU_LOCATION_TYPES.DEFAULT_MENU:
                if (loggedInUser.signupZipCode) {
                    dispatch<any>(getMenuByZipCode(loggedInUser.signupZipCode, dsprId, isFullMenuShown))
                        .then(response => checkForClosedMessageAndRenderMenu(response, dsprId));
                } else {
                    setShowPleaseEnableLocation(true);
                }
                break;
            default:
                break;
        }
    }

    const processMenuResult = async (response) => {
        if (response && response.type &&
            [GET_MENU_BY_LATLONG_FAILURE, GET_MENU_BY_DEFAULT_ADDRESS_FAILURE, GET_MENU_BY_ZIPCODE_FAILURE].includes(response.type)) {
            const err = response.error && response.error.split("'");
            if (err.length === 3 && err[0] === MULTIPLE_DSPR_FOR_ZIP_CODE_ERROR && err[1].length === 5) {
                // Multiple DSPRs are servicing the current location/zip code/address
                const zipCode = err[1];
                dispatch(setMenuZipCode(zipCode));
                const { response: allDsprResponse, type } = await dispatch<any>(getAllDSPRServicingZipCode(zipCode));

                if (type === GET_ALL_DSPR_SERVICING_ZIP_CODE_SUCCESS) {
                    const mostRecentDspr = getMostRecentDSPR(); // Get most recent DSPR ID from LocalStorage
                    const { showAllDsprs } = locationState;
                    if (!showAllDsprs && Object.keys(allDsprResponse.entities.DSPRs).includes(mostRecentDspr)) {
                        if (!showingMostRecentDspr) setShowingMostRecentDspr(true);
                        setOnDSPRSelection(false);
                        pollMenuWithDsprId(parseInt(mostRecentDspr));
                    } else {
                        if (showingMostRecentDspr) setShowingMostRecentDspr(false);
                        const dsprs = Object.values(allDsprResponse.entities.DSPRs).sort().map((dspr: any) => {
                            const dspForDspr = allDsprResponse.entities.deliveryServiceProviders && allDsprResponse.entities.deliveryServiceProviders[dspr.deliveryServiceProvider];
                            return {
                                ...dspr,
                                fullName: (dspForDspr ? dspForDspr.name + ' ' : '') + dspr.name,
                            }
                        });
                        setDsprId(null)
                        if (isFullMenuShown) showFullMenuModal();
                        setHasReceivedMenu(true);
                        setOnDSPRSelection(true);
                        setDsprs(dsprs);
                        // Flush the location state field so it does not persist on URL-based navigation
                    }
                }
            } else if (err.length === 3 && err[0] === NO_DSPR_FOR_ZIP_CODE_ERROR && err[1].length === 5) {
                if (isFullMenuShown) showFullMenuModal();
                setHasReceivedMenu(true)
                setLoadingLocation(false);
                setOnDSPRSelection(false);
                setError(response.error);
            } else {
                if (isFullMenuShown) showFullMenuModal();
                setHasReceivedMenu(true)
                setLoadingLocation(false);
                setOnDSPRSelection(false);
                setError(response.error);
            }
        } else {
            const dsprId = parseInt(response.response.entities.menu[0].dspr);
            const menuZip = response.response.entities.menu[0].zipCode;
            if (menuZip) dispatch(setMenuZipCode(menuZip));
            checkForClosedMessageAndRenderMenu(response, dsprId);
            setOnDSPRSelection(false);
        }

        const resultProducts = response ? response.response ? response.response.entities ? response.response.entities.dspProducts : null : null : null;
        const resultCategories = response ? response.response ? response.response.entities ? response.response.entities.productCategories : null : null : null;
        const resultBrands = response ? response.response ? response.response.entities ? response.response.entities.dspBrands : null : null : null;

        if (entryPath && (entryPath.includes("/product/") || entryPath.includes("/categories/") || entryPath.includes("/brands/"))) {
            const appURLEntryArray = entryPath.split("/");
            if (appURLEntryArray.length >= 3) {
                switch (appURLEntryArray[1]) {
                    case "product":
                        if (resultProducts) {
                            if (Object.keys(resultProducts).includes(appURLEntryArray[2])) {
                                const path = entryPath;
                                setEntryPath(undefined);
                                history.push(path);
                            } else {
                                alert("We're sorry, that product isn't available at your current location");
                            }
                        }
                        break;
                    case "menu":
                        switch (appURLEntryArray[2]) {
                            case "categories":
                                if (resultCategories) {
                                    if (Object.keys(resultCategories).includes(appURLEntryArray[3])) {
                                        const path = entryPath;
                                        setEntryPath(undefined);
                                        history.push(path);
                                    } else {
                                        alert("We're sorry, this Category isn't available at your current location");
                                    }
                                }
                                break;
                            case "brands":
                                if (resultBrands) {
                                    if (Object.keys(resultBrands).includes(appURLEntryArray[3])) {
                                        const path = entryPath;
                                        setEntryPath(undefined);
                                        history.push(path);
                                    } else {
                                        alert("We're sorry, this Brand isn't available at your current location");
                                    }
                                }
                                break;
                        }
                    default:
                        return;
                }
            }
        }
    }

    const startMenuPolling = (dsprId?: number) => {
        // if (loggedInUser === undefined) {
        //     return <Redirect to={'/'} />
        // }

        if (loggedInUser) {
            if (medicalRecommendation && !medicalRecommendation.verified) dispatch(getUserMedicalRecommendation());
            if (idDocument && !idDocument.verified) dispatch(getUserIdDocument());
        } // TODO: redirect login if loggedInUser is undefined? maybe the root is already handling it though

        const defaultAddress = loggedInUser ? loggedInUser.defaultAddress : null;

        if (!defaultAddress && !currentZipCode && initializationComplete) {
            // If the user doesn't have a default address, open up the modal to set a zip code
            const localStorageZipCode = getDefaultZipCode();

            if (localStorageZipCode) {
                dispatch(setMenuZipCode(localStorageZipCode));
            } else {
                setShowZipCodeModal(true);
            }
        }
        switch (menuType) {
            case MENU_LOCATION_TYPES.CURRENT_LOCATION:
                if (!coords) {
                    startGeolocationPoll();
                } else {
                    dispatch<any>(getMenuByLatLong(coords.latitude, coords.longitude, dsprId, isFullMenuShown))
                        .then(response => processMenuResult(response));
                }
                break;
            case MENU_LOCATION_TYPES.DELIVERY_ADDRESS:
                dispatch<any>(getMenuByDefaultAddress(defaultAddress.zipCode, defaultAddress.street, dsprId, isFullMenuShown))
                    .then(response => processMenuResult(response));
                break;
            case MENU_LOCATION_TYPES.DEFAULT_MENU:
                if (loggedInUser && loggedInUser.signupZipCode) {
                    dispatch<any>(getMenuByZipCode(loggedInUser.signupZipCode, dsprId, isFullMenuShown))
                        .then(response => processMenuResult(response));
                } else if (currentZipCode) {
                    dispatch<any>(getMenuByZipCode(currentZipCode, null, isFullMenuShown))
                        .then(response => processMenuResult(response));
                } else if (loggedInUser) {
                    setShowPleaseEnableLocation(true);
                }
                break;

            default:
                break;
        }
        timeout.current = setTimeout(() => startMenuPolling(dsprId), MENU_REFRESH_PERIOD);
    };

    const startGeolocationPoll = () => {
        const startupGeolocationPollCount = locationState.fromSidebarNavigation ? 2 : 20;
        if (!coords) {
            if (geoLocationPollCount < startupGeolocationPollCount) {
                setGeoLocationPollCount(currentValue => currentValue + 1);
                timeout.current = setTimeout(startGeolocationPoll, 100);
            } else {
                switchMenuLocationType(loggedInUser.defaultAddress ? MENU_LOCATION_TYPES.DELIVERY_ADDRESS : MENU_LOCATION_TYPES.DEFAULT_MENU);
                setLoadingLocation(false);
            }
        } else {
            switchMenuLocationType(MENU_LOCATION_TYPES.CURRENT_LOCATION);
            if (timeout.current) clearTimeout(timeout.current);
            setLoadingLocation(false);
        }
    };

    const determineMenuType = () => {
        const menuTypeFromLocalStorage = localStorage.getItem('menuType');
        const { locationType: menuTypeFromRouter } = locationState;

        // priority: component state > location state > localStorage
        const tentativeMenuType = menuTypeFromLocalStorage && menuTypeFromRouter && menuType;

        if (isGeolocationEnabled) {
            if (!coords) {
                startGeolocationPoll();
                return false; // cannot fetch menu yet
            };
            if (tentativeMenuType === MENU_LOCATION_TYPES.CURRENT_LOCATION) return MENU_LOCATION_TYPES.CURRENT_LOCATION;
        }
        return tentativeMenuType || (loggedInUser && loggedInUser.defaultAddress) ? MENU_LOCATION_TYPES.DELIVERY_ADDRESS :
            MENU_LOCATION_TYPES.DEFAULT_MENU;
    }

    const switchMenuLocationType = (type: string) => {
        if (type === menuType && !loggedInUser) {
            setShowZipCodeModal(true);
        }
        if (type === menuType) return;

        setHasReceivedMenu(false);
        if (error) setError(null);
        if (type === MENU_LOCATION_TYPES.CURRENT_LOCATION) {
            if (!isGeolocationEnabled) {
                setShowPleaseEnableLocation(true);
                setLoadingLocation(false);
                //if (isFullMenuShown  && !hasUserSeenFullMenuModal) showFullMenuModal();
                setHasReceivedMenu(true);
                clearTimeout(timeout.current);
            } else if (!coords) {
                setLoadingLocation(true);
                setMenuType(type);
                dispatch(setCurrentMenuType(type))
            } else {
                if (loadingLocation) setLoadingLocation(false);
                setMenuType(type);
                dispatch(setCurrentMenuType(type))
                setDsprId(null);
                setDsprs(null);
            }
        } else if (type === MENU_LOCATION_TYPES.DELIVERY_ADDRESS && !loggedInUser.defaultAddress) {
            if (!loggedInUser) {
                setMenuType(MENU_LOCATION_TYPES.DEFAULT_MENU);
                setLoadingLocation(true);
                dispatch(setCurrentMenuType(MENU_LOCATION_TYPES.DEFAULT_MENU));
            } else {
                history.push("/profile");
            }

        } else {
            setMenuType(type);
            dispatch(setCurrentMenuType(type))
            setDsprId(null);
            setDsprs(null);
        }
    };

    // Doesn't seem like the state field is used anywhere
    // useEffect(() => {
    //     dispatch(updateLoggedInUserInfo());
    //     setShowRecreational(isRecreationalMarket && userSignature);
    // }, [dispatch, isRecreationalMarket, userSignature]);

    // useEffect(() => {
    //     dispatch(updateLoggedInUserInfo());
    // }, [dispatch]);

    useEffect(() => {
        // eslint-disable-next-line
        const newMenuType = determineMenuType();
        if (newMenuType) switchMenuLocationType(newMenuType); // if the coordinates are pending, don't set
        // eslint-disable-next-line
    }, [isGeolocationEnabled, coords]); // dep list intentional

    useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current); // avoid repeated polling from closed-over calls
        }

        //disableIsFullMenuShown if user switches from viewing a full menu on one dspr, to dspr that does not have full menu enabled
        if (!isFullMenuAvailable) {
            dispatch(setIsFullMenuShownToUser(false));
        }

        // eslint-disable-next-line
        if (initializationComplete) {
            startMenuPolling(dsprId);
        }
        // eslint-disable-next-line
    }, [menuType, isFullMenuShown, dsprId, currentZipCode, initializationComplete]); // When the menu type is changed, poll

    useEffect(() => {
        //setShowWaitTimeMOdal(true)
        return () => {
            if (timeout) clearTimeout(timeout.current)
        }
    }, []);

    const allDocumentsNonExpired = () => {
        if (loggedInUser) {
            if (isRecreationalMarket) {
                return !!(idDocument
                    && idDocument.expirationDate
                    && parseDateEndOfDay(idDocument.expirationDate) > new Date());
            } else {
                return !!(medicalRecommendation
                    && medicalRecommendation.expirationDate
                    && parseDateEndOfDay(medicalRecommendation.expirationDate) > new Date()
                    && idDocument
                    && idDocument.expirationDate
                    && parseDateEndOfDay(idDocument.expirationDate) > new Date());
            }
        }
        return false;
    };

    const allDocumentsExist = () => {
        if (loggedInUser) {
            if (isRecreationalMarket) {
                return !!(userSignature && idDocument);
            } else {
                return !!(medicalRecommendation && idDocument);
            }
        }
        return false;
    };

    const allDocumentsVerified = () => {
        if (loggedInUser) {
            if (isRecreationalMarket) {
                return !!(idDocument && idDocument.verified);
            } else {
                return !!(medicalRecommendation
                    && medicalRecommendation.verified
                    && idDocument
                    && idDocument.verified);
            }
        }
        return false;
    };

    const processError = () => {
        console.log("Error: ", error);
        if (error && (error.includes(NO_SERVICE_ERROR_STRING) || error.includes(GOOGLE_ZIPCODE_ERROR))) {
            let zip = '';
            if (error.includes(GOOGLE_ZIPCODE_ERROR)) {
                zip = currentZipCode
            } else {
                zip = error.substring(error.indexOf('\''));
            }

            const buttonLabel = menuType === MENU_LOCATION_TYPES.DELIVERY_ADDRESS ?
                "SET NEW DELIVERY ADDRESS" :
                (loggedInUser && loggedInUser.defaultAddress) ? "VIEW DELIVERY ADDRESS MENU" : "CHANGE DELIVERY ZIPCODE";

            const buttonFunc = menuType === MENU_LOCATION_TYPES.DELIVERY_ADDRESS ? () => history.push("/profile") : (loggedInUser && loggedInUser.defaultAddress) ? () => switchMenuLocationType(MENU_LOCATION_TYPES.DELIVERY_ADDRESS) : () => setShowZipCodeModal(true);

            ReactGA.event({
                category: 'ZipCode',
                action: 'Load Zip Code Error',
                label: zip
            });

            return <div className="msg-style">
                Unfortunately, Grassp is not currently servicing zip code: {zip}.
                <Button color="primary" variant="contained" onClick={buttonFunc}>{buttonLabel}</Button>
            </div>;
        }
        return <p>{error}</p>;
    };

    const Notifications = <Fragment>
        {!allDocumentsExist() && (isRecreationalMarket ?
            <Notification message="Please provide your Signature and make sure you have uploaded your Driver's License/State ID!" variant="warning" />
            :
            <Notification message="Please upload your Driver's License/State ID and Medical Recommendation/Card!" variant="warning" />)}
        {allDocumentsExist() && !allDocumentsVerified() &&
            <Notification message="The Grassp team is verifying your documents. Feel free to browse the menu in the meantime!" variant="warning" />}
        {allDocumentsExist() && allDocumentsVerified() && !allDocumentsNonExpired() &&
            <Notification message="One of your documents has expired. Please upload current documents." variant="error" />}
    </Fragment>

    const Errors = showSomethingWentWrong ?
        <Notification message="Uh oh! Something went wrong trying to load the menu. Please reach out to support@grasspit.com for help!" variant="error" />
        : (hasReceivedMenu && awayMessage) ?
            (<div className="msg-style grassp-closed">
                {awayMessage}
            </div>) : null;

    const renderMenuOrMenuDirectoryOrMenuSwitcher = () => {
        if (dsprId) {
            if (!value && section === 'categories') {
                return <MenuDirectory directoryType={'categories'} />
            }

            if (!value && section === 'brands') {
                return <MenuDirectory directoryType={'brands'} />
            }

            return (
                <Menu
                    dsprId={dsprId}
                    productCategories={productCategoriesWithPromotions.sort((a, b) => a.order - b.order)}
                    products={products}
                    loggedInUser={loggedInUser}
                    medicalRecommendation={medicalRecommendation}
                    idDocument={idDocument}
                    currentZipCode={currentZipCode}
                    isRecreationalMarket={isRecreationalMarket}
                    userSignature={userSignature}
                    initialCategoryId={initialCategoryId}
                    dsprFullName={getDsprFullName(dsprId)}
                    dsprName={getDsprName(dsprId)}
                    dsprImageLocation={API_HOST + getDsprImageLocation(dsprId)}
                    mostRecentDspr={!locationState.fromSidebarNavigation && showingMostRecentDspr && !hasSeenDsprModal}
                    setHasSeenDsprModal={setHasSeenDsprModal}
                    Notifications={Notifications}
                    Errors={Errors}
                    LocationMenu={<SetLocationMenu
                        defaultAddress={loggedInUser && loggedInUser.defaultAddress}
                        currentMenuType={menuType}
                        setMenuType={switchMenuLocationType}
                        Icon={menuType === MENU_LOCATION_TYPES.CURRENT_LOCATION
                            ? <LocationIcon style={{ color: 'black' }} />
                            : <MapIcon style={{ color: 'black' }} />}
                    />}
                    FiltersMenu={<FiltersMenu Icon={<KeyboardArrowDown style={{ color: 'black' }} />} openFiltersMenu={showFiltersModal} />}
                    isFullMenuAvailable={isFullMenuAvailable}
                    isFullMenuShown={isFullMenuShown}
                    menuSorting={menuSorting}
                    menuFilters={menuFilters}
                    menuSearch={menuSearch}
                    clearMenuSearch={clearMenuSearch}
                    handleChangeForFullMenuCheckbox={handleChangeForFullMenuCheckbox}
                    currentMenuType={menuType}
                    currentMenu={currentMenu}
                />
            )
        }

        if (dsprs) {
            return (
                <MenuSwitcher
                    dsprs={dsprs}
                    menuType={menuType}
                    currentZipCode={currentZipCode}
                    handleDsprClick={dsprId => {
                        dispatch(setMostRecentDSPR(dsprId));
                        history.push('/menu');
                    }}
                    getImageLocationForDSPR={getDsprImageLocation}
                    Notifications={Notifications}
                    Errors={Errors}
                    LocationMenu={<SetLocationMenu
                        defaultAddress={loggedInUser && loggedInUser.defaultAddress}
                        currentMenuType={menuType}
                        setMenuType={switchMenuLocationType}
                        Icon={<ArrowDropDownIcon />}
                    />}
                />
            )
        }
    }

    return <div>
        {loadingLocation ? <LoadingSpinner loadingMessage="Finding Your Location..." /> :
            //TODO: confirm commented out code was incorrect/reversed
            !hasReceivedMenu ? (showZipCodeModal ? <div></div> : <LoadingSpinner loadingMessage={dsprs ? "Loading Products..." : "Loading Dispensaries..."} />) :
                //!hasReceivedMenu ? <LoadingSpinner loadingMessage={dsprs ? "Loading Dispensaries..." : "Loading Products..."} /> :
                <Fragment>
                    <div>
                        {renderMenuOrMenuDirectoryOrMenuSwitcher()}
                    </div>

                    {/*Cannot find current location dialog*/}
                    <Dialog open={showCannotLocate}
                        onClose={() => setShowCannotLocate(false)}
                    >
                        <div className="msg-style">
                            Cannot find your location. Please make sure that your location services are enabled for your browser
                            and globally on your device. Otherwise, please set a delivery address
                            <Link to="/profile">here</Link>
                        </div>
                        <Button onClick={() => {
                            setShowCannotLocate(false);
                            if (isGeolocationEnabled) switchMenuLocationType(MENU_LOCATION_TYPES.CURRENT_LOCATION);
                        }}>
                            Done
                        </Button>
                    </Dialog>

                    {/*Enable location or set delivery address dialog*/}
                    <Dialog open={showPleaseEnableLocation}
                        onClose={() => setShowPleaseEnableLocation(false)}
                    >
                        <div className="msg-style">
                            Please enable location services or set a delivery address <Link to="/profile">here</Link>
                        </div>
                        <Button onClick={() => {
                            setShowPleaseEnableLocation(false);
                            if (isGeolocationEnabled) switchMenuLocationType(MENU_LOCATION_TYPES.CURRENT_LOCATION);
                        }}>
                            Done
                        </Button>
                    </Dialog>

                    {/*Enable location or set delivery address dialog*/}
                    <FilterSelectionModal isVisible={filtersModalVisible} closeModal={hideFiltersModal} clearFilters={clearMenuFilters} />

                    {/*Full menu results in longer delivery times modal/dialog*/}
                    <DelayedDeliveryFullMenuModal
                        isVisible={isFullMenuModalVisible && !hasUserSeenFullMenuModal}
                        closeFullMenuModal={closeFullMenuModal}
                    />

                    {error && processError()}
                </Fragment>}

        {showZipCodeModal && <Fragment>
            <Dialog open={showZipCodeModal}
                onClose={() => setShowZipCodeModal(false)}
                disableBackdropClick={true}
            >
                {/* Create an input that users can use to put in their zip code */}
                <div className="msg-style">
                    Please enter your zip code to view the menu.
                </div>
                <ZipCodeForm onSubmit={handleSubmitZipCodeForm} handleCancel={() => { }} enableButton={true} errorMessage={""} />
            </Dialog>
        </Fragment>}
    </div>
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000
})(MenuContainer);