import React, { Component} from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Card, CardHeader, CardContent, withStyles, Typography } from "@material-ui/core";

import { attemptLogin } from "../actions/oauthActions";
import { getUserIdDocument, getUserMedicalRecommendation } from "../actions/userActions";
import { clearErrorMessage } from "../actions/apiUIHelperActions";

import { getLoggedInUser } from "../selectors/userSelectors";
import { getUserIdDocumentFromState, getUserMedicalRecommendationFromState } from "../selectors/userDocumentsSelector";
import { getError } from "../selectors/errorSelector";

import { history } from "../index.js";
import { parseDateEndOfDay } from '../util/util';

import LoginForm from "./LoginForm";

const styles = {
    loginPage: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "inherit",
    },
    header: {
        marginTop: 8,
        marginBottom: 8,
        display: "flex",
        justifyContent: "center",
        fontFamily: "reklame-script", 
        userSelect: "none" as const,
        fontWeight: 600
    },
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    card: {
        marginTop: "10%",
        width: "90%",
        maxWidth: 450,
        height: "fit-content",
        overflowY: "auto" as const
    },
    badge: {
        top: 12,
        right: 7,
        background: "black",
        backgroundSize: "50%",
        color: "white",
        width: '50%',
        height: '50%'
    },
    health: { 
        color: '#77C03E', 
        fontSize: "100%", 
        fontStyle: "italicize",
        alignSelf: "center",
        fontWeight: 600,
    }
}
class Login extends Component<any, {errorMessage: string, enableButton: boolean}> {
    // loginCont;
    timeout: NodeJS.Timeout;
    state = {
        errorMessage: null,
        enableButton: true,
    }

    componentDidMount() {
        this.pollLoggedInUser();
        // window.addEventListener('resize', this.resize);
        // this.resize();
    }

    // componentDidUpdate() {
    //     this.resize();
    // }

    // resize = () => {
    //     const loginNode = ReactDOM.findDOMNode(this.loginCont);
    //     if (loginNode !== null) {
    //         const diff = loginNode.offsetHeight - loginNode.clientHeight;
    //         loginNode.style.height = (window.innerHeight - loginNode.offsetTop + diff) + 'px';
    //     }
    // }

    handleSubmit = userLoginValues => {
        this.setState({ enableButton: false }, () => {
            this.props.attemptLogin(userLoginValues.email.trim(), userLoginValues.password)
                .then(() => this.pollLoggedInUser());
        })
    }

    handleSignUp = () => {
        history.push("/signup")
    }

    componentWillReceiveProps = newProps => {
        if (newProps.errorMessage !== "") {
            this.setState({
                errorMessage: newProps.errorMessage,
                enableButton: true,
            });
            this.props.clearErrorMessage();
        }
    }

    pollLoggedInUser = () => {
        if (this.props.loggedInUser) {
            if (!this.props.idDocument && !this.props.medicalRecommendation) {
                history.push("/signup");
            } else {
                this.props
                    .getUserIdDocument()
                    .then(() => this.props.getUserMedicalRecommendation())
                    .then(() => {
                        if (
                            !this.props.idDocument ||
                            !this.props.idDocument.verified ||
                            parseDateEndOfDay(this.props.idDocument.expirationDate) < new Date()
                            // || !this.props.medicalRecommendation ||
                            // !this.props.medicalRecommendation.verified ||
                            // this.props.medicalRecommendation.expirationDate < Date.now()
                        ) {
                            history.push("/profile");
                        } else {
                            history.push("/");
                        }
                    })
            }
        } else {
            if (!this.props.errorMessage) {
                this.timeout = setTimeout(this.pollLoggedInUser, 100);
            }
            // else {
            // history.push('/login');
            // }
        }
    }

    // className="login-section card-with-form"
    render() {
        return (
            <div className={this.props.classes.loginPage}
                style={{textAlign: 'center'}}
                // ref={(loginCont) => { this.loginCont = loginCont; }}
            >
                <Card className={this.props.classes.card}>
                    <CardHeader 
                        title={
                            <Typography variant="h4" color="inherit" className={this.props.classes.grow} onClick={() => <Link to="/" /> } style={{ margin: 'auto', width: 'auto' }}>
                                <p className={this.props.classes.header}>
                                    <img src='/images/GrasspLogo.png' alt="Grassp" style={{ height: '30px', top: '6px', position: 'relative', marginRight: '1px' }} />
                                    rassp<em className={this.props.classes.health}>Health</em>
                                </p>
                            </Typography>
                        }
                        subheader={
                            <div>
                                Don't have an account? <Link to="/signup">Sign Up!</Link>
                            </div>
                        } 
                    />
                    <CardContent>
                        <LoginForm onSubmit={this.handleSubmit} handleCancel={this.handleSignUp} enableButton={this.state.enableButton} errorMessage={this.state.errorMessage}/>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errorMessage: getError(state),
    loggedInUser: getLoggedInUser(state),
    idDocument: getUserIdDocumentFromState(state),
    medicalRecommendation: getUserMedicalRecommendationFromState(state)
})

const mapDispatchToProps = {
    attemptLogin,
    getUserIdDocument,
    getUserMedicalRecommendation,
    clearErrorMessage,
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)))

