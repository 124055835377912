import { API_ROOT, CALL_API, Schemas } from '../middleware/api';
import { getAppAccessToken, LOCAL_STORAGE_ACCESS_TOKEN_KEY } from './oauthActions';
import 'whatwg-fetch';

const baseURL = window.location.protocol + "//" + window.location.host;

const CALLBACK_EMAIL_CONFIRM_URL = `${baseURL}/validate-email/`;
const FORGOT_PASSWORD_EMAIL_CALLBACK_TEMPLATE = `${baseURL}/reset-password/`;

export const LOGGED_IN_USER_INFO = 'LOGGED_IN_USER_INFO';
export const LOGGED_IN_USER_INFO_SUCCESS = 'LOGGED_IN_USER_INFO_SUCCESS';
export const LOGGED_IN_USER_INFO_FAILURE = 'LOGGED_IN_USER_INFO_FAILURE';

// Get the info about the logged in user
const getLoggedInUser = () => ({
    [CALL_API]: {
        httpAction: 'GET',
        types: [LOGGED_IN_USER_INFO, LOGGED_IN_USER_INFO_SUCCESS, LOGGED_IN_USER_INFO_FAILURE],
        endPoint: 'user',
        schema: Schemas.USER
    }
});

export const updateLoggedInUserInfo = () => (dispatch, getState) => {
    return dispatch(getLoggedInUser());
};


export const GET_ALL_DSPR_SERVICING_ZIP_CODE = 'GET_ALL_DSPR_SERVICING_ZIP_CODE';
export const GET_ALL_DSPR_SERVICING_ZIP_CODE_SUCCESS = 'GET_ALL_DSPR_SERVICING_ZIP_CODE_SUCCESS';
export const GET_ALL_DSPR_SERVICING_ZIP_CODE_FAILURE = 'GET_ALL_DSPR_SERVICING_ZIP_CODE_FAILURE';

const allDSPRServicingZipCode = (zipCode) => ({
    [CALL_API]: {
        httpAction: 'GET',
        types: [GET_ALL_DSPR_SERVICING_ZIP_CODE, GET_ALL_DSPR_SERVICING_ZIP_CODE_SUCCESS, GET_ALL_DSPR_SERVICING_ZIP_CODE_FAILURE],
        endPoint: `dspr/zip_code/list_dsprs`,
        schema: Schemas.DSPR_ARRAY,
        queryParamsMap: {
            zip_code: zipCode
        }
    }
});

export const getAllDSPRServicingZipCode = (zipCode) => (dispatch) => {
    return dispatch(allDSPRServicingZipCode(zipCode));
};


export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS';
export const CREATE_NEW_USER_FAILURE = 'CREATE_NEW_USER_FAILURE';

// Creates a new Grassp user.

const createUser = (user, referrerIdentityCode?: any) => {
    const userRegistration = referrerIdentityCode ? {
        user: {...user, email: user.email.trim()},
        callbackURLForEmailValidation: CALLBACK_EMAIL_CONFIRM_URL,
        referrerIdentityCode: referrerIdentityCode
    } : {
        user: {...user, email: user.email.trim()},
        callbackURLForEmailValidation: CALLBACK_EMAIL_CONFIRM_URL
    };
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [ CREATE_NEW_USER, CREATE_NEW_USER_SUCCESS, CREATE_NEW_USER_FAILURE ],
            endPoint: 'user',
            schema: Schemas.USER,
            body: userRegistration
        }
    }
};

//Given a zipcode we can find out all the DSPRs that are servicing that zip code... there can be multiple DSPRs
//We're creating the user, then we make at least make one call otherwise we have no DSPR object since they aren't even at the menu yet
export const createNewUser = (user) => (dispatch, getState) => {
    return dispatch(getAppAccessToken())
        .then(() => dispatch(createUser(user)))
};

export const createNewUserWithReferral = (user, referrerIdentityCode) => (dispatch, getState) => {
    return dispatch(getAppAccessToken())
        .then(() => dispatch(createUser(user, referrerIdentityCode)));
};

export type USER_ID_DOCUMENT = 'identification_document';
export const USER_ID_DOCUMENT = 'identification_document';
export type USER_MEDICAL_RECOMMENDATION = 'medical_recommendation';
export const USER_MEDICAL_RECOMMENDATION = 'medical_recommendation';
//export const USER_SIGNATURE = 'signature';

export const UPLOAD_USER_DOCUMENT = 'UPLOAD_USER_DOCUMENT';
export const UPLOAD_USER_DOCUMENT_SUCCESS = 'UPLOAD_USER_DOCUMENT_SUCCESS';
export const UPLOAD_USER_DOCUMENT_FAILURE = 'UPLOAD_USER_DOCUMENT_FAILURE';

const userDocumentUploader = (userId, docType, imageFile) => {
    const userDocument = {
        user: {id : userId},
        fileName: imageFile.name
    };

    switch (docType) {
        case USER_ID_DOCUMENT:
        case USER_MEDICAL_RECOMMENDATION:
            return {
                [CALL_API] : {
                    httpAction: 'POST',
                    types: [UPLOAD_USER_DOCUMENT, UPLOAD_USER_DOCUMENT_SUCCESS, UPLOAD_USER_DOCUMENT_FAILURE],
                    endPoint: `user/${docType}`,
                    schema: docType === USER_ID_DOCUMENT ? Schemas.USER_ID_DOCUMENT : Schemas.USER_MEDICAL_RECOMMENDATION,
                    body: userDocument,
                    file: imageFile,
                }
            };
        default:
            throw new Error('Invalid document type.')
    }

};

export const uploadUserDocument = (userId: any, docType: USER_DOCUMENT_TYPES, imageFile: any) => (dispatch, getState) => {
    return dispatch(userDocumentUploader(userId, docType, imageFile))
        .then(() => dispatch(getLoggedInUser()));
};

export type USER_DOCUMENT_TYPES = USER_ID_DOCUMENT | USER_MEDICAL_RECOMMENDATION;

export const getUserDocumentUrl = (document: USER_DOCUMENT_TYPES, userId) =>
    API_ROOT + `user/${document}/document/${userId}?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`;

export const getDocumentImage = (document, filename) =>
    API_ROOT + `user/${document}/documentfile/${btoa(filename)}?access_token=${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`;

export const GET_USER_ID_DOCUMENT = 'GET_USER_ID_DOCUMENT';
export const GET_USER_ID_DOCUMENT_SUCCESS = 'GET_USER_ID_DOCUMENT_SUCCESS';
export const GET_USER_ID_DOCUMENT_FAILURE = 'GET_USER_ID_DOCUMENT_FAILURE';

const userIdDocument = () => ({
    [CALL_API]: {
        httpAction: 'GET',
        types: [GET_USER_ID_DOCUMENT, GET_USER_ID_DOCUMENT_SUCCESS, GET_USER_ID_DOCUMENT_FAILURE],
        endPoint: `user/${USER_ID_DOCUMENT}`,
        schema: Schemas.USER_ID_DOCUMENT
    }
});

export const getUserIdDocument = () => (dispatch) => {
    return dispatch(userIdDocument());
};

export const GET_USER_MEDICAL_RECOMMENDATION = 'GET_USER_MEDICAL_RECOMMENDATION';
export const GET_USER_MEDICAL_RECOMMENDATION_SUCCESS = 'GET_USER_MEDICAL_RECOMMENDATION_SUCCES';
export const GET_USER_MEDICAL_RECOMMENDATION_FAILURE = 'GET_USER_MEDICAL_RECOMMENDATION_FAILURE';


const userMedicalRecommendation = () => ({
    [CALL_API]: {
        httpAction: 'GET',
        types: [GET_USER_MEDICAL_RECOMMENDATION, GET_USER_MEDICAL_RECOMMENDATION_SUCCESS, GET_USER_MEDICAL_RECOMMENDATION_FAILURE],
        endPoint: `user/${USER_MEDICAL_RECOMMENDATION}`,
        schema: Schemas.USER_MEDICAL_RECOMMENDATION
    }
});

export const getUserMedicalRecommendation = () => (dispatch) => {
    return dispatch(userMedicalRecommendation());
};

export const SET_USER_DEFAULT_ADDRESS = 'SET_USER_DEFAULT_ADDRESS';
export const SET_USER_DEFAULT_ADDRESS_SUCCESS = 'SET_USER_DEFAULT_ADDRESS_SUCCESS';
export const SET_USER_DEFAULT_ADDRESS_FAILURE = 'SET_USER_DEFAULT_ADDRESS_FAILURE';

const userDefaultAddressSetter = address => ({
    [CALL_API]: {
        httpAction: 'POST',
        types: [ SET_USER_DEFAULT_ADDRESS, SET_USER_DEFAULT_ADDRESS_SUCCESS, SET_USER_DEFAULT_ADDRESS_FAILURE ],
        endPoint: 'user/default-address',
        schema: Schemas.USER,
        body: address
    }
});

export const setUserDefaultAddress = (address) => (dispatch, getState) => {
    return dispatch(userDefaultAddressSetter(address))
        .then((response) => dispatch(getLoggedInUser()).then(()=> {
            return response
        }));
};

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

const passwordChanger = (oldPassword, newPassword) => {
    const passwordChanger = {oldPassword, newPassword};
    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE],
            endPoint: `user/change-password`,
            schema: Schemas.USER,
            body: passwordChanger,
        }
    }
};

export const changePassword = (oldPassword, newPassword) => (dispatch, getState) => {
    return dispatch(passwordChanger(oldPassword, newPassword));
};

export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE';

const emailValidator = (token) => {
    const passwordChanger = {
        changeToken: token
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [VALIDATE_EMAIL, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAILURE],
            endPoint: `user/validate-email`,
            schema: Schemas.USER,
            body: passwordChanger,
        }
    }
}

export const validateEmail = (token) => (dispatch, getState) => {
    return dispatch(emailValidator(token));
};

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

const emailRegexCheck = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

const phoneNumberRegexCheck = (phoneNumber) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    return re.test(phoneNumber);
}

const passwordForgotten = (emailOrPhoneNumber) => {
    const userObject = emailRegexCheck(emailOrPhoneNumber) ? {
        email: emailOrPhoneNumber
    } : phoneNumberRegexCheck(emailOrPhoneNumber) ? {
        phoneNumber: emailOrPhoneNumber
    } : {};

    const passwordChanger = {
        user: userObject,
        callBackURLTemplate: FORGOT_PASSWORD_EMAIL_CALLBACK_TEMPLATE
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE],
            endPoint: `user/forgot-password-email`,
            schema: Schemas.USER,
            body: passwordChanger,
        }
    }
};

export const forgotPassword = (emailOrPhoneNumber) => (dispatch, getState) => {
    return dispatch(passwordForgotten(emailOrPhoneNumber));
};

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

const passwordResetter = (changeToken, newPassword) => {
    const passwordChanger = {
        newPassword,
        changeToken
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
            endPoint: `user/change-password-from-email`,
            schema: Schemas.USER,
            body: passwordChanger,
        }
    }
};

export const resetPassword = (changeToken, newPassword) => (dispatch, getState) => {
    return dispatch(getAppAccessToken()).then(() => dispatch(passwordResetter(changeToken, newPassword)));
};

export const ATTEMPT_LEGACY_REGISTRATION = "ATTEMPT_LEGACY_REGISTRATION";
export const ATTEMPT_LEGACY_REGISTRATION_SUCCESS = "ATTEMPT_LEGACY_REGISTRATION_SUCCESS";
export const ATTEMPT_LEGACY_REGISTRATION_FAILURE = "ATTEMPT_LEGACY_REGISTRATION_FAILURE";

const legacyRegister = (email, password) => {
    const userRegistration = {
        user: {email: email.trim(), password},
        callbackURLForEmailValidation: CALLBACK_EMAIL_CONFIRM_URL
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            types: [ ATTEMPT_LEGACY_REGISTRATION, ATTEMPT_LEGACY_REGISTRATION_SUCCESS, ATTEMPT_LEGACY_REGISTRATION_FAILURE ],
            endPoint: 'user/legacy-user',
            schema: Schemas.USER,
            body: userRegistration
        }
    }
};

export const attemptLegacyRegistration = (email, password) => (dispatch, getState) => {
    return dispatch(legacyRegister(email, password));
};

export const UPLOAD_USER_SIGNATURE = "UPLOAD_USER_SIGNATURE";
export const UPLOAD_USER_SIGNATURE_SUCCESS = "UPLOAD_USER_SIGNATURE_SUCCESS";
export const UPLOAD_USER_SIGNATURE_FAILURE = "UPLOAD_USER_SIGNATURE_FAILURE";

const userSignatureUploader = (dataURL) => {
    const userSignature = {
        dataURL
    };

    return {
        [CALL_API]: {
            httpAction: 'POST',
            endPoint: `user/signature`,
            types: [UPLOAD_USER_SIGNATURE, UPLOAD_USER_SIGNATURE_SUCCESS, UPLOAD_USER_SIGNATURE_FAILURE],
            schema: Schemas.USER_SIGNATURE,
            body: userSignature,
        }
    }
};

export const uploadUserSignature = (dataURL) => (dispatch, getState) => {
    return dispatch(userSignatureUploader(dataURL));
};

export const GET_USER_SIGNATURE = 'GET_USER_SIGNATURE';
export const GET_USER_SIGNATURE_SUCCESS = 'GET_USER_SIGNATURE_SUCCESS';
export const GET_USER_SIGNATURE_FAILURE = 'GET_USER_SIGNATURE_FAILURE';

const userSignature = () => ({
    [CALL_API]: {
        httpAction: 'GET',
        types: [GET_USER_SIGNATURE, GET_USER_SIGNATURE_SUCCESS, GET_USER_SIGNATURE_FAILURE],
        endPoint: `user/signature`,
        schema: Schemas.USER_SIGNATURE
    }
});

export const getUserSignature = () => (dispatch) => {
    return dispatch(userSignature());
};

// export const CHECK_USER_EMAIL = 'CHECK_USER_EMAIL';
// export const CHECK_USER_EMAIL_SUCCESS = 'CHECK_USER_EMAIL_SUCCESS';
// export const CHECK_USER_EMAIL_FAILURE = 'CHECK_USER_EMAIL_FAILURE'
//
// const checkUserEmailTaken = (userEmail) => ({
//     [CALL_API]: {
//         httpAction: 'POST',
//         types: [CHECK_USER_EMAIL, CHECK_USER_EMAIL_SUCCESS, CHECK_USER_EMAIL_FAILURE],
//         endPoint: 'user/check-email-exists',
//         schema: Schemas.USER,
//         body: {
//             email: userEmail
//         }
//     }
// })
//
// export const checkIfEmailTaken = (userEmail) => (dispatch) => {
//     return dispatch(checkUserEmailTaken(userEmail)).then((response) => {
//         return response.response.entities.users[response.response.result].isEmailValidated;
//     })
// }