import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga';
import {withRouter} from 'react-router-dom'
import { history } from '../index.js'

import {Card, CardContent, CardActions, CardHeader, CardMedia, Dialog, Snackbar, Button} from '@material-ui/core';

import {addProductToCart,FLOWER_UNITS} from '../actions/cartActions';

import {getOrderDetailForProductInCart} from '../selectors/cartSelector';
import {getCurrentQuantityAvailableForProduct, getProductFromProps} from '../selectors/menuSelector';

import {API_HOST} from '../middleware/api';

class MenuProductDialog extends Component {
    autoHideDuration = 3000;

    constructor(props) {
        super(props);
        this.state = {
            snackBarAutoHideDuration: this.autoHideDuration,
            snackBarOpen: false,
            snackBarMessage: ''
        };
    }

    modifySnackBar = (quantity, unit) => {
        if(!this.props.showSnackBar) return;
        let message = `${quantity}${unit ? ' ' + unit : ''} now in your cart.`;
        message = quantity === this.props.currentQuantityAvailableForProduct ?
            message + ' You got the last one!' : message;
        this.setState({
            snackBarOpen: true,
            snackBarMessage: message
        });
    };

    handleAddProductToCart = (product, unit, quantity=1) => () => {
        this.props.addProductToCart(product, unit, quantity);
        let gaLabel = product.name + 'x' + quantity;
        if(unit) gaLabel = gaLabel + ' ' + unit;
        ReactGA.event({
            category: 'Product',
            action: 'Product Added to Cart',
            value: product.id,
            label: gaLabel
        });

        this.modifySnackBar(quantity, unit);
    };

    handleSnackBarClose = () => {
        this.setState({
            snackBarOpen: false,
            snackBarMessage: ''
        });
    };

    handleGoToCart = () => {
        this.setState({
            snackBarOpen: false,
            snackBarMessage: ''
        });
        history.push('/cart')
    };

    createFlowerUnitButton(product, unit, orderDetailInCartForProduct) {
        if(!product.currentPrice[`${unit.name}Price`]) return null;
        const price = product.currentPrice[`${unit.name}Price`];
        const displayPrice = Number.isInteger(price) ?
            price :
            parseFloat(price).toFixed(2);
        return <div className='prod-btn-box' key={unit.name}><Button
            style={{minWidth: '25px', paddingLeft: '12px', paddingRight: '12px'}}
            className='raised-button'
            color="primary" variant="contained"
            onClick={this.handleAddProductToCart(product, unit.name) }
            disabled={ (orderDetailInCartForProduct && orderDetailInCartForProduct.unit === unit.name)
            || !this.props.docsVerified}
        >{`$${displayPrice}`}</Button><br/>{unit.label} oz.</div>
    }

    renderAddToCartButtons(product) {
        const {currentQuantityAvailableForProduct, orderDetailInCartForProduct} = this.props;
        if (product.isFlower) {
            const buttons = [];
            if (currentQuantityAvailableForProduct > 7) {
                buttons.push(this.createFlowerUnitButton(product, FLOWER_UNITS.oz, orderDetailInCartForProduct));
            }
            if (currentQuantityAvailableForProduct > 3) {
                buttons.push(this.createFlowerUnitButton(product, FLOWER_UNITS.half, orderDetailInCartForProduct));
            }
            if (currentQuantityAvailableForProduct > 1) {
                buttons.push(this.createFlowerUnitButton(product, FLOWER_UNITS.quarter, orderDetailInCartForProduct));
                buttons.push(this.createFlowerUnitButton(product, FLOWER_UNITS.eighth, orderDetailInCartForProduct));
            } else if (currentQuantityAvailableForProduct === 1) {
                buttons.push(this.createFlowerUnitButton(product, FLOWER_UNITS.eighth, orderDetailInCartForProduct));
            }
            return buttons.reverse();
        } else {
            let i = 0;
            const buttons = [];
            while(i < 4 && i < currentQuantityAvailableForProduct) {
                i++;
                const price = product.currentPrice.price*i;
                const displayPrice = Number.isInteger(price) ?
                    price :
                    parseFloat(price).toFixed(2);
                buttons.push(
                    <div className='prod-btn-box' key={i}><Button
                        style={{minWidth: '25px', paddingLeft: '12px', paddingRight: '12px'}}
                        key={i}
                        className='raised-button'
                        color="primary" variant="contained"
                        onClick={this.handleAddProductToCart(product, undefined, i)}
                        disabled={
                            (orderDetailInCartForProduct && i === orderDetailInCartForProduct.quantity)
                            || !this.props.docsVerified
                        }
                    >{`$${displayPrice}`}</Button><br/>for {i}</div>);
            }
            return buttons;
        }

    }

    render() {
        const {product, flowerType, onClose} = this.props;
        return <div>
            {product && <Dialog className="menu-dialog" open={product !== null}
                                onClose={onClose}
            >
                <Card className="dialog-product" style={{display: "flex", flexDirection: "column"}}>
                    <CardHeader title={<b>{product.name}</b>}
                    subheader={
                      <div>
                        {flowerType}
                        {product.thcPercentage > 0 && <span>THC: {product.thcPercentage}% </span>}
                        {product.cbdPercentage > 0 && <span>CBD: {product.cbdPercentage}% </span>}
                        {product.cbnPercentage > 0 && <span>CBN: {product.cbnPercentage}% </span>}
                        {product.thcMg > 0 && <span>THC: {product.thcMg}mg </span>}
                        {product.cbdMg > 0 && <span>CBD: {product.cbdMg}mg </span>}
                      </div>
                    }/>
                    <div style={{display:'flex', justifyContent:'center', height: "auto"}}>
                        <CardMedia
                            image={`${API_HOST}${product.imageLocation}`}
                            key={product.imageLocation}
                            title={`${product.name} :${product.description}`}
                            style={{
                                height: 0,
                                paddingTop: "28.125%",
                                width:'50%',
                                minWidth:'50%',
                                maxWidth:'50%',
                                marginBottom: "10px",
                                objectFit: 'contain'
                            }}
                        />
                    </div>
                    <CardContent className="card-text" style={{display: 'flex', overflowY: 'scroll', scrollPaddingTop: 0, scrollPaddingBottom: 0, paddingTop: 0, marginTop: 20}}>
                        <p>{product.description}</p>
                    </CardContent>
                    <CardActions className="card-actions" style={{display: 'flex', justifyContent:"center"}}>
                      <div className='prod-btn-container'>
                        {this.renderAddToCartButtons(product)}
                      </div>
                    </CardActions>
                </Card>
            </Dialog>}
            <Snackbar
                open={this.state.snackBarOpen}
                message={this.state.snackBarMessage}
                action="Go to Cart"
                autoHideDuration={this.state.snackBarAutoHideDuration}
                onClick={this.handleGoToCart}
                onClose={this.handleSnackBarClose}
                className="cartSnackBar"
            />
        </div>
    }

}

const mapStateToProps = (state, ownProps) => {
    const product = isNaN(Number(ownProps.product)) ? ownProps.product : getProductFromProps(state, ownProps.product);
    const orderDetailInCartForProduct = ownProps.product ?
        isNaN(Number(ownProps.product)) ? getOrderDetailForProductInCart(state, ownProps.product.id) : getOrderDetailForProductInCart(state, ownProps.product)
        : undefined;
    const currentQuantityAvailableForProduct = ownProps.product ?
        isNaN(Number(ownProps.product)) ? getCurrentQuantityAvailableForProduct(state, ownProps.product.id) : getCurrentQuantityAvailableForProduct(state, ownProps.product)
        : undefined;
    return {
        orderDetailInCartForProduct,
        currentQuantityAvailableForProduct,
        product
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addProductToCart,
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuProductDialog));
