import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Card, CardContent, CardHeader} from '@material-ui/core';

import ForgotPasswordForm from '../components/ForgotPasswordForm';

import {forgotPassword} from '../actions/userActions';
import {getAppAccessToken} from '../actions/oauthActions';

class ForgotPassword extends Component {
    constructor(props){
        super(props);
        this.state = {emailSent: false}
    }

    componentDidMount() {
        this.props.getAppAccessToken();
    }

    handleSubmit = (values) => {
        this.props.forgotPassword(values.emailOrPhoneNumber);
        this.setState({emailSent: true});
    };

    render() {
        const subheader = this.state.emailSent ? "Email Sent" : "Please enter your email or phone number to begin the process of changing your password.";
        return <Card>
            <CardHeader title="Forgot Password"
            subheader={subheader}/>
            <CardContent>
                {!this.state.emailSent &&
                <ForgotPasswordForm onSubmit={this.handleSubmit}/>
                }
                {this.state.emailSent &&
                <div>Please check your email and follow the instructions to reset your password. Feel free to reach out to support@grasspit.com or call or text us at 623-562-3308 with any questions at any time!</div>}
            </CardContent>
        </Card>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        forgotPassword,
        getAppAccessToken
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);