import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';

import {Card, CardHeader, CardContent} from '@material-ui/core';

import {changePassword} from '../actions/userActions';
import {clearErrorMessage} from '../actions/apiUIHelperActions';

import {getError} from '../selectors/errorSelector';

import ChangePasswordForm from '../components/ChangePasswordForm';

class PasswordChanger extends Component{
    constructor() {
        super();
        this.state = {changeSuccessful: false,
            errorMessage: null,
            changeInProcess: false}
    }

    handleSubmit = (values) => {
        this.setState({changeInProcess: true});
        this.props.changePassword(values.oldPassword, values.newPassword)
            .then((response) => {
                this.setState({changeInProcess: false});
                if(response.error){
                    if(response.error === 'Access Denied') {
                        this.setState({errorMessage: 'The Old Password you entered is invalid. Please try again.'})
                    }
                    else {
                        this.setState({errorMessage: response.error});
                    }
                }
                else {
                    this.setState({changeSuccessful: true});
                }
            });
    };

    componentWillReceiveProps = (newProps) => {
        if(newProps.error !== '') {
            this.setState({changeSuccessful: false, errorMessage: newProps.error});
            this.props.clearErrorMessage();
        }
    };

    render() {
        return <Card>
            <CardHeader title="Change Password"/>
            <CardContent>
                {!this.state.changeSuccessful && !this.state.changeInProcess &&
                <div>
                    {this.state.errorMessage &&
                    <h2>{this.state.errorMessage}</h2>
                    }
                    <ChangePasswordForm onSubmit={this.handleSubmit} />
                </div>}
                {this.state.changeInProcess &&
                <div>Processing</div>
                }
                {this.state.changeSuccessful &&
                <h2>Password Changed Successfully! Go back to your <Link to="/profile">Profile</Link></h2>
                }
            </CardContent>
        </Card>
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        changePassword,
        clearErrorMessage
    }, dispatch);
};

const mapStateToProps = (state, ownProps) => {
    return {
        error: getError(state)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChanger);
