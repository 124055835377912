import React, { Fragment, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { shallowEqual, useSelector } from 'react-redux';
import { getLoggedInUser } from '../selectors/userSelectors';

export const MENU_LOCATION_TYPES = {
    CURRENT_LOCATION: 'CURRENT_LOCATION',
    DEFAULT_MENU: 'DEFAULT_MENU',
    DELIVERY_ADDRESS: 'DELIVERY_ADDRESS'
};

interface SetLocationMenuProps {
    currentMenuType: string;
    setMenuType: (newMenuType: string) => void;
    defaultAddress: any;
    Icon: JSX.Element;
}

const SetLocationMenu: React.FC<SetLocationMenuProps> = props => {
    const { currentMenuType, setMenuType, defaultAddress, Icon, } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const loggedInUser = useSelector(getLoggedInUser, shallowEqual);

    return <Fragment>
        <IconButton
            aria-owns={anchorEl ? 'menu-location-setter' : undefined}
            aria-haspopup="true"
            onClick={e => setAnchorEl(e.currentTarget)}
        >
            {Icon}
        </IconButton>
        <Menu
            id="menu-location-setter"
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            getContentAnchorEl={null}
            style={{ boxShadow: 'none' }}
        >
            <MenuItem
                classes={{ root: 'menu-item', selected: 'selected' }}
                selected={currentMenuType === MENU_LOCATION_TYPES.CURRENT_LOCATION}
                onClick={() => {
                    setAnchorEl(null);
                    setMenuType(MENU_LOCATION_TYPES.CURRENT_LOCATION)
                }}
            >
                Current Location
            </MenuItem>
            {loggedInUser && <MenuItem
                classes={{ root: 'menu-item', selected: 'selected' }}
                selected={currentMenuType === MENU_LOCATION_TYPES.DELIVERY_ADDRESS}
                onClick={() => {
                    setAnchorEl(null);
                    setMenuType(MENU_LOCATION_TYPES.DELIVERY_ADDRESS)
                }}
            >
                Delivery Address
            </MenuItem>}
            {!defaultAddress &&
                <MenuItem
                    classes={{ root: 'menu-item', selected: 'selected' }}
                    selected={currentMenuType === MENU_LOCATION_TYPES.DEFAULT_MENU}
                    onClick={() => {
                        setAnchorEl(null);
                        setMenuType(MENU_LOCATION_TYPES.DEFAULT_MENU)
                    }}
                >
                    {currentMenuType === MENU_LOCATION_TYPES.DEFAULT_MENU && !loggedInUser ? "Change Zip Code" : "Default Menu"}
                </MenuItem>
            }
        </Menu>
    </Fragment>
}

export default SetLocationMenu;