import React, {Component} from 'react';
import {Card, CardHeader, CardContent, Button} from '@material-ui/core';
import { history } from '../index.js'

export default class LegacyUserRegistration extends Component{
    render() {
        return <Card>
            <CardHeader title="Welcome to Grassp 2.0" />
            <CardContent>
                We appreciate all of your feedback and are excited to release many new enhancements. Please continue to contact support@grasspit.com or reach out to 714-944-8374 with feedback or questions! Thank you for your continued support.
                <div style={{marginTop: 20}}><Button color="primary" variant="contained" label="Get Started" onClick={() => history.push("/")}/></div>
            </CardContent>
        </Card>
    }
}
