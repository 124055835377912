import {createSelector} from 'reselect';

import {getLoggedInUser} from './userSelectors';

import {getAddresses} from './addressSelector'

const getOrders = (state) => state.api ?
    state.api.entities ?
        state.api.entities.orders
        : undefined
    : undefined;

const getDrivers = (state) =>  state.api ?
    state.api.entities ?
        state.api.entities.dsprDrivers
        : undefined
    : undefined;

export const getOrderFromProps = (state, props) => props.orderId ? 
    state.api ?
        state.api.entities ?
            state.api.entities.orders ?
                state.api.entities.orders[props.orderId]
                : undefined
            : undefined
        : undefined
    :undefined;

export const getOrderFromPropsWithAddress = createSelector(
    [getOrderFromProps, getAddresses], (order, addresses) => {
        return order ?
            addresses ?
                {...order, address: addresses[order.address]}
            : order
        : undefined
    }
)
export const getLoggedInUserOrderHistory = createSelector(
    [getLoggedInUser, getOrders], (loggedInUser, orders) => {
        return loggedInUser ?
            orders ?
                Object.keys(orders).filter(orderId => orders[orderId].user === loggedInUser.id).map(orderId => orders[orderId]).reverse()
                :
                undefined
            :
            undefined;
    }
);

export const getDriverFromOrder = createSelector(
    [getOrderFromProps, getDrivers], (order, drivers) => {
        return order ?
            drivers ?
                drivers[order.dsprDriver]
                : undefined
            :undefined;
    }
);