export const getDSPRs = state => state.api.entities.DSPRs;

export const getDsprNameFromState = state => dsprId => {
	if (dsprId === 6) return 'Grassp Health'; //Hardcode a display name for Zonacare Tucson
	return state.api.entities.DSPRs[dsprId] ? state.api.entities.DSPRs[dsprId]['name'] : '';
};

export const getDsprFullName = (state, { dsprId }) => {
	const { name: dsprName, deliveryServiceProvider } = state.api.entities.DSPRs[dsprId];
	const dspName = state.api.entities.deliveryServiceProviders[deliveryServiceProvider]['name'];
	return dspName + ' ' + dsprName;
};

export const getImageLocationForDSPR = (state, { dsprId }) => state.api.entities.DSPRs[dsprId].imageLocation;

export const getDsprFullNameWithId = state => dsprId => {
	const dspr =
		state && state.api && state.api.entities && state.api.entities.DSPRs && state.api.entities.DSPRs[dsprId]
			? state.api.entities.DSPRs[dsprId]
			: {};
	const { name: dsprName, deliveryServiceProvider } = dspr;

	const dspName =
		state &&
		state.api &&
		state.api.entities &&
		state.api.entities.deliveryServiceProviders &&
		state.api.entities.deliveryServiceProviders[deliveryServiceProvider]
			? state.api.entities.deliveryServiceProviders[deliveryServiceProvider]['name']
			: '';
	return dspName + ' ' + dsprName;
};

export const getDsprImageLocationWithId = state => dsprId => {
	return state && state.api && state.api.entities && state.api.entities.DSPRs && state.api.entities.DSPRs[dsprId]
		? state.api.entities.DSPRs[dsprId].imageLocation
		: '';
};

export const isFullMenuAvailableForDSPRFromProps = (state, { dsprId }) => {
	const dspr = state.api.entities.DSPRs[dsprId];

	return dspr ? !!dspr.isFullMenuAvailable : false;
};

export const isAeroPayDSPR = (state, { dsprId }) => {
	const dspr = state.api.entities.DSPRs[dsprId];
	return dspr ? !!dspr.allowAeroPayPayment : false;
};

export const isNoCashDSPR = (state, { dsprId }) => {
	const dspr = state.api.entities.DSPRs[dsprId];
	return dspr ? !!dspr.disableCashPayment : false;
};
