import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import './FullMenuModalStyles.css';
interface FullMenuModalProps {
    isVisible: boolean;
    closeFullMenuModal: () => void;
}

const DelayedDeliveryFullMenuModal = ({ isVisible, closeFullMenuModal, }: FullMenuModalProps) => {

    return(
        <Dialog open={isVisible} onClose={closeFullMenuModal} className={'full-menu-modal-container'}>
            <DialogTitle className={'full-menu-modal-title'}>Delivery Note:</DialogTitle>
            <DialogContent className={'full-menu-modal-content-container'}>
                <p>Ordering from the full menu is more likely to result in next day delivery.</p>
                <Button onClick={closeFullMenuModal} variant={'contained'} color={'primary'}>Dismiss</Button>
            </DialogContent>
        </Dialog>
    )

}

export default DelayedDeliveryFullMenuModal;