import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from "react-redux";
import { getBrandsForCurrentMenu, getProductCategoriesForCurrentMenu } from "../selectors/menuSelector";
import { Card, Typography } from "@material-ui/core";
import { history } from "../index";
import { API_HOST } from '../middleware/api';

const styles = {
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '120px 120px',
        gridGap: '28px',
        padding: 10,
        paddingTop: 16,
        // stylings to aid in development/positioning
        //backgroundColor: '#2196F3',
        //border: '10px solid black',
        justifyContent: 'center',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column' as const,
        minHeight: 150,
    },
    gridItemText: {
        marginTop: 'auto',
        textAlign: 'center' as const,
        padding: 5,
    },
    imageContainer: {
        width: "100%",
        height: "80%",
    },

}

interface MenuDirectoryProps {
    classes: any;
    directoryType?: 'categories' | 'brands';
}

const MenuDirectory = ({classes, directoryType}: MenuDirectoryProps) => {

    const categories = useSelector<any, [any]>(state => directoryType === 'categories' && getProductCategoriesForCurrentMenu(state), shallowEqual);
    const brands = useSelector<any, [any]>(state => directoryType === 'brands' && getBrandsForCurrentMenu(state), shallowEqual)
    //TODO create a brands selector if we decide to include brands in the UWA

    const renderGridItems = () => {
        //note - can possibly refactor & use the same map function if the category and brands objects share the same property names
        switch (directoryType) {
            case "categories":
                return categories.map(category => (
                    <Card className={classes.gridItem}
                          key={category.id}
                          onClick={() => history.push(`/menu/categories/${category.id}`)}>
                        <div className={classes.imageContainer}>
                            <img width="100%" height="100%" style={{objectFit: "cover"}} src={category.imageLocation ? API_HOST + category.imageLocation : "/images/No_Image_Available.jpeg"} alt={category.name}/>
                        </div>
                        <Typography className={classes.gridItemText}>{category.name}</Typography>
                    </Card>
                ));
            case "brands":
                return brands.map(brand => (
                    <Card className={classes.gridItem}
                          key={brand.id}
                          onClick={() => history.push(`/menu/brands/${brand.id}`)}>
                        <div className={classes.imageContainer}>
                            <img width="100%" height="100%" style={{objectFit: "contain"}} src={brand.imageLocation ? API_HOST + brand.imageLocation : "/images/No_Image_Available.jpeg"} alt={brand.name}/>
                        </div>
                        <Typography className={classes.gridItemText}>{brand.name}</Typography>
                    </Card>
                ))
            default:
                return [];
        }
        
    }

    return (
        <div className={classes.gridContainer}>
            {renderGridItems()}
        </div>
    )

}

export default withStyles(styles)(MenuDirectory);