import {
	LOGGED_IN_USER_INFO_SUCCESS,
	GET_USER_MEDICAL_RECOMMENDATION_SUCCESS,
	GET_USER_ID_DOCUMENT_SUCCESS,
	GET_ALL_DSPR_SERVICING_ZIP_CODE_SUCCESS,
	GET_USER_SIGNATURE_SUCCESS,
} from '../actions/userActions';
import {
	GET_MENU_BY_DEFAULT_ADDRESS_SUCCESS,
	GET_MENU_BY_LATLONG_SUCCESS,
	GET_MENU_BY_ZIPCODE_SUCCESS,
	GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_SUCCESS,
	HIDE_FULL_MENU_DELIVERY_MODAL_FROM_USER,
} from '../actions/menuActions';
import {
	CANCEL_ORDER_SUCCESS,
	GET_ORDER_COST_SUCCESS,
	LOG_AEROPAY_TRANSACTION_SUCCESS,
	ORDER_HISTORY_SUCCESS,
	SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_SUCCESS,
} from '../actions/orderActions';
import { merge } from 'lodash';

export const initialState = {
	users: {},
	deliveryServiceProviders: {},
	dspManagers: {},
	DSPRs: {},
	dsprManagers: {},
	dsprDrivers: {},
	usersIdDocuments: {},
	usersMedicalRecommendations: {},
	usersSignatures: {},
	menu: {},
	dspProducts: {},
	productCategories: {},
	dspBrands: {},
	addresses: {},
	orders: {},
	productCategoryPromotions: {},
	metrics: {},
	isFullMenuShown: false,
	currentMenuType: null,
	hasUserSeenFullMenuDeliveryModal: false,
};

const appendAndUpdateEntitiesFromResponse = (oldState, responseEntities, skipEntityTypes = []) => {
	let newState = merge({}, oldState);
	Object.keys(responseEntities).forEach((entityType) => {
		if (skipEntityTypes.includes(entityType)) return;
		let oldStateTypeEntities = oldState[entityType];
		let entitiesInResponse = responseEntities[entityType];
		newState[entityType] = merge({}, oldStateTypeEntities, entitiesInResponse);
	});
	return newState;
};

export default (state = initialState, action) => {
	let responseEntities = action.response ? action.response.entities : undefined;
	switch (action.type) {
		case GET_MENU_BY_LATLONG_SUCCESS:
		case GET_MENU_BY_DEFAULT_ADDRESS_SUCCESS:
		case GET_MENU_BY_ZIPCODE_SUCCESS:
			state = { ...state, menu: {}, productCategories: {}, dspProducts: {}, dspBrands: {} };
			return appendAndUpdateEntitiesFromResponse(state, responseEntities);
		case GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_SUCCESS:
			state = { ...state, productCategoryPromotions: {} };
			return appendAndUpdateEntitiesFromResponse(state, responseEntities);
		case LOGGED_IN_USER_INFO_SUCCESS:
			state = { ...state, users: {} };
			return appendAndUpdateEntitiesFromResponse(state, responseEntities);
		case GET_ORDER_COST_SUCCESS:
			state = { ...state, orders: {} };
			return appendAndUpdateEntitiesFromResponse(state, responseEntities);
		case GET_USER_ID_DOCUMENT_SUCCESS:
		case GET_USER_MEDICAL_RECOMMENDATION_SUCCESS:
		case GET_USER_SIGNATURE_SUCCESS:
		case CANCEL_ORDER_SUCCESS:
		case SET_AEROPAY_REFERENCE_UUID_FOR_ORDER_SUCCESS:
		case LOG_AEROPAY_TRANSACTION_SUCCESS:
		case ORDER_HISTORY_SUCCESS:
		case GET_ALL_DSPR_SERVICING_ZIP_CODE_SUCCESS:
			return appendAndUpdateEntitiesFromResponse(state, responseEntities);
		// case SET_IS_FULL_MENU_SHOWN_TO_USER:
		//     const previousValueForIsFullMenuShown = state.isFullMenuShown
		//     state = {...state, isFullMenuShown: !previousValueForIsFullMenuShown}
		//     return state;
		// case SET_IS_FULL_MENU_SHOWN_TO_USER_TO_FALSE:
		//     return { ...state, isFullMenuShown: false }
		// case SET_CURRENT_MENU_TYPE:
		//     if (responseEntities.currentMenuType) {
		//         state = {...state, currentMenuType: responseEntities.currentMenuType}
		//     }
		//     return state;
		case HIDE_FULL_MENU_DELIVERY_MODAL_FROM_USER:
			return { ...state, hasUserSeenFullMenuDeliveryModal: true };
		default:
			return state;
	}
};
