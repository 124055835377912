import React, { useState } from 'react'
import { Provider } from 'react-redux'
// import routes from '../routes'
// import DevTools from './DevTools'
import { Router } from 'react-router-dom'
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

// import App from './App'
import App from './App'
import EntryPath from '../context/entryPath';

const debug = true;
// const debug = false;

ReactGA.initialize('UA-55314314-2', {debug});

const Root = ({ store, history}) => {
    
    return (
    <Provider store={store}>
        <Router history={history}>
            <EntryPath value={window.location.pathname}>
                <App />
            </EntryPath>
            
        </Router>
    </Provider>
)};

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default Root
// process.env.NODE_ENV !== 'production' && <DevTools />
