import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardHeader, CardContent, List, ListItem, ListItemText, Dialog, Button, Snackbar, Divider, withStyles, Typography } from '@material-ui/core';
import { Link, withRouter} from 'react-router-dom';
import SignaturePad from 'react-signature-pad';
import {
    updateLoggedInUserInfo, setUserDefaultAddress, getUserMedicalRecommendation, getUserIdDocument, uploadUserDocument, uploadUserSignature,
    USER_ID_DOCUMENT, USER_MEDICAL_RECOMMENDATION, getAllDSPRServicingZipCode, SET_USER_DEFAULT_ADDRESS_SUCCESS
} from '../actions/userActions';
import { logException } from '../actions/apiUIHelperActions';
import { removeStoredDsprs } from '../actions/menuActions';

import { getLoggedInUser } from '../selectors/userSelectors';
import { getUserMedicalRecommendationFromState, getUserIdDocumentFromState, getUserSignatureFromState } from '../selectors/userDocumentsSelector';

import BasicInformationForm from '../containers/BasicInformationForm';
import DefaultAddressForm from './AddressForm';
import UserDocumentUploadForm from '../containers/UserDocumentUploadForm';
import { getIsRecreationalMarket } from "../selectors/menuSelector";

import { parseDateEndOfDay } from '../util/util';
import { getUserDocumentUrl } from '../middleware/api';
import LoadingSpinner from '../components/LoadingSpinner'
import UserDocument from '../components/UserDocument';

const styles = {
    profileSection: {
        textAlign: 'center',
        paddingTop: 16,
        height: "100% !important"
    },
    documentsSection: {
        width: "100%",
        display: "flex",
        flexDirection: "row"
    },
    document: {
        width: "50%",
        display: "flex",
        flexDirection: "column"
    },
    fullWidthSection: {
        width: "calc(100% - 32px)",
        margin: 16,
        marginTop: 0,
        textAlign: "left"
        
    },
    fullWidthCard: {
        marginTop: 16,
        textAlign: "center"
    }
}
class UserProfile extends Component {
    autoHideDuration = 3000;

    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            uploadingId: false,
            uploadingMedRec: false,
            snackBarAutoHideDuration: this.autoHideDuration,
            snackBarOpen: false,
            snackBarMessage: '',
            settingDefaultAddress: false,
            uploadSignatureDisabled: false,
            showUploadSignature: true,
            height: null
        };
        // this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.updateLoggedInUserInfo()
            .then(() => this.setState({ showUploadSignature: !this.props.userSignature }));

        this.props.getUserIdDocument();
        this.props.getUserMedicalRecommendation();

    }

    // resize() {
    //     const profileNode = ReactDOM.findDOMNode(this.profileCont);
    //     if (profileNode !== null) {
    //         const diff = profileNode.offsetHeight - profileNode.clientHeight;
    //         //console.log(profileNode.style.height);
    //         //console.log((window.innerHeight - profileNode.offsetTop + diff) + 'px');
    //         if (profileNode.style.height !== ((window.innerHeight - profileNode.offsetTop + diff) + 'px')) {
    //             //console.log("DDOOOO");
    //             profileNode.style.height = (window.innerHeight - profileNode.offsetTop + diff) + 'px';
    //             profileNode.style.width = '100%';
    //         }
    //     }

    // }


    handleDefaultAddressSubmit = (defaultAddress) => {
        this.setState({ settingDefaultAddress: true });
        this.props.setUserDefaultAddress(defaultAddress).then((response)=>{
            if(response.type=== SET_USER_DEFAULT_ADDRESS_SUCCESS) {
                this.setState({
                    settingDefaultAddress: false,
                    snackBarOpen: true,
                    snackBarMessage: 'Default Address Updated!'
                });
            } else {
                this.setState({
                    settingDefaultAddress: false,
                    snackBarOpen: true,
                    snackBarMessage: 'An error occurred, please try again'
                });
            }
        });
        this.props.removeStoredDsprs();
        
    };

    handleBasicInformationSubmit = (values) => {
        console.log(values);
    };

    handleSnackBarClose = () => {
        this.setState({
            snackBarOpen: false,
            snackBarMessage: ''
        });
    };

    handleUploadUserIdDocument = (formValues) => {
        if (!formValues.imageFile) {
            alert("Image was not selected properly. Please contact support@grasspit.com for help.");
            logException("Image was not selected properly", { formValues, state: this.props.state });
        }
        else {
            const imageFile = formValues.imageFile[0];
            const { loggedInUser } = this.props;
            this.setState({ uploading: true, uploadingId: true });
            this.props.uploadUserDocument(loggedInUser.id, USER_ID_DOCUMENT, imageFile)
                .then((response) => {
                    if (response && response.error) {
                        alert(response.error);
                        this.setState({ uploading: false, uploadingId: false });
                    }
                    else {
                        // this.handleUserDocumentFormClose();
                        this.setState({ uploading: false, uploadingId: false });
                    }
                }, (error) => {
                    const errorMessage = error.message ? error.message : error.error;
                    alert(errorMessage);
                    this.setState({ uploading: false, uploadingId: false });
                });
        }
    };

    handleUploadDoctorRecommendation = (formValues) => {
        const imageFile = formValues.imageFile[0];
        const { loggedInUser } = this.props;
        this.setState({ uploading: true, uploadingMedRec: true });
        this.props.uploadUserDocument(loggedInUser.id, USER_MEDICAL_RECOMMENDATION, imageFile)
            .then((response) => {
                if (response.error) {
                    alert(response.error);
                    this.setState({ uploading: false, uploadingMedRec: false });
                }
                else {
                    // this.handleUserDocumentFormClose();
                    this.setState({ uploading: false, uploadingMedRec: false });
                }
            }, (error) => {
                const errorMessage = error.message ? error.message : error.error;
                alert(errorMessage);
                this.setState({ uploading: false, uploadingMedRec: false });
            });
    };

    handleUploadSignature = () => {
        this.setState({ uploadSignatureDisabled: true });
        this.props.uploadUserSignature(this.refs.mySignature.toDataURL())
            .then(() => {
                this.setState({ uploadSignatureDisabled: false, showUploadSignature: false });
                this.props.updateLoggedInUserInfo();
            });
    };

    displayUploadNewSignature = () => {
        this.setState({ showUploadSignature: true });
    };

    render() {
        const { idDocument, medicalRecommendation, classes } = this.props;

        const documentUploadDialog = (title, formOpenHandler, formSubmitHandler) =>
            <Dialog
                title={title}
                open={formOpenHandler}
                onClose={this.handleUserDocumentFormClose}
                modal={true}
            >
                {!this.state.uploading && <UserDocumentUploadForm onSubmit={formSubmitHandler}
                    showSubmitButton={true}
                    submitLabel="Upload New"
                    onCancel={() => this.handleUserDocumentFormClose()}
                    showBackButton={true}
                />}
                {this.state.uploading && <div>Uploading...</div>}
            </Dialog>;

        let requiresAction = false;
        let waitingOnVerification = false;
        let actionsRequired = [];
        let toBeVerified = [];
        if (!this.props.idDocument) {
            requiresAction = true;
            actionsRequired.push({
                string: "Please upload a current Driver's License or State ID.",
                action: this.handleUserIdDocumentFormOpen
            });
        }
        else if (this.props.idDocument && !this.props.idDocument.verified) {
            if(this.props.idDocument.visible) {
                waitingOnVerification = true;
                toBeVerified.push("ID Document");
            } else {
                requiresAction = true;
                actionsRequired.push({
                    string: "There was something wrong with your Driver's License/State ID. Please try re-uploading a new one",
                    action: this.handleUserIdDocumentFormOpen
                })
            }
        }
        else if (this.props.idDocument && this.props.idDocument && this.props.idDocument.expirationDate && parseDateEndOfDay(this.props.idDocument.expirationDate) < new Date()) {
            requiresAction = true;
            actionsRequired.push({
                string: "Your Driver's License/State ID has expired. Please upload a current one.",
                action: this.handleUserIdDocumentFormOpen
            });
        }
        // if(!this.props.medicalRecommendation) {
        //     requiresAction = true;
        //     actionsRequired.push({string: "Please upload a current Medical Recommendation/Card.",
        //         action: this.handleUserMedicalRecommendationFormOpen});
        // }
        if (this.props.medicalRecommendation && !this.props.medicalRecommendation.verified) {
            if(this.props.medicalRecommendation.visible) {
                waitingOnVerification = true;
                toBeVerified.push("Medical Recommendation");
            } else {
                requiresAction = true;
                actionsRequired.push({
                    string: "There was something wrong with your Medical Recommendation. Please try re-uploading",
                    action: this.handleUserMedicalRecommendationFormOpen
                })
            }
            
        }
        else if (this.props.medicalRecommendation && this.props.medicalRecommendation.expirationDate && parseDateEndOfDay(this.props.medicalRecommendation.expirationDate) < new Date()) {
            requiresAction = true;
            actionsRequired.push({
                string: "Your Medical Recommendation has expired. Please upload a current one.",
                action: this.handleUserMedicalRecommendationFormOpen
            });
        }

        /* Delete - not used anywhere
        const medRecExpiryDate = this.props.medicalRecommendation && this.props.medicalRecommendation.expirationDate ? parseDateEndOfDay(this.props.medicalRecommendation.expirationDate) : null;
        const medRecExpirationDays = Math.floor((medRecExpiryDate - new Date()) / (1000 * 60 * 60 * 24));
        const idCardExpiryDate = this.props.idDocument && this.props.idDocument.expirationDate ? parseDateEndOfDay(this.props.idDocument.expirationDate) : null;
        const idCardExpirationDays = Math.floor((idCardExpiryDate - new Date()) / (1000 * 60 * 60 * 24));
        */
        /// TODO: const isRecreationalMarket = this.props.isRecreationalMarket;

        return this.props.loggedInUser ?
            <div className={classes.profileSection}
                ref={(profileCont) => { this.profileCont = profileCont; }}
            >
                <div className={classes.documentsSection}>
                    <div className={classes.document}>
                        <Typography variant='body1'><strong>ID. Document</strong></Typography>
                        <UserDocument
                            document={idDocument}
                            documentType={'id'}
                        />
                    </div>
                    <div className={classes.document}>
                    <Typography variant='body1'><strong>Medical Rec.</strong></Typography>
                        <UserDocument
                            document={medicalRecommendation}
                            documentType={'med'}
                        />
                    </div>
                </div>
                {requiresAction &&
                    <div className={classes.fullWidthSection}>
                        <Typography variant='body1'><strong>Actions Required</strong></Typography>
                        <Card className={classes.fullWidthCard}>
                            <CardContent>
                                <List>
                                    {actionsRequired.map((action, index) => <ListItem onClick={action.action} key={actionsRequired.indexOf(action)}>{index+1}. {action.string}</ListItem>)}
                                </List>
                            </CardContent>
                        </Card>
                    </div>
                    
                }
                {
                    waitingOnVerification &&
                    <div className={classes.fullWidthSection}>
                        <Card className={classes.fullWidthCard}>
                        <CardHeader title="The Grassp team is verifying the following documents. Please check again soon!" style={{textAlign: "center"}}/>
                        <CardContent>
                            <List>
                                {toBeVerified.map((doc, index) => <ListItem button style={{ textAlign: "left" }} key={toBeVerified.indexOf(doc)}><ListItemText>{index + 1}. {doc}</ListItemText></ListItem>)}
                            </List>
                        </CardContent>
                    </Card>
                    </div>
                    
                }
                <div className={classes.fullWidthSection}>
                    <Typography variant='body1'><strong>Basic Information</strong></Typography>
                    <Card className={classes.fullWidthCard}>
                        <CardContent>
                            <BasicInformationForm
                                onSubmit={this.handleBasicInformationSubmit}
                                user={this.props.loggedInUser}
                            />
                            <div style={{ width: 250, marginLeft: 'auto', marginRight: 'auto', marginTop: 16 }}>
                                <Link to="/change-password" style={{ textAlign: 'left', display: 'block', fontSize: '.875rem' }}>
                                    Change your password
                                </Link>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                <div className={classes.fullWidthSection}>
                    <Typography variant='body1'><strong>Delivery Address</strong></Typography>
                    <Card className={classes.fullWidthCard}>
                        <CardContent>
                            <DefaultAddressForm
                            onSubmit={this.handleDefaultAddressSubmit}
                            address={this.props.loggedInUser && this.props.loggedInUser.defaultAddress}
                            buttonEnabled={!this.state.settingDefaultAddress}
                            showButton={true}
                        />
                        </CardContent>
                    </Card>
                </div>
                <div className={classes.fullWidthSection}>
                <Typography variant='body1'><strong>Signature</strong></Typography>
                <Card className={classes.fullWidthCard}>
                    <CardHeader
                        subheader="In order to purchase marijuana in a recreational market, you must provide a signature. It will only be used and attached to your delivery receipt with your approval."
                    />
                    <CardContent>
                        {!this.state.showUploadSignature &&
                            <div style={{ textAlign: 'center' }}>
                                <img style={{ maxWidth: '85vw', border: '1px solid rgba(0, 0, 0, 0.12)' }} alt="presentation" src={getUserDocumentUrl("signature", this.props.loggedInUser.id)} />
                                <div><Button size={'small'} color="primary" style={{width: 200, marginTop: 16}} variant="contained" onClick={this.displayUploadNewSignature}>Upload New Signature</Button></div>
                            </div>
                        }
                        {this.state.showUploadSignature &&
                            <div>
                                <div><SignaturePad clearButton="true" ref="mySignature" /></div>
                                <div><Button color="primary" variant="contained" onClick={this.handleUploadSignature} disabled={this.state.uploadSignatureDisabled}>Submit</Button></div>
                            </div>
                        }
                    </CardContent>
                </Card>
                </div>
                {/* <Card className="basic-info" style={{ boxShadow: 'none' }}>
                    <CardHeader title="Basic Information" />
                    <CardContent>
                        <Link to="/change-password">Change your password</Link>
                        <BasicInformationForm
                            onSubmit={this.handleBasicInformationSubmit}
                            user={this.props.loggedInUser}
                        />
                    </CardContent>
                </Card> */}
                {/* <Divider />
                <Card className="default-address" style={{ boxShadow: 'none' }}>
                    <CardHeader title="Delivery Address" />
                    <CardContent>
                        <DefaultAddressForm
                            onSubmit={this.handleDefaultAddressSubmit}
                            address={this.props.loggedInUser && this.props.loggedInUser.defaultAddress}
                            buttonEnabled={!this.state.settingDefaultAddress}
                            showButton={true}
                        />
                    </CardContent>
                </Card> */}
                <Divider />
                {/* <Card className="id-verif" style={{ boxShadow: 'none' }}>
                    <CardHeader title="Driver's License / State ID" />
                    <CardContent>
                        <Fragment>
                            {idCardExpired && <p className="invalid-document">EXPIRED</p>}
                            {idDocument && idDocument.verified && <div>
                                {!idCardExpired && <p className="approved-document">APPROVED</p>}
                                <span className="block-span">ID number: {idDocument.idNumber}</span>
                                {idCardExpiryDate && idCardExpirationDays < 16 && !idCardExpired &&
                                    <p className="invalid-document">EXPIRING IN {idCardExpirationDays} {idCardExpirationDays === 1 ? 'DAY' : 'DAYS'}</p>}
                                {idCardExpiryDate && <span className="block-span date">Expires: {idCardExpiryDate.toLocaleString("en-us", { month: "long" })} {idCardExpiryDate.getDate()}, {idCardExpiryDate.getFullYear()}</span>}
                            </div>}
                            {idDocument && !idDocument.verified && <span className="block-span" style={{ color: '#ffffff' }}>ID Uploaded Successfully! The Grassp team is verifying it.</span>}
                        </Fragment>
                        {!idDocument && <p className="invalid-document">UPLOAD</p>}
                        {this.state.uploadingId ? <documentUploadDialog /> : <UserDocumentUploadForm onSubmit={this.handleUploadUserIdDocument}
                            formId="ProfileIdUploadForm"
                            submitLabel="Upload"
                            buttonLabel={!idDocument ? undefined : 'Update the existing document'}
                            showSubmitButton={false}
                            showBackButton={false} />}
                    </CardContent>
                </Card>
                <Divider />
                <Card className="medical-recommend" style={{ boxShadow: 'none' }}>
                    <CardHeader title="Medical Recommendation / Card"
                    />
                    <CardContent>
                        {medicalRecommendation && !medRecExpired && medicalRecommendation.verified && <p className="approved-document">APPROVED</p>}
                        {!medicalRecommendation && <p className="invalid-document">UPLOAD</p>}
                        {this.state.uploadingMedRec ? <documentUploadDialog /> : <UserDocumentUploadForm onSubmit={this.handleUploadDoctorRecommendation}
                            formId="ProfileMedRecUploadForm"
                            submitLabel="Upload"
                            buttonLabel={!medicalRecommendation ? undefined : 'Update the existing document'}
                            showSubmitButton={false}
                            showBackButton={false} />}
                        {medRecExpired && <p className="invalid-document">EXPIRED</p>}
                        {medRecExpiryDate && medRecExpirationDays < 16 && !medRecExpired &&
                            <p className="invalid-document">EXPIRING IN {medRecExpirationDays} {medRecExpirationDays === 1 ? 'DAY' : 'DAYS'}</p>}
                        {medRecExpiryDate && <span className="block-span date">Expires: {medRecExpiryDate.toLocaleString("en-us", { month: "long" })} {medRecExpiryDate.getDate()}, {medRecExpiryDate.getFullYear()}</span>}
                        {medicalRecommendation && !medicalRecommendation.verified && <div style={{ color: '#ffffff' }}>Medical Recommendation Uploaded Successfully! The Grassp team is verifying it.</div>}
                    </CardContent>
                </Card>
                <Divider /> */}
                {/* <Card className="signature" style={{ boxShadow: 'none' }}>
                    <CardHeader title="Signature"
                        subheader="In order to purchase marijuana in a recreational market, you must provide a signature. It will only be used and attached to your delivery receipt with your approval."
                    />
                    <CardContent>
                        {!this.state.showUploadSignature &&
                            <div style={{ textAlign: 'center' }}>
                                <img style={{ maxWidth: '85vw', border: '1px solid rgba(0, 0, 0, 0.12)' }} alt="presentation" src={getUserDocumentUrl("signature", this.props.loggedInUser.id)} />
                                <div><Button color="primary" variant="contained" onClick={this.displayUploadNewSignature}>Upload New Signature</Button></div>
                            </div>
                        }
                        {this.state.showUploadSignature &&
                            <div>
                                <div><SignaturePad clearButton="true" ref="mySignature" /></div>
                                <div><Button color="primary" variant="contained" onClick={this.handleUploadSignature} disabled={this.state.uploadSignatureDisabled}>Submit</Button></div>
                            </div>
                        }
                    </CardContent>
                </Card> */}
                {documentUploadDialog("New ID Document", this.state.userIdDocumentFormOpen, this.handleUploadUserIdDocument)}
                {documentUploadDialog("New Medical Recommendation", this.state.userMedicalRecommendationFormOpen, this.handleUploadDoctorRecommendation)}
                <Snackbar
                    open={this.state.snackBarOpen}
                    message={this.state.snackBarMessage}
                    autoHideDuration={this.state.snackBarAutoHideDuration}
                    onClose={this.handleSnackBarClose}
                    className="cartSnackBar"
                />
            </div>
            :
            <LoadingSpinner />
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        loggedInUser: getLoggedInUser(state),
        idDocument: getUserIdDocumentFromState(state),
        medicalRecommendation: getUserMedicalRecommendationFromState(state),
        isRecreationalMarket: getIsRecreationalMarket(state),
        userSignature: getUserSignatureFromState(state),
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setUserDefaultAddress,
        getUserMedicalRecommendation,
        getUserIdDocument,
        getAllDSPRServicingZipCode,
        uploadUserDocument,
        uploadUserSignature,
        updateLoggedInUserInfo,
        removeStoredDsprs,
    }, dispatch);
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile)));
