
import React, { SetStateAction, Dispatch, useEffect, useMemo, useState } from "react";
export interface EntryPathInterface {
    entryPath: string | null;
    setEntryPath: Dispatch<SetStateAction<string>>;
    authRedirectEntryPoint: string | null;
    setAuthRedirectEntryPoint: Dispatch<SetStateAction<string>>;
}
export const EntryPathContext = React.createContext<EntryPathInterface | null>({
    entryPath: null, setEntryPath: null, authRedirectEntryPoint: null, setAuthRedirectEntryPoint: null
});
// https://github.com/xcarpentier/rn-tourguide/issues/17
// Voltron369 Using context, no redux
export default ({ children, value }) => {

    const [entryPath, setEntryPath] = useState<string>(value);
    const [authRedirectEntryPoint, setAuthRedirectEntryPoint] = useState<string>(value);

    console.log("ENTRYPOINT entryPath", entryPath);


    return (
        <EntryPathContext.Provider value={
            {
                entryPath, setEntryPath, authRedirectEntryPoint, setAuthRedirectEntryPoint
            }
        }>
            {children}
        </EntryPathContext.Provider>
    )
}