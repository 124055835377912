import React from 'react';
import { Place as PlaceIcon } from '@material-ui/icons'
import { Card, ButtonBase } from '@material-ui/core';

import { API_HOST } from '../middleware/api';
import { MENU_LOCATION_TYPES } from './SetLocationMenu';

interface MenuSwitcherProps {
    dsprs: any[];
    menuType: string;
    currentZipCode: string;

    handleDsprClick: (dsprId: any) => any;
    getImageLocationForDSPR: (dsprId: any) => string;

    Notifications: JSX.Element;
    Errors: JSX.Element;
    LocationMenu: JSX.Element;
}

const MenuSwitcher: React.FC<MenuSwitcherProps> = props => {
    const { dsprs, menuType, currentZipCode, handleDsprClick, getImageLocationForDSPR,
        Notifications, Errors, LocationMenu } = props;

    return <div className="menu-switcher-container">
        <div className="menu-switcher-location-display">
            <PlaceIcon />
            <p className="zipCode">{currentZipCode}</p>
            <p>{menuType === MENU_LOCATION_TYPES.CURRENT_LOCATION ? 'Current Location' :
                menuType === MENU_LOCATION_TYPES.DELIVERY_ADDRESS ? 'Delivery Address' : 'Default Menu'}</p>
            {LocationMenu}
        </div>
        <div className="menu-switcher-call-to-action">
            <p>Choose a dispensary to see it’s menu.  To switch dispensaries, click settings in upper left hand corner, then click “show providers”.</p>
        </div>
        {Notifications}
        {Errors}
        {dsprs && dsprs.map(dspr => {
            const imageLocation = getImageLocationForDSPR(dspr.id);
            const name = dspr.displayName ? dspr.displayName : dspr.name
            return (
                <Card
                    key={dspr.id}
                    className="menu-switcher-dspr-card"
                    onClick={() => handleDsprClick(dspr.id)}
                // TODO: add most recent DSPR indicator (localStorage)
                >
                    <ButtonBase>
                        {imageLocation && <img src={API_HOST + imageLocation} alt={name} />}
                        <p>{name}</p>
                    </ButtonBase>
                </Card>
            )
        })}
    </div>
}

export default MenuSwitcher;