import { CALL_API, Schemas } from '../middleware/api';
import { logException } from './apiUIHelperActions';

export const LOCAL_STORAGE_MOST_RECENT_DSPR_KEY = 'mostRecentDSPR';
export const LOCAL_STORAGE_APP_REFRESH_DATE_KEY = 'lastAppRefresh';

export const LOCAL_STORAGE_ZIP_CODE_KEY = 'defaultZipCode';
export const LOCAL_STORAGE_ENTRYPOINT_HISTORY_PUSH = 'historyRedirectHash';

export const NO_SERVICE_ERROR_STRING = "No DSPR is operating in zip code";
export const DSPR_NOT_OPERATING_IN_ZIP_ERROR_STRING = "is not operating in zip code";   
export const GOOGLE_ZIPCODE_ERROR = "'Menu' parameter 'zipcode' is invalid";

export const GET_MENU_BY_LATLONG = 'GET_MENU_BY_LATLONG';
export const GET_MENU_BY_LATLONG_SUCCESS = 'GET_MENU_BY_LATLONG_SUCCESS';
export const GET_MENU_BY_LATLONG_FAILURE = 'GET_MENU_BY_LATLONG_FAILURE';

export const GET_DSPR = 'GET_DSPR';
export const GET_DSPR_SUCCESS = 'GET_DSPR_SUCCESS';
export const GET_DSPR_FAILURE = 'GET_DSPR_FAILURE';

const dspr = (dsprId) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_DSPR, GET_DSPR_SUCCESS, GET_DSPR_FAILURE],
            endPoint: `dspr/${dsprId}`,
            schema: Schemas.DSPR
        }
    }
};

export const getDSPR = (dsprId) => (dispatch) => {
    return dispatch(dspr(dsprId));
};

export const CLEAR_DSPRS = 'CLEAR_DSPRS';

const clearAllDsprs = {
    type: CLEAR_DSPRS,
}

export const removeStoredDsprs = () => dispatch => dispatch(clearAllDsprs);

const menuGetterByLatLong = (latitude, longitude, dsprId, isFullMenuShown) => {
    const queryParamsMap = dsprId ? { latitude, longitude, dspr_id: dsprId, is_full_menu: isFullMenuShown } : { latitude, longitude };
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_MENU_BY_LATLONG, GET_MENU_BY_LATLONG_SUCCESS, GET_MENU_BY_LATLONG_FAILURE],
            endPoint: 'menu',
            queryParamsMap,
            schema: Schemas.MENU
        }
    }
};

export const getMenuByLatLong = (latitude, longitude, dsprId, isFullMenuShown) => (dispatch, getState) => {
    return dispatch(menuGetterByLatLong(latitude, longitude, dsprId, isFullMenuShown));
};

export const GET_MENU_BY_ZIPCODE = 'GET_MENU_BY_ZIPCODE';
export const GET_MENU_BY_ZIPCODE_SUCCESS = 'GET_MENU_BY_ZIPCODE_SUCCESS';
export const GET_MENU_BY_ZIPCODE_FAILURE = 'GET_MENU_BY_ZIPCODE_FAILURE';

const menuGetterByZipCode = (zipCode, dsprId, isFullMenuShown) => {
    const queryParamsMap = dsprId ? { zip_code: zipCode, dspr_id: dsprId, is_full_menu: isFullMenuShown } : { zip_code: zipCode };
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_MENU_BY_ZIPCODE, GET_MENU_BY_ZIPCODE_SUCCESS, GET_MENU_BY_ZIPCODE_FAILURE],
            endPoint: 'menu',
            queryParamsMap,
            schema: Schemas.MENU
        }
    }
};

export const getMenuByZipCode = (zipCode, dsprId, isFullMenuShown) => (dispatch, getState) => {
    if(zipCode.length !== 5) {
        const curLength = zipCode.length;
        const diff = 5 - curLength;
        for(let i = 0; i < diff; i++) {
            zipCode = "0" + zipCode;
        }
    }
    return dispatch(menuGetterByZipCode(zipCode, dsprId, isFullMenuShown));
};

export const GET_MENU_BY_DEFAULT_ADDRESS = 'GET_MENU_BY_DEFAULT_ADDRESS';
export const GET_MENU_BY_DEFAULT_ADDRESS_SUCCESS = 'GET_MENU_BY_DEFAULT_ADDRESS_SUCCESS';
export const GET_MENU_BY_DEFAULT_ADDRESS_FAILURE = 'GET_MENU_BY_DEFAULT_ADDRESS_FAILURE';

const menuGetterByDefaultAddress = (zipCode, street, dsprId, isFullMenuShown) => {
    const queryParamsMap = dsprId ? { zip_code: zipCode, street, dspr_id: dsprId, is_full_menu: isFullMenuShown } : { zip_code: zipCode, street };
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_MENU_BY_DEFAULT_ADDRESS, GET_MENU_BY_DEFAULT_ADDRESS_SUCCESS, GET_MENU_BY_DEFAULT_ADDRESS_FAILURE],
            endPoint: 'menu',
            queryParamsMap,
            schema: Schemas.MENU
        }
    }
};

export const getMenuByDefaultAddress = (zipCode, street, dsprId, isFullMenuShown) => (dispatch, getState) => {
    if(zipCode.length !== 5) {
        const curLength = zipCode.length;
        const diff = 5 - curLength;
        for(let i = 0; i < diff; i++) {
            zipCode = "0" + zipCode;
        }
    }
    return dispatch(menuGetterByDefaultAddress(zipCode, street, dsprId, isFullMenuShown));
};

export const UPDATE_MOST_RECENT_DSPR_SUCCESS = "UPDATE_MOST_RECENT_DSPR_SUCCESS"
export const UPDATE_MOST_RECENT_DSPR_FAILURE = "UPDATE_MOST_RECENT_DSPR_FAILURE"

export const getMostRecentDSPR = () => {
    return localStorage.getItem(LOCAL_STORAGE_MOST_RECENT_DSPR_KEY)
}

export const setMostRecentDSPR = (preferredDSPId) => {
    try {
        localStorage.setItem(LOCAL_STORAGE_MOST_RECENT_DSPR_KEY, preferredDSPId)
        return { type: UPDATE_MOST_RECENT_DSPR_SUCCESS }
    } catch (error) {
        logException("Caught LocalStorage Exception", { error, LOCAL_STORAGE_MOST_RECENT_DSPR_KEY, preferredDSPId })
        return { type: UPDATE_MOST_RECENT_DSPR_FAILURE }
    }
}

export const getLastAppRefresh = () => {
    return localStorage.getItem(LOCAL_STORAGE_APP_REFRESH_DATE_KEY)
}

export const UPDATE_APP_REFRESH_DATE_SUCCESS = 'UPDATE_APP_REFRESH_DATE_SUCCESS';
export const UPDATE_APP_REFRESH_DATE_FAILURE = 'UPDATE_APP_REFRESH_DATE_FAILURE';
export const setLastAppRefresh = () => {
    const refreshDate = new Date().toISOString()
    try {
        localStorage.setItem(LOCAL_STORAGE_APP_REFRESH_DATE_KEY, refreshDate)
        return { type: UPDATE_APP_REFRESH_DATE_SUCCESS }
    } catch (error) {
        logException("Caught LocalStorage Exception", { error, LOCAL_STORAGE_APP_REFRESH_DATE_KEY, refreshDate })
        return { type: UPDATE_APP_REFRESH_DATE_FAILURE }
    }
}
export const manualSetLastAppRefresh = (refreshDate) => {
    try {
        localStorage.setItem(LOCAL_STORAGE_APP_REFRESH_DATE_KEY, refreshDate)
        return { type: UPDATE_APP_REFRESH_DATE_SUCCESS }
    } catch (error) {
        logException("Caught LocalStorage Exception", { error, LOCAL_STORAGE_APP_REFRESH_DATE_KEY, refreshDate })
        return { type: UPDATE_APP_REFRESH_DATE_FAILURE }
    }
}

export const GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR = "GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR";
export const GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_SUCCESS = "GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_SUCCESS";
export const GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_FAILURE = "GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_FAILURE";

const getProductCategoryPromotions = ( dsprId ) => {
    return {
        [CALL_API]: {
            httpAction: 'GET',
            types: [GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR, GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_SUCCESS, GET_PRODUCT_CATEGORY_PROMOTIONS_FOR_DSPR_FAILURE],
            endPoint: `dspr/product-category-promotions/${dsprId}`,
            schema: Schemas.PRODUCT_CATEGORY_PROMOTION_ARRAY
        }
    }
}
export const getProductCategoryPromotionsForDSPR = ( dsprId ) => ( dispatch, getState ) => {  
    return dispatch(getProductCategoryPromotions(dsprId))
}

export const SET_IS_FULL_MENU_SHOWN_TO_USER = 'SET_IS_FULL_MENU_SHOWN_TO_USER';

export const setIsFullMenuShownToUser = (isFullMenuShown) => {
    return { type: SET_IS_FULL_MENU_SHOWN_TO_USER, response: {isFullMenuShown} };
}

export const SET_CURRENT_MENU_TYPE ='SET_CURRENT_MENU_TYPE';

export const setCurrentMenuType = (currentMenuType) => {
    return {
        type: SET_CURRENT_MENU_TYPE,
        response: {currentMenuType}
    };
}

export const HIDE_FULL_MENU_DELIVERY_MODAL_FROM_USER = 'HIDE_FULL_MENU_DELIVERY_MODAL_FROM_USER';

export const hideFullMenuDeliveryModalFromUser = () => {
    return { type: HIDE_FULL_MENU_DELIVERY_MODAL_FROM_USER };
}

export const SET_SORT_PARAMETER = "SET_SORT_PARAMETER";

export const setSortParameter = (sortParameter) => {
    return { type: SET_SORT_PARAMETER, sortParameter };
}

export const SET_SORT_METHOD = "SET_SORT_METHOD";

export const setSortMethod = (sortMethod) => {
    return { type: SET_SORT_METHOD, sortMethod };
}

export const SET_MENU_FILTER_ITEM = "SET_MENU_FILTER_ITEM";

export const setMenuFilter = (filterType, menuFilterItem) => {
    return { type: SET_MENU_FILTER_ITEM, filterType, menuFilterItem };
}
export const CLEAR_MENU_FILTERS = "CLEAR_MENU_FILTERS";

export const clearFiltersForMenu = () => {
    return { type: CLEAR_MENU_FILTERS };
}

export const SET_MENU_SEARCH = "SET_MENU_SEARCH";

export const setMenuSearch = (search) => {
    return { type: SET_MENU_SEARCH, searchValue: search }
}

export const SET_MENU_SUBHEADER_VISIBLE = "SET_MENU_SUBHEADER_VISIBLE";

export const setShowSubheader = (value) => {
    return { type: SET_MENU_SUBHEADER_VISIBLE, value}
}

export const SET_CURRENT_ZIPCODE = "SET_CURRENT_ZIPCODE";
export const setMenuZipCode = (zipCode, skipSet = false) => {
    try {
        if(!skipSet) localStorage.setItem(LOCAL_STORAGE_ZIP_CODE_KEY, zipCode)
        return { type: SET_CURRENT_ZIPCODE, zipCode }
    } catch(error) {
        logException("Caught LocalStorage Exception", { error, LOCAL_STORAGE_ZIP_CODE_KEY, zipCode })
        return { type: SET_CURRENT_ZIPCODE, zipCode }
    }
}

export const getDefaultZipCode = () => {
    return localStorage.getItem(LOCAL_STORAGE_ZIP_CODE_KEY)
}


