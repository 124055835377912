import {
    ADD_ORDER_DETAIL_TO_CART,
    REMOVE_ORDER_DETAIL_FROM_CART,
    CHECKOUT,
    CHECKOUT_FAILURE,
    CLEAR_CART
} from '../actions/cartActions';
import {LOGOUT} from '../actions/oauthActions';

export const initialState = {
    orderDetails: []
};

const addedOrderDetails = (state = initialState.orderDetails, action) => {
    switch (action.type) {
        case ADD_ORDER_DETAIL_TO_CART:
            state = [...state.filter(orderDetail => orderDetail.product.id !== action.orderDetail.product.id), action.orderDetail];
            return state;
        default:
            return state;
    }
};

const removedOrderDetails = (state = initialState.orderDetails, action) => {
    switch(action.type) {
        case REMOVE_ORDER_DETAIL_FROM_CART:
            return [...state.filter(orderDetail => orderDetail.product.id !== action.productId)];
        default:
            return state;
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
        case CHECKOUT:
        case CLEAR_CART:
            return initialState;
        case CHECKOUT_FAILURE:
            return action.cart;
        case REMOVE_ORDER_DETAIL_FROM_CART:
            return {
                orderDetails: removedOrderDetails(state.orderDetails, action)
            };
        default:
            return {
                orderDetails: addedOrderDetails(state.orderDetails, action)
            }
    }
}