export const parseDate = (date: string | number | Date): Date => {
    if (!date) return undefined;
    if (date instanceof Date) return date;
    if (typeof date === 'number') return new Date(date);

    const parsed = Date.parse(date);
    if (!isNaN(parsed)) return new Date(parsed);

    // An RFC 822 date string, e.g. "2018-02-06T20:00:00.000+0400", won't work with Safari
    // Safari can parse ISO 8601 date strings, e.g. "2018-02-06T20:00:00.000+04:00" - notice the colon near the end
    if (date && date.length === 28) return new Date(`${date.substring(0, 26)}:${date.substring(26)}`);

    throw new Error(`Invalid Date : ${date}`);
}

/**
 * Parses a date, adds a day to it, and then subtracts 1 second so it is always the very end of the given date. 
 * @param date 
 */
const returnEndOfDay = (date: Date): Date => {
    const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    dateOnly.setDate(dateOnly.getDate() + 1);
    dateOnly.setSeconds(0-1)
    return dateOnly
}

export const parseDateEndOfDay = (date: string | number | Date): Date => {
    if (!date) return undefined;
    if (date instanceof Date) return returnEndOfDay(date);
    if (typeof date === 'number') return returnEndOfDay(new Date(date));

    const parsed = Date.parse(date);
    if (!isNaN(parsed)) return returnEndOfDay(new Date(parsed));

    // An RFC 822 date string, e.g. "2018-02-06T20:00:00.000+0400", won't work with Safari
    // Safari can parse ISO 8601 date strings, e.g. "2018-02-06T20:00:00.000+04:00" - notice the colon near the end
    if (date && date.length === 28) return returnEndOfDay(new Date(`${date.substring(0, 26)}:${date.substring(26)}`));

    throw new Error(`Invalid Date : ${date}`);
}