import React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";

const DeliveryHours: React.FC<{}> = () => {
  return (
    <Card elevation={0}>
      <CardHeader
        style={{ textAlign: "center", paddingBottom: 0 }}
        title="Delivery Hours"
      />
      <CardContent>
        <section className="delivery-hours">
          <h3>Phoenix, AZ: 9am-9pm</h3>
          {/* <h3>Tuscon, AZ: 10am-8pm</h3> */}
          {/* <h3>CA: Coming Soon</h3> */}
          <h3>Salem, MA: 11am-8pm</h3>
          <p>Same day delivery during operating hours. Orders can be placed online at any time.</p>
        </section>
        <img src="/images/NewMap.png" style={{ width: "100%" }} alt="" />
      </CardContent>
    </Card>
  )
}

export default React.memo(DeliveryHours);