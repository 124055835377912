import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, CardHeader, CardContent} from '@material-ui/core';
import {getLoggedInUser} from "../selectors/userSelectors";

class ReferAFriend extends Component {

    render() {
        const loggedInUser = this.props && this.props.loggedInUser; //TODO need check for null
        const isFirstTimeUser = loggedInUser && loggedInUser.isFirstTimeUser;
        const referrerCode = loggedInUser && loggedInUser.referrerCode;
        const availableReferrerBonus = loggedInUser && loggedInUser.availableReferrerBonus;

        return isFirstTimeUser ? (
            <Card>
                <CardHeader title="Refer A Friend"/>
                <CardContent>
                    You need to place an order first before you can get $20 off by referring a friend!
                </CardContent>
            </Card>
        ) : (
            <Card>
                <CardHeader title="Refer A Friend"/>
                <CardContent>
                    Step 1) Send your friends this link: <span className="span-highlight"><b>https://signup.grassp.it/{referrerCode}</b></span><br/>
                    <br/>
                    Step 2) When a friend joins the site, they will receive a coupon code for $20 off their order.<br/>
                    <br/>
                    Step 3) Once your friend completes their first order, you will receive a coupon code for $20 off your next order!<br/>
                    {availableReferrerBonus ? (
                            <div>
                                <br/>
                                You currently have {availableReferrerBonus} bonus coupons available.<br/>
                                Your coupon code is: <b>THANKYOUBONUS</b><br/>
                            </div>
                        ) : (
                            <div/>
                        )
                    }
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loggedInUser: getLoggedInUser(state),
        state
    };
};

export default connect(mapStateToProps, null)(ReferAFriend);
