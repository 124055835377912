import React, {Component} from 'react';

import {List, ListItem, ListItemText, Divider, Chip} from '@material-ui/core';

import OrderDetailListItem from './OrderDetailListItem';

export default class OrderWithDetailsAndPrices extends Component {
    getOrderDetails() {
        return this.props.order ? this.props.order.calculatedOrderDetails.map(detail => {
            return <OrderDetailListItem
                    key={`${detail.product.id}-${(detail.unit || '0')}`}
                    orderDetail={detail}
                    removeProductFromCart={this.props.removeProductFromCart}
                    showProductQuantitySelector={this.props.showProductQuantitySelector}
                />
            }
)
            : null;
    }


    render() {
        return <List>
                {this.getOrderDetails()}
                <Divider/>
                {this.props.order.coupons && this.props.order.coupons.length !== 0 && 
                    <ListItem className="coupon-flexbox">
                        <ListItemText primary="Coupon(s)"/>
                        <ListItemText style={{padding: "0"}} primary={this.props.order.coupons.map((coupon, index) => {
                            return <Chip key={"coupon-" + coupon.code + "-" + index+1} onDelete={this.props.order.id === 0  ? () => this.props.removeCouponCode(coupon) : undefined} label={coupon.code} clickable>
                            </Chip>
                        })} />
                    </ListItem>
                }
                {this.props.order.discountTotal !== 0 &&
                    <ListItem>
                        <ListItemText primary='Pre Discount Total' secondary={`$${parseFloat(this.props.order.cashTotalPreDiscounts).toFixed(2)}`} />
                    </ListItem>
                }
                {this.props.order.discountTotal !== 0 &&
                    <ListItem>
                        <ListItemText primary='Discount' secondary={`-$${parseFloat(this.props.order.discountTotal).toFixed(2)}`} />
                    </ListItem>
                }
                <ListItem>
                    <ListItemText primary='SubTotal' secondary={`$${parseFloat(this.props.order.cashTotalPreDiscounts - this.props.order.discountTotal).toFixed(2)}`} />
                </ListItem>
                {this.props.order.orderTaxDetails.slice(0).reverse().map((detail, index)=> {
                    return(
                        <ListItem key={`order-detail-${detail.name}-${index}-${(detail.appliedCoupon && detail.appliedCoupon.code) || "none"}`}>
                            <ListItemText primary={`${detail.name} : ${detail.rate}%`} secondary={`$${parseFloat(detail.amount).toFixed(2)}`} />
                        </ListItem>
                    )
                })}
                <ListItem>
                    <ListItemText primary='Delivery Fee' secondary={this.props.order.deliveryFee === 0 ? "free" : `$${parseFloat(this.props.order.deliveryFee).toFixed(2)}`}/>
                </ListItem>
                <ListItem>
                    <ListItemText className='total-price' primary={`Total: $${parseFloat(this.props.order.cashTotal).toFixed(2)}`}/>
                </ListItem>
            </List>
    }
}
