
import merge from 'lodash/merge';
import {
    CLEAR_MENU_FILTERS,
    GET_MENU_BY_DEFAULT_ADDRESS_SUCCESS, 
    GET_MENU_BY_LATLONG_SUCCESS, 
    GET_MENU_BY_ZIPCODE_SUCCESS, 
    HIDE_FULL_MENU_DELIVERY_MODAL_FROM_USER, 
    SET_CURRENT_MENU_TYPE, 
    SET_IS_FULL_MENU_SHOWN_TO_USER,
    SET_MENU_FILTER_ITEM,
    SET_SORT_METHOD,
    SET_SORT_PARAMETER,
    SET_MENU_SEARCH,
    SET_MENU_SUBHEADER_VISIBLE,
    SET_CURRENT_ZIPCODE
} from "../actions/menuActions"
import { SET_INITALIZATION_COMPLETE } from '../actions/oauthActions';
const initialState = { isFullMenuShown: false, currentMenuType: null, hasUserSeenFullMenuDeliveryModal: false, menuClosed: false, stateInspection: false, search:'', filters: {}, sortMethod: 'descending', sortParameter: null, subheaderVisible: false, initializationComplete: false};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_MENU_TYPE:
        case SET_IS_FULL_MENU_SHOWN_TO_USER:
            return merge({}, {...state, ...action.response})
        case HIDE_FULL_MENU_DELIVERY_MODAL_FROM_USER:
            return {...state, hasUserSeenFullMenuDeliveryModal: true}
        case GET_MENU_BY_LATLONG_SUCCESS:
        case GET_MENU_BY_DEFAULT_ADDRESS_SUCCESS:
        case GET_MENU_BY_ZIPCODE_SUCCESS:
            if(!action.response.entities.menu) return {...state}
            const menu = action.response.entities.menu[action.response.result]
            return merge({},{...state, menuClosed: menu.isClosed || false, stateInspection: menu.isUnderStateInspection || false});
        case SET_MENU_SEARCH:
            return {...state, search: action.searchValue}
        case SET_SORT_PARAMETER:
            return {...state, sortParameter: action.sortParameter};
        case SET_SORT_METHOD:
            if (action.sortMethod === 'ascending' || action.sortMethod === 'descending') return { ...state, sortMethod: action.sortMethod};
            return {...state};
        case SET_CURRENT_ZIPCODE:
            return {...state, currentZipCode: action.zipCode};
        case SET_MENU_FILTER_ITEM:
            const currentFilters = state.filters;
            if(currentFilters[action.filterType] === undefined) {
                currentFilters[action.filterType] = [`${action.menuFilterItem}`]
                return merge({}, {...state, filters: currentFilters});
            }
            const index = currentFilters[action.filterType].indexOf(action.menuFilterItem)
            if(index === -1) {
                currentFilters[action.filterType].push(action.menuFilterItem);
                return merge({}, {...state, filters: currentFilters});
            } else {
                currentFilters[action.filterType].splice(index, 1);
                if(currentFilters[action.filterType].length === 0) delete currentFilters[action.filterType];
                return merge({}, {...state, filters: currentFilters});
            }
        case CLEAR_MENU_FILTERS:
            const filters= {};
            return merge({},{...state, filters: filters, sortMethod: "descending", sortParameter: null});
        case SET_MENU_SUBHEADER_VISIBLE:
            return merge({},{...state, subheaderVisible: action.value})
        case SET_INITALIZATION_COMPLETE:
            return merge({},{...state, initializationComplete: true})
        default:
            return {...state}
    }
}