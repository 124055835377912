import React, { Component, useState, useEffect, useRef } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { getDSPRs, isAeroPayDSPR, isNoCashDSPR } from '../selectors/dsprSelector';


import ReactGA from 'react-ga';
import uuid from 'uuid';

import { Card, CardHeader, CardContent, Button } from '@material-ui/core';

import OrderWithDetailsAndPrices from './OrderWithDetailsAndPrices';
import { Redirect } from 'react-router';

interface CurrentOrderProps {
	order: any;
	orderCanceler: (orderId: number) => void;
	setAeroPayReferenceUUID: (uuid: string, orderId) => void;
	logAeroPay: (orderId: number, uuid, total) => void;
	isRecreationalMarket: boolean;
	driver: any;
	loggedInUser: any;
}

const CurrentOrder: React.FC<CurrentOrderProps> = (props) => {

	const { orderCanceler, order, isRecreationalMarket, driver, loggedInUser, logAeroPay, setAeroPayReferenceUUID } = props;
	const [apButton, setAPButton] = useState(null);
	const [referenceUUID, setReferenceUUID] = useState(null);
	const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
	const [completedTransaction, setCompletedTransaction] = useState(false);
	const [lockedSeconds, setLockedSeconds] = useState(0);
	const [dsprId, setDsprId] = useState(null);
	const isAeroPay = useSelector(state => dsprId && isAeroPayDSPR(state, { dsprId }), shallowEqual);
	const isNoCash = useSelector(state => dsprId && isNoCashDSPR(state, { dsprId }), shallowEqual);


	const cancelOrder = () => {
		orderCanceler(props.order.id);
		ReactGA.event({
			category: 'Order',
			action: 'Order Canceled By User',
		});
	}

	useEffect(() => {
		if (lockedSeconds && lockedSeconds > 0) {
			setTimeout(() => {
				console.log("Locked Seconds: ", lockedSeconds)
				const newLockedSeconds = lockedSeconds - 1;
				if (newLockedSeconds === 0) {
					window.location.reload();
				}
				setLockedSeconds(newLockedSeconds < 0 ? 0 : newLockedSeconds)
			}, 1000);
		}
	}, [lockedSeconds])

	useEffect(() => {
		if (order) {
			if (order && order.dspr && order.dspr) {

				setDsprId(order.dspr);
			}
			if (order.aeroPayPaymentStatus === "pending" && order.aeroPayLastPaymentAttempt) {
				const now = new Date();
				const lastPaymentAttempt = new Date(order.aeroPayLastPaymentAttempt);
				const diff = now.getTime() - lastPaymentAttempt.getTime();
				const seconds = diff / 1000;
				if (seconds < 75) {
					console.log("Seconds: ", seconds)
					setLockedSeconds(75 - Math.round(seconds));
				}
			}

			if (order.aeroPayPaymentStatus === "completed") {
				setCompletedTransaction(true);
				setLockedSeconds(0);
			}
		}
	}, [order])

	useEffect(() => {
		global["AeroPay"].init({
			env: process.env.REACT_APP_AEROPAY_ENVIRONMENT || "staging",
		});

		var onSuccess = function (uuid) {
			setCompletedTransaction(true);
			logAeroPay(order.id, uuid, order.cashTotal.toFixed(2));
		};

		// event callback is optional 
		var onEvent = function (event) {
			console.log(event);
			if (event.data) {
				const data = JSON.parse(event.data);
				if (data && data.msgType) {
					switch (data.msgType) {
						case "AeroPayFinish":
						case "AeroPayClose":
							setPurchaseModalOpen(false);
							break;
					}
				}
			}
		};

		const referenceUUID = uuid.v4();
		setReferenceUUID(referenceUUID);

		var apButton = global['AeroPay'].button({
			location: process.env.REACT_APP_AEROPAY_LOCATION_UUID,
			type: "checkout",
			onSuccess: onSuccess,
			onEvent: onEvent,  // optional,
			onError: function (error) {
				console.log("Error", error);
			},
			uuid: referenceUUID,
		});

		setAPButton(apButton);
	}, []);

	const testAeroPay = () => {
		setPurchaseModalOpen(true)
		setAeroPayReferenceUUID(referenceUUID, order.id)
		apButton.launch(order.cashTotal.toFixed(2))
	}

	if (!order || order.orderStatus === 'canceled') return <Redirect to="/" />;
	let subheader = [];
	if (driver) subheader.push(`Delivery by ${driver.user.firstName}`);
	if (order && order.address && order.address.street && order.address.street !== '')
		subheader.push('Street Address: ' + order.address.street);
	if (order && order.address && order.address.zipCode && order.address.street !== '')
		subheader.push('Zipcode: ' + order.address.zipCode);
	if (loggedInUser && loggedInUser.phoneNumber) subheader.push('Phone Number: ' + loggedInUser.phoneNumber);
	if (loggedInUser && !loggedInUser.phoneNumber) subheader.push('Phone Number: Not Given');

	const formatStatusString = (str) => {
		return str
			.split('_')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	return (
		<div>
			<Card
				className={'current-order'}
				style={{ boxShadow: 'none' }}
			>
				<CardHeader
					title={"Order Status: " + (order && order.orderStatus ? formatStatusString(order.orderStatus) : "Queued")}
					subheader={
						<div>
							{subheader.map((subheaderContent, index) => (
								<p className="subheader-content" key={`subheaderContent-${index}`}>
									{subheaderContent}
								</p>
							))}
						</div>
					}
				/>
				<CardContent>
					{order.specialInstructions ? (
						<p>
							<strong>Special Instructions:</strong> {order.specialInstructions}
						</p>
					) : null}
					<p>
						Thank you for using Grassp. We are processing your order and will schedule you into our next
						available delivery appointment. {!isRecreationalMarket && <span><strong>Note: </strong>State Laws require delivery personnel to see your medical documents in order to complete
							your delivery. Please have your <span>documentation and photo ID</span> ready upon
							arrival.</span>}
					</p>
					<OrderWithDetailsAndPrices order={order} />

					{completedTransaction && <div>
						<div style={{ 'fontSize': 16, textAlign: "center", marginBottom: 8 }}>Payment Status</div>
						<div style={{ fontSize: 32, textAlign: "center", marginTop: 12, color: "green" }}><Button variant="outlined"
							style={{ backgroundColor: "white", color: "green", borderColor: "green" }}
							id="AeroPay Button"
							onClick={() => { }}
							fullWidth={true}
							disabled={purchaseModalOpen || lockedSeconds > 0}
						>
							Payment Completed!
						</Button></div>
					</div>}

					{!completedTransaction && <div style={{ width: "100%", flexDirection: "column" }}>
						<div style={{ display: "flex", flexDirection: 'column' }}>
							<div style={{ 'fontSize': 20, display: "flex", justifyContent: 'center', marginBottom: 8 }}>
								{<div style={{ display: 'flex', alignItems: "center" }}>
									Payment Options
								</div>}
							</div>
							{isAeroPay && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
								<div style={{ display: 'flex', alignItems: "center", flexDirection: "column", width: "50%", padding: 10, margin: 8, backgroundColor: '#efefef', borderRadius: 8, textAlign: "center" }}>
									<div style={{}}>
										<img src="/images/credit_processors/credit-card-bold.svg" height="24" alt="Debit Card SVG" />
									</div>
									Debit Card upon Driver Arrival
								</div>
								<div style={{ display: 'flex', alignItems: "center", flexDirection: "column", width: "50%", padding: 10, margin: 8, backgroundColor: '#efefef', borderRadius: 8, textAlign: 'center' }}>
									<div style={{}}>
										<img src="/images/credit_processors/mastercard-visa-logo.svg" height="24" alt="MasterCard and Visa Logos" />
									</div>
									Credit Card (text-to-pay)
								</div>
							</div>}

							{isAeroPay && <div style={{ display: 'flex', justifyContent: 'center' }}><div style={{ width: "25%", height: "12px", borderBottom: "1px solid black", textAlign: "center", marginLeft: 2, marginTop: 0, marginBottom: 10 }}>
								<span style={{ fontSize: "12px", backgroundColor: "white", padding: "0 10px" }}>
									OR
								</span>
							</div></div>}
						</div>

						{lockedSeconds > 0 && <div style={{ textAlign: "center", marginBottom: 4 }}>Recent payment attempt! Please wait <strong>{lockedSeconds}</strong> seconds before trying again.</div>}
						{order && order.orderStatus !== "in_process" && isAeroPay && <div style={{ textAlign: "center", marginBottom: 8, marginTop: 8, color: "darkgray" }}>Paying through AeroPay will become available when your driver is on their way and your order is "In Process" and might require a page refresh</div>}
						{isAeroPay && <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
							<Button variant="contained"
								className="aeropay-button"
								style={{ marginLeft: 0, marginRight: 0 }}
								id="AeroPayButton"
								onClick={testAeroPay}
								fullWidth={true}
								disabled={purchaseModalOpen || lockedSeconds > 0 || (order && order.orderStatus !== "in_process")}
							>
								<img src="/images/aeropay_logo.png" height="24" alt="AeroPay Image" />
								<strong style={{ marginLeft: 8 }}>(no fees)</strong>
							</Button>
							<div style={{ width: "100%", backgroundColor: "#efefef", borderRadius: 10, fontSize: 14, marginTop: 8, paddingLeft: 12, paddingRight: 12, paddingBottom: 12 }}>
								<p>100% secure, no-fee payments. Link your bank and make easy, direct payments. <a href="https://www.aeropay.com/personal">Learn More</a>.</p>
								<div style={{ display: "flex", flexDirection: "row" }}>
									<img src="/images/banks/Chase_Logo_Icon.svg" height="36" width="36" alt="Chase Bank" style={{ marginRight: 16 }} />
									<img src="/images/banks/Bank_of_America_Logo_Icon.svg" height="36" width="36" alt="Bank Of America" style={{ marginRight: 16 }} />
									<img src="/images/banks/Wells_Fargo_Bank.svg" height="36" width="36" alt="Wells Fargo Bank" style={{ marginRight: 16 }} />
									<img src="/images/banks/Capital_One_Logo.svg" height="36" width="36" alt="Capital One Bank" style={{ marginRight: 16 }} />
									<img src="/images/banks/USAA_Logo.svg" height="36" width="36" alt="USAA Bank" style={{ marginRight: 16 }} />
									<p style={{ marginLeft: 8 }}>and more.</p>
								</div>
								<p style={{ margin: 0, marginTop: 8, display: "flex", alignItems: "center" }}><img src="/images/lock.svg" height="24" width="24" style={{ marginRight: 6 }}></img>Best-in-class security</p>
								<p style={{ margin: 0, marginTop: 8, display: "flex", alignItems: "center" }}><img src="/images/shield.svg" height="24" width="24" style={{ marginRight: 6 }}></img>Trusted by thousands of banks</p>
								<p style={{ margin: 0, marginTop: 8, display: "flex", alignItems: "center" }} ><img src="/images/lightning.svg" height="24" width="24" style={{ marginRight: 6 }}></img>Instantly link your account</p>
							</div>
						</div>}


						{isAeroPay && !isNoCash && <div style={{ marginLeft: 50, marginRight: 50 }}>
							<div style={{ width: "100%", height: "12px", borderBottom: "1px solid black", textAlign: "center", marginLeft: 2, marginTop: 0, marginBottom: 10 }}>
								<span style={{ fontSize: "12px", backgroundColor: "white", padding: "0 10px" }}>
									OR
								</span>
							</div>
						</div>}

						{!isNoCash && <Button
							variant="outlined"
							style={{ marginLeft: 0, marginRight: 0 }}
							color='secondary'
							fullWidth={true}>
							Pay Cash To Driver Upon Arrival
						</Button>}
					</div>}

					<div style={{ height: 30, width: "100%" }}></div>
					{!completedTransaction && <div>
						<div style={{ 'fontSize': 16, textAlign: "center", marginBottom: 8 }}>Additional Actions</div>
					</div>}

					<Button
						variant="contained"
						className='error-button'
						fullWidth={true}
						onClick={cancelOrder}>
						Cancel Order
					</Button>
					{completedTransaction && <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<div style={{ textAlign: "center", marginTop: 8, maxWidth: 600 }}><strong>Note:</strong> Canceling your order will not automatically refund your AeroPay transaction.
							Please reach out to support to cancel the transaction</div>
					</div>}
					{/* <p>Payment Option: {dsprIds.includes('5') ? 'Cash or credit card' : 'Cash Only'}</p> */}
				</CardContent>
			</Card>
			{/* analytics stuff */}
			<img src={'https://bcp.crwdcntrl.net/5/c=15886/int=grassphealthandTYP'} alt={''} />
		</div >
	);
}


export default CurrentOrder;
