import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { getFileUploadStatusFromDocumentForm } from '../selectors/documentUploadFormSelector';

const FILE_FIELD_NAME = 'imageFile';

const renderDropzoneInput = (field, btnText) => {
    const files = field.input.value;
    const preview = files ? files.length > 0 ? <img src={files[0].preview} alt="Preview" width="auto" height="100%" /> : <span>The maximum file size is 10MB. Please choose a smaller file.</span> : undefined;
    return (<Fragment>
        <Dropzone
            name={field.label}
            onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
            multiple={false}
            className={`drop-zone${files ? ' with-file' : ''}`}
            maxSize={10485760}
        >
            <Fragment>
                {files ? preview :
                    <div className="document-upload-drop-zone">
                        <svg height="32px" version="1.1" viewBox="0 0 32 32" width="32px" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#929292" strokeWidth="1">
                                <path d="M17,22 L25.0005601,22 C27.7616745,22 30,19.7558048 30,17 C30,14.9035809 28.7132907,13.1085075 26.8828633,12.3655101 L26.8828633,12.3655101 C26.3600217,9.87224935 24.1486546,8 21.5,8 C20.6371017,8 19.8206159,8.19871575 19.0938083,8.55288165 C17.8911816,6.43144875 15.6127573,5 13,5 C9.13400656,5 6,8.13400656 6,12 C6,12.1381509 6.00400207,12.275367 6.01189661,12.4115388 L6.01189661,12.4115388 C4.23965876,13.1816085 3,14.9491311 3,17 C3,19.7614237 5.23249418,22 7.99943992,22 L16,22 L16,16 L12.75,19.25 L12,18.5 L16.5,14 L21,18.5 L20.25,19.25 L17,16 L17,22 L17,22 Z M16,22 L16,27 L17,27 L17,22 L16,22 L16,22 Z" id="cloud-upload" />
                            </g>
                        </svg>
                        <Button color="primary">
                            {btnText || 'Select an image to upload.'}
                        </Button>
                    </div>
                }
            </Fragment>
        </Dropzone>
        {field.meta.touched &&
            field.meta.error &&
            <span className="error">{field.meta.error}</span>}
    </Fragment>
    );
};

const validate = (values) => {
    const errors = {};
    const requiredFields = [FILE_FIELD_NAME];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'An image is required.'
        }
    });
    return errors
};

let UserDocumentUploadForm = (props) => {

    const {
        handleSubmit,
        onCancel,
        submitLabel,
        showBackButton,
        buttonLabel,
        hasFile,
    } = props;

    return (
        <Fragment>
            <form onSubmit={handleSubmit} className="document-upload-form">
                <Field name={FILE_FIELD_NAME} component={f => renderDropzoneInput(f, buttonLabel)} type="dropzone" label="Upload Image" />
                {<Button color="primary" variant="contained"
                    label={submitLabel}
                    disabled={!hasFile}
                    onClick={(form) => {
                        handleSubmit(form)
                    }}
                    fullWidth={false}>
                    {submitLabel}
                </Button>}
                {showBackButton && <Button color="secondary" variant="contained" onClick={onCancel}>Back</Button>}
            </form>

        </Fragment>

    )
};

function mapStateToProps(state, props) {
    return {
        form: props.formId || 'UserDocumentUploadForm',
        hasFile: getFileUploadStatusFromDocumentForm(state, props.formId),
    }
}

export default connect(mapStateToProps)(reduxForm({
    // form: 'UserDocumentUploadForm', // a unique identifier for this form
    validate
})(UserDocumentUploadForm));
