import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import CartFooter from '../components/CartFooter';
import GeneralFooter from "../components/GeneralFooter";
import ProductFooter from "../components/ProductFooter";
import { getLoggedInUser } from '../selectors/userSelectors';


const FooterConductor = () => {

    const { pathname: path } = useLocation();
    const loggedInUser = useSelector(getLoggedInUser);

    const renderFooter = () => {
        if (loggedInUser) {
            switch (path) {
                case (path.match('/login/') || {}).input:
                case (path.match('/signup/') || {}).input:
                case (path.match('/currentOrder') || {}).input:
                    return;
                case (path.match('/product/') || {}).input:
                    return <ProductFooter />
                case (path.match('/cart') || {}).input:
                    return <CartFooter />
                default:
                    return <GeneralFooter />
            }
        } else {
            switch (path) {
                case (path.match('/product/') || {}).input:
                    return <ProductFooter />
            }
            return;
        }

    }

    return (
        <>
            {renderFooter()}
        </>
    )
}

export default FooterConductor;