export const getOrderDetailForProductInCart = (state, productId) => {
    return state.cart.orderDetails ? state.cart.orderDetails
        .filter(orderDetail => orderDetail.product.id === productId)[0]
        : null;
};

export const getCartOrderDetails = (state) => {
    return state.cart.orderDetails;
};

export const getOrderForCost = (state) => {
    return state.api.entities.orders[0];
};
