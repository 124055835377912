import React from "react";
import { NavLink } from "react-router-dom";
import { Typography, withStyles } from "@material-ui/core";
import { Person } from '@material-ui/icons';
import { Store } from '@material-ui/icons';
import { History } from '@material-ui/icons';

const styles = {
    navContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100%',
    },
    linkContainer: {    
        flexDirection: "column" as const,
        justifyContent: "center",
        height: "55px",
        width: "55px",
        display: 'flex',
        color: '#75B3FF',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: '.75rem',
        '& p': {
            color: '#75B3FF',
        },
    },
    footerNavActive: {
        color: '#0058bf',
        width: "55px",
        backgroundColor: "aliceblue",
        borderRadius: "100%",
        padding: "5px",
        '& p' : {
            color: '#0058bf',
        }
    },


}

const GeneralFooter = ({classes}) => {

    return (
        //<footer className={'footer-container'} style={{position: 'absolute', bottom: 0, left: 0, height: 'fit-content', flexShrink: 0}}>
        <footer className={'footer-container'} color={"primary"}>
            <nav className={classes.navContainer}>

                <NavLink to="/menu"
                         activeClassName={classes.footerNavActive}
                         className={classes.linkContainer} style={{flexDirection: 'column'}}
                >
                    <Store />
                    <Typography>Menu</Typography>
                </NavLink>

                <NavLink to="/orders"
                         activeClassName={classes.footerNavActive}
                         className={classes.linkContainer} style={{flexDirection: 'column'}}
                >
                    <History />
                    <Typography>Orders</Typography>
                </NavLink>

                <NavLink to="/profile"
                         activeClassName={classes.footerNavActive}
                         className={classes.linkContainer} style={{flexDirection: 'column'}}
                >
                    <Person />
                    <Typography>Profile</Typography>
                </NavLink>

            </nav>
        </footer>
    )
}

export default withStyles(styles)(React.memo(GeneralFooter));