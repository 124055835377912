import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {getAppAccessToken} from '../actions/oauthActions';
import {resetPassword} from '../actions/userActions';
import {clearErrorMessage} from '../actions/apiUIHelperActions';

import {getError} from '../selectors/errorSelector';

import {Card, CardHeader, CardContent} from '@material-ui/core';

import ResetPasswordForm from '../components/ResetPasswordForm';

class ResetPassword extends Component{
    constructor(props) {
        super(props);
        this.state = {error: null, submitted: false}
    }

    componentDidMount(){
        this.props.getAppAccessToken();
    }

    componentWillReceiveProps(newProps) {
        if(newProps.error) {
            this.setState({error: newProps.error});
            this.props.clearErrorMessage();
        }
    }

    handleSubmit = (values) => {
        this.props.resetPassword(this.props.match.params.token, values.newPassword)
            .then(() => this.setState({submitted: true}));
    };

    render(){
        const title = this.state.submitted && !this.state.error ? "Password Reset Successful" : "Reset Your Password";

        return <Card>
            <CardHeader title={title}/>
            <CardContent>
                {!this.state.submitted &&
                <ResetPasswordForm onSubmit={this.handleSubmit}/>
                }
                {this.state.error &&
                <div>{this.state.error}</div>}
                {this.state.submitted && !this.state.error &&
                <div>Click <Link to="/login">here</Link> to login.</div>}
            </CardContent>
        </Card>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        error: getError(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getAppAccessToken,
        clearErrorMessage,
        resetPassword
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
