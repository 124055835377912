import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, List, ListItem, ListItemText, Dialog } from '@material-ui/core';

import { parseDate } from '../util/util';
import { getOrderHistory } from '../actions/orderActions'
import { getLoggedInUserOrderHistory } from '../selectors/orderSelectors';

import OrderDetailsWithPrices from '../components/OrderWithDetailsAndPrices';
import LoadingSpinner from '../components/LoadingSpinner'
import { useDispatch, useSelector } from 'react-redux';

const OrderHistory: React.FC<{}> = (props) => {

    const dispatch = useDispatch();
    useEffect(() => {
        getOrderHistory()(dispatch).then((response) => {
            setLoading(false);
        })
    }, [dispatch])

    const orders = useSelector(getLoggedInUserOrderHistory);
    const [orderDetailsOpen, setOrderDetailsOpen] = useState(false);
    const [orderForDetails, setOrderForDetails] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const showOrderDetails = (order) => () => {
        setOrderDetailsOpen(true);
        setOrderForDetails(order);
    };

    const handleOrderDetailsClose = () => {
        setOrderDetailsOpen(false);
    };

    const orderStatusDisplayMapping = {
        in_process: 'IN PROCESS',
        received: 'IN PROCESS',
        allotment_confirmed: 'IN PROCESS',
        packaged: 'IN PROCESS',
        in_route: 'IN PROCESS',
        queued: 'IN PROCESS',
        canceled: 'CANCELED',
        completed: 'COMPLETED',
        modified: 'MODIFIED',
    };

    const orderListItem = (order) => {
        const timeToDisplay = (
            order.orderStatus === 'in_process'
            || order.orderStatus === 'queued'
            ||  order.orderStatus === 'received'
            ||  order.orderStatus === 'allotment_confirmed'
            ||  order.orderStatus === 'packaged'
            ||  order.orderStatus === 'in_route'
        ) ? order.originalOrderCreatedTime : order.lastStatusChangeTime;
        const date = parseDate(timeToDisplay);
        const discountString = order.discountTotal ? order.discountTotal > 0 ? " including discounts of $" + order.discountTotal : undefined : undefined;
        const discountSpan = discountString ? <span className="discounts">{discountString}</span> : undefined;
        const statusMessage = (
            order.orderStatus === 'in_process'
            || order.orderStatus === 'queued'
            ||  order.orderStatus === 'received'
            ||  order.orderStatus === 'allotment_confirmed'
            ||  order.orderStatus === 'packaged'
            ||  order.orderStatus === 'in_route'
        ) ? 'is IN PROCESS and was placed' : `was ${orderStatusDisplayMapping[order.orderStatus]}`;

        return <ListItem button className="history-list" key={order.id} onClick={showOrderDetails(order)}>
            <ListItemText>
                <span className="numOrderDetails">A {order.orderDetails.length} item order </span>
                <span className="total"> for ${order.cashTotal} </span>{discountSpan}
                <span className="orderStatus"> {statusMessage} on<br /></span>
                <span className="date"> {date.toLocaleString("en-us", { month: "long" })} {date.getDate()}, {date.getFullYear()}, at {date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
            </ListItemText>
        </ListItem>
    };

    return (loading ? <LoadingSpinner loadingMessage={"Loading Order History..."}/> :
        (<Card className="order-history"
            style={{ boxShadow: 'none', overflowY: 'auto' }}
        >
            <CardHeader title="Order History" style={{ padding: '0 16px', marginTop: 16 }} />
            <CardContent className="history-container">
                
                {orders && orders.length > 0 && <List className="history-items">
                    {orders.map(order => orderListItem(order))}
                </List>}
                {orders && orders.length === 0 && (
                    <p style={{margin: 16}}>You don't appear to have any orders with Grassp yet!</p>
                )}
                <Dialog open={orderDetailsOpen}
                    onClose={handleOrderDetailsClose}>
                    <OrderDetailsWithPrices order={orderForDetails} />
                </Dialog>

            </CardContent>
        </Card>
        )
    )
}


export default OrderHistory;
