import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { TextField, Button, Checkbox, withStyles } from '@material-ui/core';
import { shallowEqual, useSelector } from 'react-redux';
import { getMenuZipCode } from '../selectors/menuSelector';

const styles = {
    form: {
        display: "flex",
        flexDirection: "column" as const
    },
    checkboxStyles: {
        width: 250,
        marginTop: 6,
        display: "flex",
        alignItems: "flex-start",
        flexDirection: 'row' as const,
        textAlign: 'left' as const,
        margin: "auto"
    },
    errorMessage: {
        width: 250,
        margin: "auto",
        color: "red"
    },
    button: {
        marginTop: 16
    }
}

const renderField = ({ input, label, type, meta: { touched, error } }) => {

    return (
        <div>
            {type === 'checkbox' ?
                <Checkbox
                    className="checkbox"
                    checked={!!input.value}
                    onChange={input.onChange}
                    style={touched && error ? { color: "red" } : {}}
                />
                :
                <TextField label={label} hint={label} type={type} {...input}
                    error={touched && error ? true : false}
                    helperText={touched && error ? error : ''}
                    margin="normal"
                    style={{ width: 250 }}
                />
            }
        </div>
    )
};

interface SignUpFormProps extends Partial<InjectedFormProps> {
    handleSubmit?: any,
    handleCancel?: any
    enableButton: boolean,
    errorMessage: string
    classes: any

}

const SignUpForm = (props: SignUpFormProps) => {
    const { handleSubmit, enableButton, handleCancel, classes, errorMessage } = props;


    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Field name="firstName" type="text" component={renderField} label="First Name" />
            <Field name="lastName" type="text" component={renderField} label="Last Name" />
            <Field name="email" type="text" component={renderField} label="Email Address" />
            <Field name="password" type="password" component={renderField} label="Password" />
            <Field name="signupZipCode" type="text" component={renderField} label="Zip Code" />
            <Field name="phoneNumber" type="text" component={renderField} label="Phone Number" />
            <div className={classes.checkboxStyles}>
                <Field name="termsAndConditions" type="checkbox" component={renderField} />
                <p style={{ marginTop: 12 }}>I agree to the <a target="_blank" rel="noopener noreferrer" href="https://www.grassphealth.com/terms-and-conditions">Terms and Conditions</a> and the <a target="_blank" rel="noopener noreferrer" href="http://www.grassphealth.com/privacy-policy">Privacy Policy</a></p>
            </div>
            {errorMessage ? <p className={classes.errorMessage}>{errorMessage}</p> : null}
            <Button color="primary" variant="contained" onClick={handleSubmit} disabled={!enableButton} className={classes.button} id="sign-up-button">
                Sign Up
            </Button>
            <Button color="primary" variant="outlined" onClick={handleCancel} className={classes.button} id="log-in-button">
                Log In
            </Button>
        </form>
    );
};

const validate = values => {
    const errors: any = {};
    if (!values.firstName) {
        errors.firstName = 'Required';
    } if (!values.lastName) {
        errors.lastName = 'Required';
    }
    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email.trim())) {
        errors.email = 'Invalid email address';
    }
    if (!values.password) {
        errors.password = 'Required';
    }
    if (!values.signupZipCode) {
        errors.signupZipCode = 'Required';
    }
    // TODO: [angel] validate zip codes.
    if (!values.phoneNumber) {
        errors.phoneNumber = 'Required';
    } else if (isNaN(Number(values.phoneNumber))) {
        errors.phoneNumber = 'Must be a number';
    }
    // TODO: [angel] validate phone numbers.
    if (!values.termsAndConditions) {
        errors.termsAndConditions = 'You must agree to the terms and conditions.';
    }
    return errors;
};
//
// const asyncValidate = (values, dispatch, props, field ) => {
//     return props.checkIfEmailTaken(values["email"]).then(emailTaken => {
//         if(emailTaken) throw { email: 'Email is already taken' }
//     });
// };

export default withStyles(styles)(reduxForm<any, SignUpFormProps>({
    form: 'userSignUpForm',
    validate
})(SignUpForm));
