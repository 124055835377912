import React, { Component } from "react"
import Loader from "react-loader-spinner"

export default class LoadingSpinner extends Component {
  render() {
    const { loadingMessage } = this.props

    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 50px)",
          display: "flex",
          left: 0,
          top: 50,
          flexDirection: "row",
          justifyContent: "center",
          position: "fixed",
          overflow: "none"
        }}
      >
        <div
          style={{
            // height: '100vh',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <Loader type="TailSpin" color="#000" height="250px" width="250px" />
          <img
            src="/images/DeliveryCarIcon1.png"
            style={{ color: "#67cb33", height: "100px", position: "relative", bottom: "175px" }}
            alt="cannabis leaf"
          />
          <span
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              fontWeight: "light",
              marginTop: "20px",
              fontSize: "20px",
              position: "relative",
              bottom: "75px"
            }}
          >
            {loadingMessage ? loadingMessage : "Loading..."}
          </span>
        </div>
      </div>
    )
  }
}
