import {createSelector} from 'reselect';

export const getMenu = (state) => state.api.entities.menu[0];
const getAllProducts = (state) => state.api.entities.dspProducts;
const getAllBrands = (state) => state.api.entities.dspBrands;

export const getProductCategories = (state) => {
    const {productCategories}  = state.api.entities;
    return productCategories ? Object.values(productCategories) : [];
};

export const getProductBrands = (state) => {
    const {brands}  = state.api.entities;
    return brands ? Object.values(brands) : [];
};

export const getProductCategoriesForCurrentMenu = (state) => {
    const { productCategories } = state.api.entities;
    const currentMenu = getMenu(state);

    if(!currentMenu || !currentMenu.productCategories) return [];
    return currentMenu.productCategories.map(categoryId => productCategories[categoryId]);
}

export const getProductCateogryByIdFromProps = (state, props) => {
    const { productCategories } = state.api.entities;
    return productCategories[props.categoryId] || undefined; 
}

export const getBrandsForCurrentMenu = (state) => {
    const { dspBrands } = state.api.entities;
    const currentMenu = getMenu(state);

    if(!currentMenu || !currentMenu.brands) return [];
    return currentMenu.brands.map(brandId => dspBrands[brandId]);
} 

export const getBrandByIdFromProps = (state, props) => {
    return state?.api?.entities?.dspBrands[props.brandId] || undefined; 
}

export const getShowSubheader = (state) => {
    return state.menu.subheaderVisible;
}

export const getProductCategoryPromotionsForDSPRFromProps = (state, props) => {
    const {productCategoryPromotions} = state.api.entities
    return productCategoryPromotions ? Object.values(productCategoryPromotions).filter(promotion => promotion.dspr === parseInt(props.dsprId)) : [];
}

export const getProductCategoriesWithPromotionsForDSPR = createSelector (
    [getProductCategories, getProductCategoryPromotionsForDSPRFromProps], (productCategories, productCategoryPromotions) =>
    productCategories ?
            productCategoryPromotions ?
                productCategories.map(productCategory => ({...productCategory, currentPromotion: productCategoryPromotions.find(promotion=> promotion.current && (promotion.productCategory === productCategory.id))})) 
                :undefined
            :undefined
)
export const getAllProductsOnMenu = createSelector(
    [getMenu, getAllProducts, getAllBrands], (menu, products, brands) => {
    const menuProducts = menu && menu.products ? menu.products : undefined;
    return products ?
        menuProducts ?
            menuProducts.map(productId => ({ ...products[productId], brand: products[productId].brand ? brands[products[productId].brand] : undefined}))
            : undefined
        : undefined;
});

export const getAllProductsOnMenuMap = createSelector([getMenu, getAllProducts, getAllBrands], (menu, products, brands) => {
    const menuProducts = menu && menu.products ? menu.products : undefined;
    const productsMap = {};

    if(!products || !menuProducts) return undefined;
    menuProducts.map(productId => productsMap[productId] = ({ ...products[productId], brand: products[productId].brand ? brands[products[productId].brand] : undefined})) 
    return productsMap;
})

export const getCurrentQuantityAvailableForProduct =  (state, productId) => {
    return state.api.entities.dspProducts && state.api.entities.dspProducts[productId] && state.api.entities.dspProducts[productId].currentQuantityAvailable;
};

export const getMenuCategories = createSelector([getMenu], (menu) => {
    return menu && menu.productCategories ? menu.productCategories : undefined; 
})

export const getMenuProducts = createSelector([getMenu], (menu) => {
    return menu && menu.products ? menu.products : undefined; 
})
export const getMenuBrands = createSelector([getMenu], (menu) => {
    return menu && menu.brands ? menu.brands : undefined; 
})

export const getAddressFromMenu = (state) => {
    // TODO: Need to fix the hacky menu id.
    return state.api.entities.menu[0] && state.api.entities.addresses[state.api.entities.menu[0].address];
};

export const getDriverMenu = (state) => {
    // TODO: Need to fix the hacky menu id.
    return state.api.entities.menu[0] && {id :state.api.entities.menu[0].driver};
};

export const getIsRecreationalMarket = (state) => {
    return state.api.isRecreationalMarket;
};

export const getIsUnderStateInspection = (state) => {
    return state.api.entities.menu[0] && state.api.entities.menu[0].isUnderStateInspection;
}

export const isFullMenuShownToUser = (state) => {
    return state.menu.isFullMenuShown;
}

export const isClosed = state => {
    return state.menu.menuClosed
}

export const isUnderStateInspection = state => {
    return state.menu.stateInspection
}

export const getProductFromProps = (state, productId) => state.api.entities.dspProducts[productId];

/**Returns current menu type, which can be any of the MENU_LOCATION_TYPES || null*/
export const getCurrentMenuType = (state) => {
    return state.menu.currentMenuType;
}

export const hasFullMenuModalBeenShownToUser = (state) => {
    return state.menu.hasUserSeenFullMenuDeliveryModal;
}

export const getMenuFilters = (state) => {
    return state.menu.filters;
}

export const getMenuSorting = (state) => {
    return {sortParameter: state.menu.sortParameter, sortMethod: state.menu.sortMethod}
}

export const getMenuSearch = (state) => {
    return state.menu.search
}

export const getMenuZipCode = (state) => {
    return state.menu.currentZipCode;
}