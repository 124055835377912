import { useState } from 'react';

/**Custom hook returning a boolean value, and functions to explicitly set the value as true or false
 *  One use case: displaying modals
 * */
export const useBoolean = (initialValue: boolean): [boolean, () => void, () => void] => {
    const [value, setValue] = useState(initialValue);

    const setTrue = () => setValue(true);
    const setFalse = () => setValue(false);

    return [value, setTrue, setFalse];
}

/**Custom hook that toggles a boolean value
 *  One use case: handling state for a switch component
 * */
export const useToggle = (initialValue: boolean): [boolean, () => void] => {
    const [value, setValue] = useState(initialValue);

    const toggleValue = () => setValue(!value);

    return [value, toggleValue];
}