import React, { useEffect, useMemo, useState } from "react";
import { Card, CardContent, withStyles, CardActionArea, Typography } from "@material-ui/core";
import { parseDateEndOfDay } from '../util/util';
import { history } from '../index.js'

const styles = {
    documentCard: {
        width: "calc(100% - 32px)",
        height: "calc(100% - 32px)",
        margin: 16
    }
}

export type DocumentType = 'id' | 'med';

type userDocumentProps = {
    document?: any;
    classes: any;
    documentType: DocumentType;
}

const UserDocument: React.FC<userDocumentProps> = (props) => {
    const { document, classes, documentType } = props;
    // const expiryDate = document && document.expirationDate ? parseDateEndOfDay(document.expirationDate) : null;
    // const expirationDays = Math.floor((expiryDate.valueOf() - new Date().valueOf()) / (1000 * 60 * 60 * 24));
    const [expiryDate, setExpiryDate] = useState(undefined);
    const [expiryDays, setExpiryDays] = useState(undefined);

    const parseExpiryDate = useMemo(() => {
        if(document && document.expirationDate) {
           return document && document.expirationDate ? parseDateEndOfDay(document.expirationDate) : undefined
        }
        return undefined
    }, [document])

    useEffect(() => {
        if(parseExpiryDate) {
            setExpiryDate(parseExpiryDate)
            setExpiryDays(Math.floor((parseExpiryDate.valueOf() - new Date().valueOf()) / (1000 * 60 * 60 * 24)))
        }
    },[parseExpiryDate])

  return (document ? <Card className={classes.documentCard}>
      <CardActionArea onClick={()=> history.push(`/profile/documentStatus/${documentType}`)}>
        <CardContent>
            <Typography variant="body2" style={{marginBottom: 8}}>
                {expiryDate ? "Exp: " + (expiryDate.getMonth()+1) + "/" + expiryDate.getDate() + "/" + expiryDate.getFullYear() % 100: undefined}
            </Typography>
            {<Typography variant="h6" style={{lineHeight: 1}}>{document.verified ? JSON.stringify(expiryDays) + " Days" : "Unverified"}</Typography>}
            <Typography variant="body1" style={{marginTop: 8}} color={document.verified ? "primary" : document.visible ? "secondary" : "error"}>{document.verified? "Verified": document.visible ? "Under Review" : "Please Re-Upload"}</Typography>
        </CardContent>   
      </CardActionArea>
  
</Card>
    : <Card className={classes.documentCard}>
        <CardActionArea onClick={()=> history.push(`/profile/documentStatus/${documentType}`)}>
            <CardContent>
                <Typography variant="h6" style={{lineHeight: 1}} color={"error"}>Upload Needed</Typography>
                    
            </CardContent>
        </CardActionArea>
        
    </Card>
  )
}

export default withStyles(styles)(React.memo(UserDocument));