import React, {Component} from 'react';

import {ListItem, ListItemText, IconButton} from '@material-ui/core';
import RemoveShoppingCart from '@material-ui/icons/RemoveShoppingCart';

export default class OrderDetailListItem extends Component {
    render() {
        const {orderDetail, removeProductFromCart} = this.props;
        const {product} = orderDetail;
        const rightIcon = removeProductFromCart ?
            <IconButton onClick={() => removeProductFromCart(product)}>
                <RemoveShoppingCart />
            </IconButton>
            : undefined;

        if (orderDetail) {
            const unit = orderDetail.unit ? orderDetail.unit + ' oz of' : ' x ';
            //TOOD: let's standardize the flower type capitalization through a helper function

            const tapFunction = this.props.showProductQuantitySelector ? () => this.props.showProductQuantitySelector(product) : undefined;
            return <ListItem
                className='cart-product'
                >
                {rightIcon}
                <ListItemText
                    onClick={tapFunction}
                    primary={`${orderDetail.quantity} ${unit} ${product.name}`}
        secondary={<span><span className={product.flowerType.replace("_", " ")}>{product.flowerType.replace("_", " ")}</span><span>{orderDetail.appliedCoupon ? (" - " + orderDetail.appliedCoupon.code.toUpperCase()) : ""}</span></span>}
                />
                <div className={orderDetail.discount !== 0 ? "strikethrough" : ""}>${orderDetail.pricePreDiscount.toFixed(2)}</div>
                {orderDetail.discount !== 0 && <div>${(orderDetail.pricePreDiscount - orderDetail.discount).toFixed(2)}</div>}
            </ListItem>
        }
        return <ListItem className='cart-product' />;
    }
}
